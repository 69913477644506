import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import { X } from "react-feather";
import MaterialDrawer from "@material-ui/core/Drawer";

import { border } from "src/styles/mixins";
import theme from "src/styles/theme";

const DrawerHeaderContainer = styled.header`
  ${(props) => {
    return `
        padding: ${themeGet("space.5")(props)}px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: ${border()}
    `;
  }}
`;

const DrawerCloseButton = styled.button`
  border: none;
  background: none;
  line-height: 1;
`;

export const DrawerHeader = ({ handleClose, title }) => {
  return (
    <DrawerHeaderContainer>
      <h2>{title}</h2>
      <DrawerCloseButton onClick={handleClose}>
        <X size={"20px"} stroke={theme.colors.darkGray} />
      </DrawerCloseButton>
    </DrawerHeaderContainer>
  );
};

export const Drawer = ({
  children,
  direction = "right",
  isOpen,
  handleClose,
  title,
}) => {
  return (
    <MaterialDrawer anchor={direction} open={isOpen}>
      <DrawerHeader handleClose={handleClose} title={title} />
      {children}
    </MaterialDrawer>
  );
};

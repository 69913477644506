import React from "react";
import styled from "styled-components";

import { Box, Flex, Text } from "src/components/Primitives";
import { border } from "src/styles/mixins";

const CartPriceItem = ({ label, value }) => {
  return (
    <Box pb={2}>
      <Flex justifyContent={"space-between"}>
        <Text fontSize={6} textTransform="uppercase" pr={10}>
          {label}
        </Text>
        <Text fontSize={6} textTransform="uppercase" fontWeight={"600"}>
          $ {value}
        </Text>
      </Flex>
    </Box>
  );
};

const CartFooterContainer = styled.footer`
  border-top: ${border()};
`;

const CartFooterContainerInner = styled(Box)``;

const CartPriceItemContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const CartPriceItemInnerContainer = styled(Box)``;

export const CartFooter = ({ children, subtotal, tax, total }) => {
  return (
    <CartFooterContainer>
      <CartFooterContainerInner p={5}>
        <CartPriceItemContainer pb={2}>
          <CartPriceItemInnerContainer>
            <CartPriceItem label="Total" value={total} />
          </CartPriceItemInnerContainer>
        </CartPriceItemContainer>
        <Box>{children}</Box>
      </CartFooterContainerInner>
    </CartFooterContainer>
  );
};

export const EmptyCartMessage = (
  <Box p={5}>
    <Text fontSize={7}>Your cart is currently empty.</Text>
  </Box>
);

export const TitleContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

import React from "react";
import styled from "styled-components";

import { Box } from "src/components/Primitives";
import theme from "src/styles/theme";

const createMarkup = (content) => {
  return { __html: content };
};

export const MarkdownStyling = styled(Box)`
  h2 {
    font-size: ${theme.fontSizes["4"]};
    padding-bottom: ${theme.space[5]}px;
    padding-top: ${theme.space[5]}px;
    font-weight: ${theme.fontWeights[5]};
  }

  h3 {
    padding-bottom: ${theme.space[4]}px;
  }

  p,
  ul {
    color: ${theme.colors.gray};
    font-size: ${theme.fontSizes["6"]};
    font-family: ${theme.fonts.body};
    line-height: ${theme.lineHeights.copy};
    padding-bottom: ${theme.space[4]}px;
  }

  ul {
    padding-left: 40px;
  }

  li {
    list-style-type: square;
    padding-left: 5px;
  }
`;

export const MarkdownContainer = ({ content }) => {
  return <MarkdownStyling dangerouslySetInnerHTML={createMarkup(content)} />;
};

import {
  exists,
  getConfigValueIfUndefined,
  getValueIfUndefined,
} from "../../utilities/misc";

import { signs } from "../../utilities/westernAstrology";
import { textHeight } from "../../utilities/text";
import Montserrat from "../../assets/fonts/Montserrat-ExtraBoldItalic.ttf";

export const config = [
  {
    name: "category1",
    type: "category",
    label: "Main Controls",
  },
  {
    name: "category2",
    type: "category",
    label: "Spacing Controls",
  },
  {
    name: "category3",
    type: "category",
    label: "Color Controls",
  },
  {
    name: "sign",
    type: "select",
    defaultValue: Object.values(signs)[5].name,
    options: Object.values(signs).map((item) => item.name),
    label: "Sun Sign",
    category: "category1",
  },
  {
    name: "risingSign",
    type: "select",
    defaultValue: Object.values(signs)[10].name,
    options: Object.values(signs).map((item) => item.name),
    label: "Rising Sign",
    category: "category1",
  },
  {
    name: "moonSign",
    type: "select",
    defaultValue: Object.values(signs)[4].name,
    options: Object.values(signs).map((item) => item.name),
    label: "Moon Sign",
    category: "category1",
  },
  {
    name: "enableSunSign",
    type: "boolean",
    defaultValue: true,
    label: "Enable Sun Sign",
    category: "category1",
  },
  {
    name: "enableRisingSign",
    type: "boolean",
    defaultValue: true,
    label: "Enable Rising Sign",
    category: "category1",
  },
  {
    name: "enableMoonSign",
    type: "boolean",
    defaultValue: true,
    label: "Enable Moon Sign",
    category: "category1",
  },
  {
    name: "topPadding",
    type: "float",
    defaultValue: 145,
    min: 0,
    max: 800,
    label: "Sun Sign Padding",
    category: "category2",
  },
  {
    name: "risingSignPadding",
    type: "float",
    defaultValue: 53,
    min: -512,
    max: 800,
    label: "Rising Sign Padding",
    category: "category2",
  },
  {
    name: "moonSignPadding",
    type: "float",
    defaultValue: 82,
    min: -512,
    max: 800,
    label: "Moon Sign Padding",
    category: "category2",
  },
  {
    name: "fgColor",
    type: "color",
    defaultValue: "#e059c5",
    label: "Foreground Color",
    category: "category3",
  },
  {
    name: "bgColor",
    type: "color",
    defaultValue: "#474745",
    label: "Background Color",
    category: "category3",
  },
];

const sketch = (p) => {
  const givenState = document.p5jsState;
  const state = givenState || {};
  const configFromState = state.configuration;
  let bgOverrideColor = givenState.bgColor;

  let sign = getConfigValueIfUndefined(config, configFromState, "sign");
  sign = sign.toLowerCase();

  let risingSign = getConfigValueIfUndefined(
    config,
    configFromState,
    "risingSign"
  );
  risingSign = risingSign.toLowerCase();

  let moonSign = getConfigValueIfUndefined(config, configFromState, "moonSign");
  moonSign = moonSign.toLowerCase();

  let enableSunSign = getConfigValueIfUndefined(
    config,
    configFromState,
    "enableSunSign"
  );
  let enableRisingSign = getConfigValueIfUndefined(
    config,
    configFromState,
    "enableRisingSign"
  );
  let enableMoonSign = getConfigValueIfUndefined(
    config,
    configFromState,
    "enableMoonSign"
  );

  let topPadding = getConfigValueIfUndefined(
    config,
    configFromState,
    "topPadding"
  );
  let risingSignPadding = getConfigValueIfUndefined(
    config,
    configFromState,
    "risingSignPadding"
  );
  let moonSignPadding = getConfigValueIfUndefined(
    config,
    configFromState,
    "moonSignPadding"
  );

  let fgColor = getConfigValueIfUndefined(config, configFromState, "fgColor");
  let bgColor = getConfigValueIfUndefined(config, configFromState, "bgColor");

  let screenSizeX = getValueIfUndefined(state, "screenSizeX", 1024);
  let screenSizeY = getValueIfUndefined(state, "screenSizeY", 1024);
  let pixelDensity = getValueIfUndefined(state, "pixelDensity", 0.5);

  p.myCustomRedrawAccordingToNewPropsHandler = (props) => {
    if (exists(props.sign)) {
      sign = props.sign.toLowerCase();
    }

    if (exists(props.risingSign)) {
      risingSign = props.risingSign.toLowerCase();
    }

    if (exists(props.moonSign)) {
      moonSign = props.moonSign.toLowerCase();
    }

    if (exists(props.enableSunSign)) {
      enableSunSign = props.enableSunSign;
    }

    if (exists(props.enableRisingSign)) {
      enableRisingSign = props.enableRisingSign;
    }

    if (exists(props.enableMoonSign)) {
      enableMoonSign = props.enableMoonSign;
    }

    if (exists(props.topPadding)) {
      topPadding = props.topPadding;
    }

    if (exists(props.risingSignPadding)) {
      risingSignPadding = props.risingSignPadding;
    }

    if (exists(props.moonSignPadding)) {
      moonSignPadding = props.moonSignPadding;
    }

    if (exists(props.fgColor)) {
      fgColor = props.fgColor;
    }

    if (exists(props.bgColor)) {
      bgColor = props.bgColor;
    }
  };

  let myFont;

  p.preload = () => {
    myFont = p.loadFont(Montserrat);
  };

  p.setup = () => {
    p.createCanvas(screenSizeX, screenSizeY);
    p.rectMode(p.CENTER);
    p.angleMode(p.DEGREES);
    p.frameRate(6);
    p.pixelDensity(pixelDensity);
    p.imageMode(p.CENTER);
    p.textAlign(p.CENTER, p.TOP);
  };

  p.draw = () => {
    if (bgOverrideColor) {
      p.background(bgOverrideColor);
    } else {
      p.background(bgColor);
    }
    p.textFont(myFont);

    const width = p.width;
    const originX = width / 2;

    const widthWithPadding = width * 0.75;

    p.fill(fgColor);
    p.noStroke();
    const textSize = 120;

    p.push();
    // main state starts
    p.translate(originX, topPadding);

    // main sign text
    p.push();
    p.textSize(textSize);

    const mainSignText = signs[sign].name.toUpperCase();
    const mainSignTextWidth = p.textWidth(mainSignText);
    const widthToTextRatio = widthWithPadding / mainSignTextWidth;

    p.textSize(textSize * widthToTextRatio);
    const mainSignTextHeight = textHeight(p, mainSignText);

    if (enableSunSign) {
      p.text(mainSignText, 0, 0);
    }

    p.pop();

    // rising sign text
    p.push();
    p.textSize(textSize);

    const risingSignText = `RISING ${signs[risingSign].name.toUpperCase()}`;
    const risingSignTextWidth = p.textWidth(risingSignText);
    const risingSignScaleRatio = widthWithPadding / risingSignTextWidth;

    p.textSize(textSize * risingSignScaleRatio);
    const risingSignTextHeight = textHeight(p, risingSignText);

    if (enableRisingSign) {
      p.text(risingSignText, 0, mainSignTextHeight * 2 - risingSignPadding);
    }

    p.pop();

    // moon sign text
    p.push();
    p.textSize(textSize);

    const moonSignText = `MOON IN ${signs[moonSign].name.toUpperCase()}`;
    const moonSignTextWidth = p.textWidth(moonSignText);
    const moonSignScaleRatio = widthWithPadding / moonSignTextWidth;

    p.textSize(textSize * moonSignScaleRatio);

    if (enableMoonSign) {
      p.text(
        moonSignText,
        0,
        mainSignTextHeight * 2 + risingSignTextHeight * 2 - moonSignPadding
      );
    }

    p.pop();

    // main state ends
    p.pop();
  };
};

export default sketch;

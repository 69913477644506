import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ShoppingCart } from "react-feather";
import MenuIcon from "@material-ui/icons/Menu";
import { useMediaQuery } from "react-responsive";

import { MaxWidthContainer } from "src/components/MaxWidthContainer";
import { Box, Flex, Text } from "src/components/Primitives";
import { LogoIcon } from "src/components/LogoIcon";
import { border } from "src/styles/mixins";
import { Drawer } from "src/components/Drawer";
import theme, { getBreakPoint } from "src/styles/theme";

const HeaderContainer = styled(Box)`
  border-bottom: ${border()};
`;

const HeaderLogoContainer = styled(Box)``;

const CartButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  color: black;
  padding-top: 0;
  padding-bottom: 0;
`;

const CartContainer = styled(Box)`
  position: relative;
`;

const CartNotification = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: ${theme.colors.pink};
  position: absolute;
  right: 0;
  top: 0;
`;

const MenuIconContainer = styled(Box)`
  cursor: pointer;
`;

const HeaderLink = ({ path, text }) => {
  return (
    <Box mr={8} mb={[1, 0]}>
      <Link to={path}>
        <Flex alignItems={"center"}>
          <Text fontSize={5} fontWeight={"300"}>
            {text}
          </Text>
        </Flex>
      </Link>
    </Box>
  );
};

const Header = ({ cartHasItems, isCartOpen, onCartOpen }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const mobileBreakpoint = getBreakPoint("mobile");

  const isLargerThanMobile = useMediaQuery({
    query: `(min-width: ${mobileBreakpoint}px)`,
  });

  const isMobile = !isLargerThanMobile;

  const handleMenuIconClick = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const menuItems = (
    <Flex flexDirection={["column", "row"]}>
      <HeaderLink path={"/shop"} text={"Shop"} />
      <HeaderLink path={"/about"} text={"About"} />
      <HeaderLink path={"/blog"} text={"Blog"} />
      <HeaderLink path={"/faq"} text={"FAQ"} />
    </Flex>
  );

  const menuDrawer = isMobile && (
    <Drawer
      isOpen={mobileMenuOpen}
      handleClose={() => setMobileMenuOpen(false)}
      title={"Karaktera"}
      direction={"left"}
    >
      <Flex flexDirection={"column"} p={5}>
        {menuItems}
      </Flex>
    </Drawer>
  );

  return (
    <HeaderContainer py={6} px={10}>
      <MaxWidthContainer>
        <Flex justifyContent={"space-between"} alignItems={["center"]}>
          <Flex alignItems={["center"]} flexDirection={["row"]}>
            {isMobile && (
              <MenuIconContainer onClick={handleMenuIconClick} mr={1}>
                <MenuIcon />
              </MenuIconContainer>
            )}
            <HeaderLogoContainer mr={10} mb={[1, 0]}>
              <Link to="/">
                <Flex
                  alignItems={"center"}
                  flexDirection={["row-reverse", "row"]}
                >
                  <Box mr={[0, 1]} ml={[1, 0]}>
                    <LogoIcon />
                  </Box>
                  <Text fontSize={5} fontWeight={"700"}>
                    Karaktera
                  </Text>
                </Flex>
              </Link>
            </HeaderLogoContainer>
            {!isMobile && <Flex flexDirection={"row"}>{menuItems}</Flex>}
          </Flex>

          <Box>
            {!isCartOpen && (
              <CartContainer>
                {cartHasItems && <CartNotification />}
                <CartButton onClick={onCartOpen}>
                  <ShoppingCart size={"20px"} />
                </CartButton>
              </CartContainer>
            )}
          </Box>

          {menuDrawer}
        </Flex>
      </MaxWidthContainer>
    </HeaderContainer>
  );
};

export default Header;

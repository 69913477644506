import styled from "styled-components";
import { themeGet } from "styled-system";

export const CanvasContainer = styled.div`
  ${(props) => {
    const boxShadow = props.styleConfig.noShadow
      ? "box-shadow: none;"
      : `box-shadow: 5px 5px 15px 2px ${themeGet("colors.midGray")(props)};`;

    return `
      & canvas {
        width: 100% !important;
        height: 100% !important;
      }

      & div {
        width: 100%;
        height: 100%;
      }

      & canvas {
        display: block;
      }

      max-height: ${props.height};
      max-width: ${props.width};

      ${boxShadow}

      padding: 0;
      background: none;
      
    `;
  }};
`;

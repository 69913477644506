import React, { useState, useEffect } from "react";
import marked from "marked";
import { Fade } from "src/components/Fade";

import ContentFile from "./content.md";

import { MarkdownContainer } from "src/components/MarkdownContainer";
import { Box, MainTitle, Page } from "src/components/Primitives";
import { MaxWidthContainer } from "src/components/MaxWidthContainer";
import { LayoutWrapper } from "src/components/LayoutWrapper";

const ShippingAndReturns = () => {
  const [bodyText, setBodyText] = useState("");

  useEffect(() => {
    fetch(ContentFile)
      .then((response) => response.text())
      .then((text) => {
        setBodyText(marked(text));
      });
  }, [ContentFile]);

  return (
    <LayoutWrapper title={"Shipping and Returns"}>
      <Fade>
        <Page>
          <MaxWidthContainer contentContainer>
            <Box w={1}>
              <MainTitle>Shipping &amp; Returns</MainTitle>
            </Box>
            <MarkdownContainer content={bodyText} />
          </MaxWidthContainer>
        </Page>
      </Fade>
    </LayoutWrapper>
  );
};

export default ShippingAndReturns;

export const TEXT_FOR_DEFAULT_OPTION = "Default Title";

export const getVariantTitle = (title) => {
  if (!title) {
    return title;
  }

  if (title === TEXT_FOR_DEFAULT_OPTION) {
    return "Default Option";
  }

  return title;
};

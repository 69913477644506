import React from "react";
import styled from "styled-components";
import { BodyTitle, Box, BodyText } from "src/components/Primitives";

const InfoCardContainer = styled(Box)`
  ${({ path }) => {
    return `background: url(${path});`;
  }};

  ${({ first }) => {
    return first && `padding-left: 0 !important;`;
  }};

  ${({ last }) => {
    return last && `padding-right: 0 !important;`;
  }};
`;

const InfoCardTitleContainer = styled(Box)`
  ${({ path }) => {
    return `
      background: url(${path});
      border-radius: 4px;
      height: 400px;
      width: 100%;
      background-size: cover;
      background-position: center !important;
    `;
  }};
`;

const InfoCard = ({ description, first, last, path, title }) => {
  return (
    <InfoCardContainer
      px={[0, 0, 4]}
      py={4}
      pt={0}
      pb={4}
      width={[1]}
      first={first}
      last={last}
    >
      <InfoCardTitleContainer mb={4} path={path} />
      <Box>
        <BodyTitle as={"h3"}>{title}</BodyTitle>
        <BodyText
          fontSize={6}
          lineHeight={"copy"}
          textAlign={"left"}
          fontFamily={"body"}
        >
          {description}
        </BodyText>
      </Box>
      <Box />
    </InfoCardContainer>
  );
};

export default InfoCard;

import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { Box } from "src/components/Primitives";
import appTheme from "src/styles/theme";
import { getBreakPoint } from "src/styles/theme";
import { border } from "src/styles/mixins";

function TabPanel(props) {
  const { children, value, index } = props;

  if (value !== index) {
    return null;
  }

  return (
    <Box
      role="tabpanel"
      id={`scrollable-auto-tabpanel-${index}`}
      ariaLabelledby={`scrollable-auto-tab-${index}`}
      p={5}
    >
      {children}
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const Container = styled(Box)`
  flex-grow: 1;
  width: "100%";
  background: white;
`;

const TabLabels = styled(Tab)`
  & > span {
    font-weight: 900;
    font-size: ${appTheme.fontSizes["7"]};
    font-family: ${appTheme.fonts.heading};
  }

  &:selected: {
    color: ${appTheme.colors.pink};
  }
`;

const TabsContainer = styled(Box)`
  & .MuiTabs-flexContainer {
    justify-content: center;
  }

  & header {
    box-shadow: none;
    border: ${border()};
  }

  ${({ isMobile }) => {
    if (isMobile) {
      return `& .MuiTabs-flexContainer {
        justify-content: left;
      }`;
    }
  }}
`;

export function TabsSection({ categories, controllers = [], onChange }) {
  const [value, setValue] = React.useState(0);
  const breakpoint = getBreakPoint("mobile");
  const isLargerThanMobile = useMediaQuery({
    query: `(min-width: ${breakpoint}px)`,
  });
  const isMobile = !isLargerThanMobile;

  const handleChange = (_event, newValue) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const hasPanelContent = controllers.length > 0;

  const allTabs = categories.map((category, index) => {
    const { label } = category;
    return <TabLabels label={label} {...a11yProps(index)} key={label} />;
  });

  const allTabPanels = categories.map((category, index) => {
    const { name } = category;

    return (
      <TabPanel index={index} value={value} key={name}>
        {controllers
          .filter((controller) => {
            const { category } = controller;
            if (category === name) {
              return true;
            }

            return false;
          })
          .map((controller) => controller.component)}
      </TabPanel>
    );
  });

  return (
    <Container>
      <TabsContainer isMobile={isMobile}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {allTabs}
          </Tabs>
        </AppBar>
      </TabsContainer>

      {hasPanelContent && allTabPanels}
    </Container>
  );
}

import React from "react";

import * as Components from "./ImageCard.components";
import * as ProductTypes from "./images";

const getProductTypeIcon = (productType) => {
  return ProductTypes[productType] || null;
};

export const ImageCard = ({
  altText,
  description,
  linkPath,
  imagePaths = [],
  isMobile,
  productTypes,
}) => {
  const imageAmountZeroIndex = imagePaths.length - 1;
  const [position, setPosition] = React.useState(0);
  const images = imagePaths.map((path, index) => {
    return (
      <Components.Image
        key={path}
        src={path}
        isVisible={position === index}
        alt={altText}
      />
    );
  });

  const productTypeIcons = productTypes.map((productType, index) => {
    return (
      <Components.ProductTypeImage
        key={index}
        src={getProductTypeIcon(productType)}
        alt={`${productType} icon`}
      />
    );
  });

  function getPosition(position) {
    if (position > imageAmountZeroIndex) {
      return 0;
    }
    if (position < 0) {
      return imageAmountZeroIndex;
    }

    return position;
  }

  return (
    <Components.CustomCard>
      <Components.ImageCardContainer>
        <Components.IconLeftContainer
          onClick={() => {
            setPosition(getPosition(position - 1));
          }}
          isVisible={isMobile}
        />
        <Components.ImageContainer>
          <Components.ProductTypeContainer>
            {productTypeIcons}
          </Components.ProductTypeContainer>
          <Components.Link to={{ pathname: linkPath }}>
            <Components.ImageContainer>{images}</Components.ImageContainer>
          </Components.Link>
        </Components.ImageContainer>
        <Components.IconRightContainer
          onClick={() => {
            setPosition(getPosition(position + 1));
          }}
          isVisible={isMobile}
        />
      </Components.ImageCardContainer>
      <Components.TextContainer>{description}</Components.TextContainer>
    </Components.CustomCard>
  );
};

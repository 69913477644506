import React, { useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import { deleteCheckoutData } from "src/utilities/localStorage";

import Cart from "../Cart";
import Header from "../Header";
import { Helmet } from "../Helmet";
import Footer from "../Footer";
import NewsLetter from "../Newsletter";

import { App, ChildrenContainer } from "./index.components";

const Layout = (props) => {
  const [isCartOpen, setIsCartOpen] = useState(false);

  function handleCartOpen() {
    setIsCartOpen(true);
  }

  function handleCartClose() {
    setIsCartOpen(false);
  }

  function handleOpenCheckout() {
    deleteCheckoutData();
    window.open(props.checkout.webUrl);
  }

  const {
    checkout,
    children,
    displayNewsletter = true,
    removeLineItemInCart,
    updateLineItemInCart,
    title,
    description,
    checkoutUpdating,
  } = props;

  const { data } = useQuery(QUERY);
  const shopName = (data && data.shop && data.shop.name) || "karaktera";
  const shopDescription = (data && data.shop && data.shop.description) || "";
  const cartHasItems = checkout.lineItems.edges.length > 0;

  return (
    <App bg={"white"}>
      <Helmet title={title} description={description} />
      <Header
        name={shopName}
        description={shopDescription}
        isCartOpen={isCartOpen}
        onCartOpen={() => {
          setIsCartOpen(true);
        }}
        cartHasItems={cartHasItems}
      />
      <Cart
        removeLineItemInCart={removeLineItemInCart}
        updateLineItemInCart={updateLineItemInCart}
        checkout={checkout}
        isCartOpen={isCartOpen}
        handleCartClose={handleCartClose}
        handleOpenCheckout={handleOpenCheckout}
        checkoutUpdating={checkoutUpdating}
      />
      <ChildrenContainer>
        {children({
          handleCartOpen: handleCartOpen,
          handleOpenCheckout: handleOpenCheckout,
        })}
      </ChildrenContainer>
      {displayNewsletter && <NewsLetter />}
      <Footer />
    </App>
  );
};

const QUERY = gql`
  query query {
    shop {
      name
      description
    }
  }
`;

export default Layout;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SelectOriginal from "@material-ui/core/NativeSelect";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
}));

export function Select(props) {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <SelectOriginal {...props} fullWidth>
        {props.options.map((value) => {
          return (
            <option value={value} key={value}>
              {value}
            </option>
          );
        })}
      </SelectOriginal>
    </FormControl>
  );
}

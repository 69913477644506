export const getProductsData = (productsData) => {
  const products = productsData.node.products.edges.map((productData) => {
    return getProductData(productData);
  });

  products.sort((a, b) => {
    return a.title < b.title;
  });

  return products;
};

const getProductData = (productData) => {
  const product = productData.node;

  const { descriptionHtml, id, options, priceRange, title } = product;
  const variantData = product.variants;
  const { minVariantPrice, maxVariantPrice } = priceRange;

  const productPriceRange = {
    min: {
      amount: minVariantPrice.amount,
      currency: minVariantPrice.currencyCode,
    },
    max: {
      amount: maxVariantPrice.amount,
      currency: maxVariantPrice.currencyCode,
    },
  };

  const image = product.images.edges[0].node.src;
  const images = product.images.edges.map((item) => {
    const { node } = item;
    return node.src;
  });

  const variants = variantData.edges.map((variantData) => {
    const variant = variantData.node;
    const { id, price, selectedOptions, title } = variant;
    const variantImageData = variant.image;
    const image = variantImageData.originalSrc;
    const variantOptions = selectedOptions.map((option) => {
      return {
        name: option.name,
        value: option.value,
      };
    });

    return {
      id,
      image,
      price,
      title,
      product,
      variantOptions,
    };
  });

  return {
    id,
    descriptionHtml,
    image,
    images,
    options,
    priceRange: productPriceRange,
    title,
    variants,
  };
};

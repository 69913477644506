import React from "react";

import Layout from "src/components/Layout";
import * as AppContext from "src/App.context";

import { ReviewYourOrder } from "./ReviewYourOrder";

const ReviewYourOrderProvider = () => {
  return (
    <AppContext.Consumer>
      {(appContext) => {
        const {
          checkout,
          checkoutUpdating,
          removeLineItemInCart,
          updateLineItemInCart,
        } = appContext;

        return (
          <Layout {...appContext}>
            {(layoutContext) => {
              const { handleOpenCheckout } = layoutContext;

              return (
                <ReviewYourOrder
                  checkout={checkout}
                  handleOpenCheckout={handleOpenCheckout}
                  removeLineItemInCart={removeLineItemInCart}
                  updateLineItemInCart={updateLineItemInCart}
                  checkoutUpdating={checkoutUpdating}
                />
              );
            }}
          </Layout>
        );
      }}
    </AppContext.Consumer>
  );
};

export default ReviewYourOrderProvider;

import React, { useState } from "react";
import styled from "styled-components";
import { XCircle } from "react-feather";

import { Box, Flex, Text } from "src/components/Primitives";

const NotificationContainer = styled(Flex)``;

const CloseButtonContainer = styled(Box)`
  position: absolute;
  right: 0;
  top: 10px;
  margin-right: 10px;
  cursor: pointer;
`;

const NotificationBar = () => {
  const [open, setOpen] = useState(true);

  function handleClick() {
    setOpen(false);
  }

  if (!open) {
    return null;
  }

  return (
    <NotificationContainer
      alignItems={"center"}
      bg={"darkGray"}
      justifyContent={"center"}
      textAlign={"center"}
      px={10}
      position={"relative"}
      width={1}
      pt={2}
      pb={3}
    >
      <Text
        color={"white"}
        textAlign={"center"}
        fontSize={7}
        fontWeight={"600"}
      >
        <span role="img" aria-label={"shipping box"}>
          📦
        </span>{" "}
        Worldwide, flat fee shipping!{" "}
        <span role="img" aria-label={"shipping box"}>
          📦
        </span>
      </Text>
      <CloseButtonContainer onClick={handleClick}>
        <XCircle color={"white"} size={"20px"} />
      </CloseButtonContainer>
    </NotificationContainer>
  );
};

export default NotificationBar;

import React, { useEffect } from "react";

import { Fade } from "src/components/Fade";
import { MarkdownContainer } from "src/components/MarkdownContainer";
import { MaxWidthContainer } from "src/components/MaxWidthContainer";
import { MainTitle, Page } from "src/components/Primitives";
import { scrollToTop } from "src/utilities/misc";

import { LayoutWrapper } from "src/components/LayoutWrapper";

export const BlogPage = ({ data }) => {
  useEffect(() => {
    scrollToTop();
  }, []);

  if (!data) {
    return null;
  }

  return (
    <LayoutWrapper>
      <Fade>
        <Page>
          <MaxWidthContainer contentContainer>
            <MainTitle>{data.title}</MainTitle>
            <MarkdownContainer content={data.contentHtml} />
          </MaxWidthContainer>
        </Page>
      </Fade>
    </LayoutWrapper>
  );
};

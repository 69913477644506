import React, { useEffect } from "react";
import { Element, Events, scroller } from "react-scroll";

import { Delayed } from "src/components/Delayed";
import { Box, Flex, SectionTitle } from "src/components/Primitives";
import { LayoutWrapper } from "src/components/LayoutWrapper";
import { MaxWidthContainer } from "src/components/MaxWidthContainer";
import { PaymentIcons } from "src/components/PaymentIcons";

import { HeroSection } from "./components/HeroSection";
import { InfoSection } from "./components/InfoSection";
import { ProductSection } from "./components/ProductSection";
import { TestimonialsSection } from "./components/TestimonialsSection";
import { Walkthrough } from "./components/Walkthrough";

import { Gallery } from "src/sections/Gallery";

export const LandingPage = () => {
  useEffect(() => {
    Events.scrollEvent.register("begin", function(to, element) {});

    Events.scrollEvent.register("end", function(to, element) {});

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  const scrollTo = () => {
    scroller.scrollTo("scroll-to-element", {
      duration: 800,
      delay: 150,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <LayoutWrapper>
      <Box>
        <Box mb={[5]}>
          <HeroSection />
        </Box>

        <Box px={[10]} mb={[15, 20]}>
          <MaxWidthContainer>
            <Walkthrough />
          </MaxWidthContainer>
        </Box>

        <Flex justifyContent={"center"}>
          <Box px={10} width={[1, 1, 0.8, 0.7]} mb={[15, 20, 22]}>
            <ProductSection onClick={scrollTo} />
          </Box>
        </Flex>

        <Box px={[10]} pb={20}>
          <MaxWidthContainer>
            <Flex mb={10} justifyContent={"left"}>
              <Element name="scroll-to-element">
                <SectionTitle>
                  Choose a design to <br />
                  get started
                </SectionTitle>
              </Element>
            </Flex>
            <Delayed wait={500}>
              <Gallery />
            </Delayed>
          </MaxWidthContainer>
        </Box>

        {/* <Box px={[10]} pb={20}>
          <MaxWidthContainer>
            <Flex mb={10} justifyContent={"center"}>
              <SectionTitle>What our customers are saying</SectionTitle>
            </Flex>
            <TestimonialsSection />
          </MaxWidthContainer>
        </Box> */}

        <Box px={[10]} mb={[15, 20, 22]}>
          <MaxWidthContainer>
            <InfoSection />
          </MaxWidthContainer>
        </Box>

        <Box px={[10]} pb={10}>
          <MaxWidthContainer>
            <Flex justifyContent={"center"}>
              <PaymentIcons />
            </Flex>
          </MaxWidthContainer>
        </Box>
      </Box>
    </LayoutWrapper>
  );
};

import React, { useEffect } from "react";
import { Fade } from "src/components/Fade";
import { MaxWidthContainer } from "src/components/MaxWidthContainer";
import { Box, MainTitle, Page } from "src/components/Primitives";
import { scrollToTop } from "src/utilities/misc";

import { FaqContent } from "src/components/FaqContent";
import { LayoutWrapper } from "src/components/LayoutWrapper";

import { TitleContainer } from "./Faq.components";

export const Faq = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <LayoutWrapper>
      <Fade>
        <Page>
          <MaxWidthContainer contentContainer>
            <TitleContainer>
              <MainTitle>Frequently Asked Questions</MainTitle>
            </TitleContainer>
            <Box>
              <FaqContent />
            </Box>
          </MaxWidthContainer>
        </Page>
      </Fade>
    </LayoutWrapper>
  );
};

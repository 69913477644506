import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";

import {
  Box as RebassBox,
  Card,
  Flex as RebassFlex,
  Image,
  Heading,
  Text as TextBase,
} from "rebass";

export const Box = styled(RebassBox)`
  ${({ height }) => {
    return `height: ${height && height};`;
  }};
`;

export const Flex = styled(RebassFlex)`
  ${({ height }) => {
    return `height: ${height && height};`;
  }};
`;

// Padding-bottom = (Height ÷ Width) × 100;
export const LazyContainer = styled(Box)`
  display: block;
  position: relative;
  height: 0;
  width: 100%;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Text = styled(TextBase)`
  ${({ textTransform }) => {
    return `text-transform: ${textTransform && textTransform};`;
  }};
`;

const BodyTextStyle = styled(TextBase)`
  ${({ textTransform, ...props }) => {
    return `
        text-transform: ${textTransform && textTransform};
        color: ${themeGet("colors.gray")(props)};
      `;
  }};
`;

export const BodyText = (props) => {
  return <BodyTextStyle lineHeight={"body"} {...props} />;
};

export const CopyText = (props) => {
  return <BodyTextStyle lineHeight={"copy"} fontFamily={"body"} {...props} />;
};

export const MainTitle = (props) => {
  return (
    <Text
      as={"h1"}
      fontSize={3}
      textAlign={"center"}
      fontWeight={"900"}
      mb={6}
      {...props}
    />
  );
};

export const SectionTitle = (props) => {
  return (
    <Text
      as={"h2"}
      fontSize={[4, 3, 3]}
      fontWeight={"700"}
      lineHeight={"title"}
      {...props}
    />
  );
};

export const BodyTitle = (props) => {
  return (
    <Text
      fontSize={6}
      textAlign={"center"}
      fontWeight={"800"}
      textTransform={"uppercase"}
      mb={3}
      {...props}
    />
  );
};

export const Subtitle = (props) => {
  return (
    <Text
      as={"h2"}
      fontSize={4}
      textAlign={"left"}
      fontWeight={"600"}
      {...props}
    />
  );
};

export const Page = ({ children }) => {
  return (
    <Box px={[5, 10, 20]} py={[10, 20]} pb={10}>
      {children}
    </Box>
  );
};

export const SrOnly = styled(Box)`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;

export { Card, Image, Heading };

import React from "react";

import { Fade } from "src/components/Fade";

import { Box } from "src/components/Primitives";

import { LayoutWrapper } from "src/components/LayoutWrapper";

import { Gallery } from "src/sections/Gallery";

export class Shop extends React.Component {
  render() {
    return (
      <LayoutWrapper title={"Shop"}>
        <Box px={10} py={10} pb={10}>
          <Fade>
            <Gallery />
          </Fade>
        </Box>
      </LayoutWrapper>
    );
  }
}

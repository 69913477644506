import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import colorConvert from "color-convert";

import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";

import { getBreakPoint } from "src/styles/theme";
import { ChromePicker } from "react-color";
import ColorPalette from "./ColorPalette";

import {
  ControllerContainer,
  SlidersContainer,
  SliderContainer,
  SliderLabel,
} from "./DesignController.components";

import { CollapsibleSection } from "./CollapsibleSection/";
import { Select } from "./Select";
import { Slider } from "./Slider";
import { TabsSection } from "../TabsSection/";

export const DesignController = (props) => {
  const {
    configuration,
    initialState,
    stateCallback,
    useTabs,
    isBgIgnored,
  } = props;
  const categories = getCategoriesFromConfiguration(configuration);
  const hasCategories = categories.length > 0;
  const firstCategoryLabel = hasCategories ? categories[0].label : null;

  const [state, setState] = useState({});
  const [expanded, setExpanded] = React.useState(firstCategoryLabel);
  const screenBreakpoint = getBreakPoint("tablet");
  const isLargerThanBreakpoint = useMediaQuery({
    query: `(min-width: ${screenBreakpoint}px)`,
  });

  // set state if initial state is given and there is no internal state.
  useEffect(() => {
    // want to run this only if the internal state is empty
    if (Object.keys(state).length !== 0) {
      return null;
    }

    // console.log(initialState, typeof initialState);

    const newState = {};
    const hasInitialState =
      initialState && Object.keys(initialState).length > 0;

    // construct state from initialState;
    if (hasInitialState) {
      Object.keys(initialState).forEach((key) => {
        const value = initialState[key];
        newState[key] = value;
      });

      // console.log(hasInitialState);
      // console.log(newState);
    } else {
      Object.keys(configuration).forEach((index) => {
        const itemName = configuration[index].name;
        const itemValue = configuration[index].default;

        newState[itemName] = itemValue;
      });
    }

    setState(newState);
    // update the top level state.
    if (stateCallback) stateCallback(newState);
  }, []);

  function handleChange(_e, value, key) {
    const { stateCallback } = props;
    const newState = { ...state };
    newState[key] = value;

    setState(newState);
    if (stateCallback) stateCallback(newState);
  }

  function handleCategoryChange(categoryLabel) {
    return (_event, isExpanded) => {
      setExpanded(isExpanded ? categoryLabel : false);
    };
  }

  function getCategoriesFromConfiguration(configuration) {
    const categories = configuration.filter((configItem) => {
      const { type } = configItem;
      return type === "category";
    });

    return categories;
  }

  const getCategorySection = (controllers) => {
    if (!configuration) {
      return null;
    }

    const categories = getCategoriesFromConfiguration(configuration);

    const categoryComponents = categories.map((category) => {
      const { label, name } = category;

      return (
        <CollapsibleSection
          controllers={controllers}
          label={label}
          name={name}
          key={label}
          expanded={expanded}
          handleChange={handleCategoryChange}
        />
      );
    });

    return categoryComponents;
  };

  const getControllers = () => {
    if (!configuration) {
      return null;
    }

    const controllersExceptCategories = configuration.filter(
      (configurationItem) => configurationItem.type !== "category"
    );

    const controllers = controllersExceptCategories.map((data, index) => {
      const {
        category,
        defaultValue,
        name,
        label,
        min,
        max,
        options,
        type,
      } = data;

      if (defaultValue === undefined) {
        throw new Error(
          `no default value defined for parameter controller ${label}`
        );
      }

      // TODO: to not render the background for TShirts. Not the best implementation
      if (name === "bgColor" || label === "Background Color") {
        if (isBgIgnored) {
          return null;
        }
      }

      const controllerValue =
        state[name] === undefined ? defaultValue : state[name];

      const onChangeCallback = (e, value) => handleChange(e, value, name);

      const controllerProps = {
        value: controllerValue,
        onChange: onChangeCallback,
        options,
      };

      let controllerComponent;

      switch (type) {
        case "scalar":
          controllerProps.step = 1;
          controllerProps.min = min;
          controllerProps.max = max;
          controllerComponent = <Slider {...controllerProps} />;
          break;
        case "float":
          controllerProps.min = min;
          controllerProps.max = max;
          controllerProps.step = 0.01;
          controllerComponent = <Slider {...controllerProps} />;
          break;
        case "boolean":
          let checkboxProps = { ...controllerProps };
          delete checkboxProps.value;
          checkboxProps.checked = controllerValue;
          controllerComponent = <Switch {...checkboxProps} />;
          break;
        case "color":
          let colorComponentProps = {
            color: controllerProps.value,
            disableAlpha: true,
            onChange: (color) => {
              const cmykColor = colorConvert.hex.cmyk(color.hex);
              const hexColor = colorConvert.cmyk.hex(cmykColor);
              handleChange(null, `#${hexColor}`, name);
            },
          };
          controllerComponent = <ChromePicker {...colorComponentProps} />;
          break;
        case "colorPalette":
          let colorPaletteProps = {
            colors: controllerProps.value,
            onChange: (colors) => handleChange(null, colors, name),
          };
          controllerComponent = <ColorPalette {...colorPaletteProps} />;
          break;
        case "select":
          let selectProps = {
            ...controllerProps,
          };
          selectProps.onChange = (e) => handleChange(e, e.target.value, name);

          controllerComponent = <Select {...selectProps} />;
          break;
        case "text":
          let textProps = {
            ...controllerProps,
          };
          textProps.onChange = (e) => handleChange(e, e.target.value, name);

          controllerComponent = (
            <TextField variant="outlined" {...textProps} fullWidth />
          );
          break;
        default:
          break;
      }

      const component = (
        <SliderContainer key={index}>
          <SliderLabel>{label}</SliderLabel>
          {controllerComponent}
        </SliderContainer>
      );

      return { component, category };
    });

    return controllers.filter((controller) => Boolean(controller));
  };

  let content;
  const controllers = getControllers();

  if (hasCategories) {
    const categorySection = getCategorySection(controllers);
    const tabsSection = (
      <TabsSection controllers={controllers} categories={categories} />
    );
    content =
      !isLargerThanBreakpoint || useTabs ? tabsSection : categorySection;
  } else {
    content = controllers.map((controller) => controller.component);
  }

  return (
    <ControllerContainer>
      <SlidersContainer {...props}>{content}</SlidersContainer>
    </ControllerContainer>
  );
};

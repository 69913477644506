import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Fade } from "src/components/Fade";

import { getBreakPoint } from "src/styles/theme";
import { Text } from "src/components/Primitives";
import DesignDisplay from "src/components/DesignDisplay";
import { MaxWidthContainer } from "src/components/MaxWidthContainer";
import { imagePaths } from "src/images";

import {
  sketch,
  sketchConfig as config,
  DesignControllerMainPage,
  HeroContainer,
  HeroImageContainer,
  CustomDesignContainer,
  HeroSectionContainer,
  TitleContainer,
  DesignControllerContainer,
  DesignControllerContainerInner,
  LazyContainer,
} from "./index.components";

export const HeroSection = () => {
  const [state, setState] = useState(config);

  const mobileBreakpoint = getBreakPoint("mobile");
  const tabletBreakpoint = getBreakPoint("tablet");

  const isLargerThanMobile = useMediaQuery({
    query: `(min-width: ${mobileBreakpoint}px)`,
  });

  const isSmallerThanTablet = useMediaQuery({
    query: `(max-width: ${tabletBreakpoint}px)`,
  });

  const isMobile = !isLargerThanMobile;
  const isTablet = isLargerThanMobile && isSmallerThanTablet;

  function getState(state) {
    setState(state);
  }

  const titleMarkup = (
    <Text
      fontSize={[3, 3, 3, 2]}
      fontWeight={"900"}
      pb={[3, 0, 3]}
      color={["black", "black", "white"]}
      as={"h1"}
    >
      Customizable Designs <br />
      Tailored for Your Personality
    </Text>
  );

  const designDisplayMarkup = (
    <Fade delay={500}>
      <DesignDisplay
        styleConfig={{
          noPadding: isLargerThanMobile,
          noShadow: isLargerThanMobile,
        }}
        configuration={state}
        state={state}
        sketch={sketch}
        aspectRatio={{ x: 1, y: 1.36 }}
        extraState={{
          homeScreen: true,
          pixelDensity: 0.66,
        }}
      />
    </Fade>
  );

  return (
    <HeroContainer px={10}>
      <MaxWidthContainer>
        {isTablet && <TitleContainer isTablet>{titleMarkup}</TitleContainer>}
        <HeroSectionContainer>
          <HeroImageContainer>
            {!isTablet && (
              <TitleContainer isMobile={isMobile}>{titleMarkup}</TitleContainer>
            )}
            {isLargerThanMobile && (
              <LazyContainer pb={"66%"}>
                <Fade delay={300}>
                  <img
                    style={{ width: "100%" }}
                    src={imagePaths.hero}
                    alt="Customize your design"
                  />
                </Fade>
              </LazyContainer>
            )}
          </HeroImageContainer>

          <CustomDesignContainer isMobile={isMobile}>
            {designDisplayMarkup}
          </CustomDesignContainer>
        </HeroSectionContainer>
        <DesignControllerContainer isMobile={isMobile} isTablet={isTablet}>
          <DesignControllerContainerInner width={[1, 1, 0.6]}>
            <DesignControllerMainPage getState={getState} config={config} />
          </DesignControllerContainerInner>
        </DesignControllerContainer>
      </MaxWidthContainer>
    </HeroContainer>
  );
};

import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";

const ButtonWrapper = styled.button`
  ${({
    disabled,
    noBorder,
    type,
    fullWidth,
    marginLeft,
    marginRight,
    ...props
  }) => {
    let defaultStyle = ``;

    switch (type) {
      case "main": {
        defaultStyle = `
          background: black;
          border: 2px solid black;
          border-radius: 2px;
          color: white;
        `;
        break;
      }

      case "pink": {
        defaultStyle = `
          background: ${themeGet("colors.pink")(props)};;
          border: 2px solid ${themeGet("colors.pink")(props)};;
          border-radius: 2px;
          color: white;
        `;
        break;
      }

      default: {
        defaultStyle = `
          background: transparent;
          border: 2px solid #8783d1;
          border-radius: 2px;
          color: #8783d1;
        `;
      }
    }

    return `
      cursor: pointer;
      width: 100%;
      padding: 10px 20px;
      font-size: 1em;
      transition: all 0.2s ease-in-out;
    
      font-weight: 900;
      letter-spacing: 0em;
      line-height: 1.3;
      display: inline-block;
      margin: 0;
      width: auto;
      font-size: 1em;
      text-decoration: none;
      text-transform: uppercase;
      text-align: center;
      user-select: none;
      vertical-align: middle;
      white-space: normal;

      ${defaultStyle}

      ${fullWidth ? `width: 100%;` : ""}
      ${marginRight ? `margin-right: ${marginRight};` : ""}
      ${marginLeft ? `margin-left: ${marginLeft};` : ""}
      ${disabled &&
        `
        border: 2px solid ${themeGet("colors.midGray")(props)};
        color: ${themeGet("colors.midGray")(props)};
        background: inherit;
      `}

      ${noBorder && "border: none;"}
    `;
  }}
`;

export const Button = ({ children, onClick, ...props }) => {
  return (
    <ButtonWrapper {...props} onClick={onClick}>
      {children}
    </ButtonWrapper>
  );
};

export const MainButton = (props) => {
  return <Button {...props} type={"main"} />;
};

export default Button;

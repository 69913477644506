import React from "react";

import { Box, MainTitle, Page } from "src/components/Primitives";
import { MaxWidthContainer } from "src/components/MaxWidthContainer";

import { LayoutWrapper } from "src/components/LayoutWrapper";
import DesignWithController from "src/components/DesignWithController";

import { accurateQuilt as sketch } from "src/artwork";

let newConfiguration = [];
newConfiguration = newConfiguration.concat([
  {
    name: "text",
    type: "text",
    defaultValue: "404",
    label: "Text",
  },
  {
    name: "spacingMultiplier",
    type: "float",
    min: 0,
    max: 10,
    defaultValue: 0.1,
    label: "Spacing Multiplier",
  },
  {
    name: "textSize",
    type: "float",
    min: 200,
    max: 1000,
    defaultValue: 400,
    label: "Text Size",
  },
  {
    name: "textCopyAmount",
    type: "scalar",
    min: 0,
    max: 80,
    defaultValue: 20,
    label: "Text Copy Amount",
  },
]);

const NoMatch = () => {
  return (
    <LayoutWrapper title={"404"}>
      <Page>
        <MaxWidthContainer>
          <MainTitle>Page Not Found</MainTitle>
          <Box>
            <DesignWithController
              configuration={newConfiguration}
              sketch={sketch}
              page={"404"}
            ></DesignWithController>
          </Box>
        </MaxWidthContainer>
      </Page>
    </LayoutWrapper>
  );
};

export default NoMatch;

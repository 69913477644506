import React from "react";
import { hydrate, render } from "react-dom";
import ReactGA from "react-ga";

import { ApolloProvider } from "react-apollo";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider as StyledComponentThemeProvider } from "styled-components";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { QueryParamProvider } from "use-query-params";

// local imports

import { apolloClient } from "src/utilities/apolloClient";
import theme from "./app/styles/theme";
import "./app/app.css";
import App from "./app/App";
import {
  About,
  CustomizeYourDesign,
  DesignCollection,
  NoMatch,
  Shop,
  ShippingAndReturns,
  Playground,
  ReviewYourOrder,
  Blog,
  BlogPage,
  Faq,
  TermsOfService,
  PrivacyPolicy,
} from "./app/sections";
import { GA_TRACKING_ID } from "./config/config";

import * as AppContext from "./app/App.context";
import NotificationBar from "./app/components/NotificationBar";

const muiTheme = createMuiTheme();
// DON'T DO TRACKING TILL I FIGURE OUT EU STUFF
ReactGA.initialize(GA_TRACKING_ID, {
  debug: true,
  standardImplementation: true,
});

const AppContainer = () => {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <StyledComponentThemeProvider theme={theme}>
        <ApolloProvider client={apolloClient}>
          <AppContext.Provider>
            <NotificationBar />
            <Router>
              <QueryParamProvider ReactRouterRoute={Route}>
                <Switch>
                  <Route exact path="/" component={App} />
                  <Route
                    path="/design/:designType/:designId"
                    component={DesignCollection}
                  />
                  <Route
                    path="/customize/:designType/:designId"
                    component={CustomizeYourDesign}
                  />
                  <Route path="/about/" component={About} />
                  <Route path="/shop/" component={Shop} />
                  <Route path="/playground/" component={Playground} />
                  <Route path="/review/" component={ReviewYourOrder} />
                  <Route
                    path="/shipping-and-returns/"
                    component={ShippingAndReturns}
                  />
                  <Route path="/terms-of-service/" component={TermsOfService} />
                  <Route path="/privacy-policy/" component={PrivacyPolicy} />
                  <Route path="/faq" component={Faq} />
                  <Route path="/blog/:article" component={BlogPage} />
                  <Route path="/blog" component={Blog} />
                  <Route path="*">
                    <NoMatch />
                  </Route>
                </Switch>
              </QueryParamProvider>
            </Router>
          </AppContext.Provider>
        </ApolloProvider>
      </StyledComponentThemeProvider>
    </MuiThemeProvider>
  );
};

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<AppContainer />, rootElement);
} else {
  render(<AppContainer />, rootElement);
}

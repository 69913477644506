import { lerp } from "canvas-sketch-util/math";

import {
  exists,
  getConfigValueIfUndefined,
  getValueIfUndefined,
} from "../../utilities/misc";

import { personalities } from "../../utilities/enneagram";
import Montserrat from "../../assets/fonts/Montserrat-Black.ttf";

export const config = [
  {
    name: "category1",
    type: "category",
    label: "Text Controls",
  },
  {
    name: "category3",
    type: "category",
    label: "Text Copy Controls",
  },
  {
    name: "category2",
    type: "category",
    label: "Styling Controls",
  },
  {
    name: "personality",
    type: "select",
    defaultValue: Object.keys(personalities)[0],
    options: Object.keys(personalities),
    label: "Enneagram Personality Type",
    category: "category1",
  },
  {
    name: "textSizeMultiplier",
    type: "float",
    min: 0.1,
    max: 1,
    defaultValue: 0.7,
    label: "Text Size Multiplier",
    category: "category1",
  },
  {
    name: "textPositionOffset",
    type: "float",
    min: -400,
    max: 400,
    defaultValue: -0,
    label: "Text Position Offset",
    category: "category1",
  },
  {
    name: "textCopyTiltAmount",
    type: "float",
    min: -0.3,
    max: 0.3,
    defaultValue: -0.18,
    label: "Text Copy Tilt",
    category: "category1",
  },
  {
    name: "textHeight",
    type: "float",
    min: 10,
    max: 500,
    defaultValue: 85,
    label: "Text Height",
    category: "category1",
  },
  {
    name: "strokeWeight",
    type: "float",
    min: 0,
    max: 60,
    defaultValue: 10,
    label: "Stroke Weight",
    category: "category2",
  },
  {
    name: "strokeColor",
    type: "color",
    defaultValue: "#142149",
    label: "Stroke Color",
    category: "category2",
  },
  {
    name: "textColor",
    type: "color",
    defaultValue: "#bba3dc",
    label: "Text Color",
    category: "category2",
  },
  {
    name: "bgColor",
    type: "color",
    defaultValue: "#b9d0db",
    label: "Background Color",
    category: "category2",
  },
  {
    name: "spacing",
    type: "scalar",
    min: 0,
    max: 100,
    defaultValue: 12,
    label: "Spacing",
    category: "category3",
  },
  {
    name: "spacingMultiplier",
    type: "float",
    min: 0,
    max: 10,
    defaultValue: 0.3,
    label: "Spacing Multiplier",
    category: "category3",
  },
  {
    name: "textCopyAmount",
    type: "scalar",
    min: 1,
    max: 50,
    defaultValue: 18,
    label: "Text Copy Amount",
    category: "category3",
  },
];

const sketch = (p) => {
  const givenState = document.p5jsState;
  const state = givenState || {};
  const configFromState = state.configuration;
  let isAnimated = givenState.homeScreen;
  let bgOverrideColor = givenState.bgColor;

  let personality = getConfigValueIfUndefined(
    config,
    configFromState,
    "personality"
  );
  let textSizeMultiplier = getConfigValueIfUndefined(
    config,
    configFromState,
    "textSizeMultiplier"
  );
  let textPositionOffset = getConfigValueIfUndefined(
    config,
    configFromState,
    "textPositionOffset"
  );
  let textCopyTiltAmount = getConfigValueIfUndefined(
    config,
    configFromState,
    "textCopyTiltAmount"
  );
  let textHeight = getConfigValueIfUndefined(
    config,
    configFromState,
    "textHeight"
  );
  let spacing = getConfigValueIfUndefined(config, configFromState, "spacing");
  let spacingMultiplier = getConfigValueIfUndefined(
    config,
    configFromState,
    "spacingMultiplier"
  );
  let textCopyAmount = getConfigValueIfUndefined(
    config,
    configFromState,
    "textCopyAmount"
  );
  let strokeWeight = getConfigValueIfUndefined(
    config,
    configFromState,
    "strokeWeight"
  );
  let textColor = getConfigValueIfUndefined(
    config,
    configFromState,
    "textColor"
  );
  let strokeColor = getConfigValueIfUndefined(
    config,
    configFromState,
    "strokeColor"
  );
  let bgColor = getConfigValueIfUndefined(config, configFromState, "bgColor");

  let screenSizeX = getValueIfUndefined(state, "screenSizeX", 1024);
  let screenSizeY = getValueIfUndefined(state, "screenSizeY", 1024);
  let pixelDensity = getValueIfUndefined(state, "pixelDensity", 0.5);

  p.myCustomRedrawAccordingToNewPropsHandler = (props) => {
    if (exists(props.personality)) {
      personality = props.personality;
    }

    if (exists(props.textSizeMultiplier)) {
      textSizeMultiplier = parseFloat(props.textSizeMultiplier);
    }

    if (exists(props.textPositionOffset)) {
      textPositionOffset = parseFloat(props.textPositionOffset);
    }

    if (exists(props.textCopyTiltAmount)) {
      textCopyTiltAmount = parseFloat(props.textCopyTiltAmount);
    }

    if (exists(props.textHeight)) {
      textHeight = parseFloat(props.textHeight);
    }

    if (exists(props.spacing)) {
      spacing = parseInt(props.spacing, 10);
    }

    if (exists(props.spacingMultiplier)) {
      spacingMultiplier = parseFloat(props.spacingMultiplier);
    }

    if (exists(props.textCopyAmount)) {
      textCopyAmount = parseInt(props.textCopyAmount, 10);
    }

    if (exists(props.strokeWeight)) {
      strokeWeight = parseFloat(props.strokeWeight);
    }

    if (exists(props.textColor)) {
      textColor = props.textColor;
    }

    if (exists(props.strokeColor)) {
      strokeColor = props.strokeColor;
    }

    if (exists(props.bgColor)) {
      bgColor = props.bgColor;
    }
  };

  let myFont;

  p.preload = () => {
    myFont = p.loadFont(Montserrat);
  };

  p.setup = () => {
    const canvas = p.createCanvas(screenSizeX, screenSizeY);
    canvas.drawingContext.miterLimit = 2;

    p.pixelDensity(pixelDensity);

    p.rectMode(p.CENTER);
    p.angleMode(p.DEGREES);
    p.frameRate(isAnimated ? 24 : 6);
    p.textAlign(p.CENTER, p.CENTER);
  };

  p.draw = () => {
    if (bgOverrideColor) {
      p.background(bgOverrideColor);
    } else {
      p.background(bgColor);
    }
    p.textFont(myFont);

    const width = p.width;
    const height = p.height;
    const originX = width / 2;
    const originY = height / 2;

    if (isAnimated) {
      const animationValue = lerp(-0.1, 0.1, p.sin(p.frameCount * 2) + 1);
      textCopyTiltAmount = animationValue;
    }

    p.push();
    p.translate(0, originY + textPositionOffset);

    for (let i = spacing * textCopyAmount; i > 0; i -= spacing) {
      // styling
      {
        let finalTextColor = textColor;
        let finalStrokeColor = strokeColor;

        p.fill(finalTextColor);
        p.strokeWeight(strokeWeight);
        p.stroke(finalStrokeColor);
      }
      // styling ends

      p.push();
      p.translate(0 - i * textCopyTiltAmount, i * spacingMultiplier);

      {
        // draw text
        p.push();
        let finalText = "";
        let maxWidth = 0;
        personalities[personality].keyFeatures.forEach((feature) => {
          const text = feature.toUpperCase();
          const textWidth = p.textWidth(text);
          maxWidth = Math.max(textWidth, maxWidth);
          finalText = finalText + text + "\n";
        });

        const textSize = 100;
        const appliedTextSize = textSize * textSizeMultiplier;
        p.textSize(appliedTextSize);
        p.textLeading(textHeight);
        p.text(finalText, originX, 0);
        p.pop();
        // draw text ends
      }

      p.pop();
    }

    p.pop();
  };
};

export default sketch;

import { useEffect, useState } from "react";

export const Delayed = ({ children, wait }) => {
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHidden(false);
    }, wait);

    return () => clearTimeout(timer);
  }, []);

  return hidden ? null : children;
};

import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";

import {
  SITE_GRAPHQL_ENDPOINT,
  SHOPIFY_STOREFRONT_ACCESS_TOKEN
} from "../../config/config";

const httpLink = createHttpLink({
  uri: SITE_GRAPHQL_ENDPOINT
});

const middlewareLink = setContext(() => ({
  headers: {
    "X-Shopify-Storefront-Access-Token": SHOPIFY_STOREFRONT_ACCESS_TOKEN
  }
}));

export const apolloClient = new ApolloClient({
  link: middlewareLink.concat(httpLink),
  cache: new InMemoryCache()
});

export function createVariantOptionsTable(product) {
  const variantOptionTable = {};

  if (!product) {
    return variantOptionTable;
  }

  const allVariantOptions = product.variants.map(
    (variant) => variant.variantOptions
  );

  allVariantOptions.forEach((optionBundle) => {
    optionBundle.forEach((optionItem) => {
      const { name: optionName, value: optionValue } = optionItem;

      const optionKeyExists = variantOptionTable[optionName];
      if (!optionKeyExists) {
        variantOptionTable[optionName] = {};
        variantOptionTable[optionName][optionValue] = {};
      }

      const optionValueForKeyExists =
        variantOptionTable[optionName][optionValue];
      if (!optionValueForKeyExists) {
        variantOptionTable[optionName][optionValue] = {};
      }

      optionBundle.forEach((otherOptionItem) => {
        const {
          name: otherOptionName,
          value: otherOptionValue,
        } = otherOptionItem;
        if (otherOptionName === optionName) {
          return;
        }

        const otherOptionKeyExists =
          variantOptionTable[optionName][optionValue][otherOptionName];
        if (!otherOptionKeyExists) {
          variantOptionTable[optionName][optionValue][otherOptionName] = [];
        }

        variantOptionTable[optionName][optionValue][otherOptionName].push(
          otherOptionValue
        );
      });
    });
  });

  return variantOptionTable;
}

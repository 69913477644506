import React from "react";
import { Helmet as ReactHelmet } from "react-helmet";

export const Helmet = ({ description, title }) => {
  const siteName = "Karaktera";
  const titleTag = "Designs for zodiac sign and Enneagram personality type";

  const siteTitle = `${
    title ? `${title} - ${siteName}` : `${titleTag} - ${siteName}`
  }`;
  const siteUrl = "https://www.karaktera.com";
  const siteDescription =
    "Unique, personalized prints for your zodiac sign and Enneagram personality type. We deliver around the world.";
  const keywords =
    "Enneagram, Personality Type, Zodiac Signs, Astrology, Capricorn, Aquarius, Pisces, Aries, Taurus, Gemini, Cancer, Leo, Virgo, Libra, Scorpio, Sagittarius";

  return (
    <ReactHelmet>
      <meta name="description" content={description || siteDescription} />
      <meta
        name="robots"
        content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
      <link rel="canonical" href={siteUrl} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={siteTitle} />
      <meta property="og:description" content={siteDescription} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:site_name" content={siteName} />
      <meta name="twitter:description" content={siteDescription} />
      <meta name="twitter:title" content={siteTitle} />
      <meta itemprop="keywords" content={keywords} />
      <meta name="keywords" content={keywords} />
      <title>{siteTitle}</title>
    </ReactHelmet>
  );
};

import React from "react";
import marked from "marked";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { Fade } from "src/components/Fade";

import {
  Box,
  Flex,
  Subtitle,
  CopyText,
  MainTitle,
  Page,
  LazyContainer,
} from "src/components/Primitives";
import { Button } from "src/components/Button";
import { LayoutWrapper } from "src/components/LayoutWrapper";
import { MaxWidthContainer } from "src/components/MaxWidthContainer";
import { scrollToTop } from "src/utilities/misc";

import { imagePaths } from "src/images";

import theme from "src/styles/theme";

import content from "./content.json";

const CopyTextCustom = styled(CopyText)`
  a {
    color: ${theme.colors.pink};
    font-weight: ${theme.fontWeights[5]};
  }
`;

const Paragraph = ({ children, ...props }) => {
  return <CopyTextCustom {...props}>{children}</CopyTextCustom>;
};

const CenteredContainer = ({ children }) => {
  return <Flex justifyContent={"center"}>{children}</Flex>;
};

const createMarkup = (content) => {
  return { __html: content };
};

class About extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    scrollToTop();
    this.props.history.push(`/shop`);
  }

  render() {
    const allContent = content.map((contentItem, key) => {
      let { type, content: contentBody } = contentItem;

      if (type === "title") {
        return (
          <Box key={key}>
            <MainTitle>{contentBody}</MainTitle>
          </Box>
        );
      }

      if (type === "subtitle") {
        return (
          <Box pb={[5]} key={key}>
            <Subtitle>{contentBody}</Subtitle>
          </Box>
        );
      }

      if (type === "text") {
        contentBody = marked(contentBody);
        return (
          <Box pb={[5]} key={key}>
            <Paragraph dangerouslySetInnerHTML={createMarkup(contentBody)} />
          </Box>
        );
      }

      if (type === "image_01") {
        return (
          <Box pb={5} key={key} mb={10}>
            <CenteredContainer>
              <LazyContainer pb={"65%"}>
                <img src={imagePaths.about1} alt="" />
              </LazyContainer>
            </CenteredContainer>
          </Box>
        );
      }

      if (type === "image_02") {
        return (
          <Box pb={5} key={key}>
            <CenteredContainer>
              <LazyContainer pb={"66%"}>
                <img src={imagePaths.about2} alt="" />
              </LazyContainer>
            </CenteredContainer>
          </Box>
        );
      }

      return null;
    });

    return (
      <LayoutWrapper
        title={"About"}
        description={
          "Customizable designs that are related to Enneagram Type and Astrology on your favorite products"
        }
      >
        <Fade>
          <Page>
            <MaxWidthContainer contentContainer>
              <Box mb={5}>{allContent}</Box>
              <Flex justifyContent={"center"}>
                <Box mr={[0, 0, 2]} width={[1, 1, 0.25]} mb={[2, 2, 0]}>
                  <Button fullWidth onClick={this.handleClick} type={"pink"}>
                    Get Started
                  </Button>
                </Box>
              </Flex>
            </MaxWidthContainer>
          </Page>
        </Fade>
      </LayoutWrapper>
    );
  }
}

export default withRouter(About);

import { random } from "canvas-sketch-util";
import { lerp } from "canvas-sketch-util/math";
import { personalities } from "../../utilities/enneagram";

import {
  exists,
  getConfigValueIfUndefined,
  getValueIfUndefined,
} from "../../utilities/misc";
import Montserrat from "../../assets/fonts/Montserrat-Black.ttf";

export const config = [
  {
    name: "category1",
    type: "category",
    label: "Text Controls",
  },
  {
    name: "category2",
    type: "category",
    label: "Offset Controls",
  },
  {
    name: "category3",
    type: "category",
    label: "Other Controls",
  },
  {
    name: "seedValue",
    type: "scalar",
    min: 0,
    max: 9999,
    defaultValue: 2173,
    label: "Seed Value",
    category: "category3",
  },
  {
    name: "personality",
    type: "select",
    defaultValue: Object.keys(personalities)[0],
    options: Object.keys(personalities),
    label: "Enneagram Type",
    category: "category1",
  },
  {
    name: "overrideText",
    type: "text",
    defaultValue: "",
    label: "Override Text",
    category: "category1",
  },
  {
    name: "textSize",
    type: "float",
    min: 50,
    max: 400,
    defaultValue: 200,
    label: "Text Size",
    category: "category1",
  },
  {
    name: "bgColor",
    type: "color",
    defaultValue: "#ffffff",
    label: "Background Color",
    category: "category3",
  },
  {
    name: "offsetMultiplier",
    type: "float",
    min: 0,
    max: 2,
    defaultValue: 0.1,
    label: "Offset Multiplier",
    category: "category2",
  },
  {
    name: "offsetMultiplierX",
    type: "float",
    min: 0,
    max: 2,
    defaultValue: 1,
    label: "Offset Multiplier X",
    category: "category2",
  },
  {
    name: "offsetMultiplierY",
    type: "float",
    min: 0,
    max: 2,
    defaultValue: 1,
    label: "Offset Multiplier Y",
    category: "category2",
  },
];

const sketch = (p) => {
  const givenState = document.p5jsState;
  const state = givenState || {};
  const configFromState = state.configuration;

  let personality = getConfigValueIfUndefined(
    config,
    configFromState,
    "personality"
  );
  let overrideText = getConfigValueIfUndefined(
    config,
    configFromState,
    "overrideText"
  );
  let seedValue = getConfigValueIfUndefined(
    config,
    configFromState,
    "seedValue"
  );
  let textSize = getConfigValueIfUndefined(config, configFromState, "textSize");
  let bgColor = getConfigValueIfUndefined(config, configFromState, "bgColor");
  let offsetMultiplier = getConfigValueIfUndefined(
    config,
    configFromState,
    "offsetMultiplier"
  );
  let offsetMultiplierX = getConfigValueIfUndefined(
    config,
    configFromState,
    "offsetMultiplierX"
  );
  let offsetMultiplierY = getConfigValueIfUndefined(
    config,
    configFromState,
    "offsetMultiplierY"
  );
  let screenSizeX = getValueIfUndefined(state, "screenSizeX", 1024);
  let screenSizeY = getValueIfUndefined(state, "screenSizeY", 1024);
  let pixelDensity = getValueIfUndefined(state, "pixelDensity", 0.5);

  p.myCustomRedrawAccordingToNewPropsHandler = (props) => {
    if (exists(props.personality)) {
      personality = props.personality;
    }

    if (exists(props.bgColor)) {
      bgColor = props.bgColor;
    }

    if (exists(props.overrideText)) {
      overrideText = props.overrideText;
    }

    if (exists(props.seedValue)) {
      seedValue = parseInt(props.seedValue, 10);
    }

    if (exists(props.textSize)) {
      textSize = parseFloat(props.textSize);
    }

    if (exists(props.offsetMultiplier)) {
      offsetMultiplier = parseFloat(props.offsetMultiplier);
    }

    if (exists(props.offsetMultiplierX)) {
      offsetMultiplierX = parseFloat(props.offsetMultiplierX);
    }

    if (exists(props.offsetMultiplierY)) {
      offsetMultiplierY = parseFloat(props.offsetMultiplierY);
    }
  };

  let myFont;
  p.preload = () => {
    myFont = p.loadFont(Montserrat);
  };

  p.setup = () => {
    const canvas = p.createCanvas(screenSizeX, screenSizeY);
    canvas.id("mapsofyou-canvas");
    p.pixelDensity(pixelDensity);
    p.rectMode(p.CENTER);
    p.imageMode(p.CENTER);
    p.angleMode(p.DEGREES);
    p.textAlign(p.CENTER, p.CENTER);
  };

  p.draw = () => {
    p.background(bgColor);
    p.blendMode(p.EXCLUSION);

    random.setSeed(seedValue);

    const width = p.width;
    const height = p.height;
    const originX = width / 2;
    const originY = height / 2;

    const fontSize = textSize;
    const fontShiftAmount = fontSize * offsetMultiplier;
    const fontShiftAmountX = fontShiftAmount * offsetMultiplierX;
    const fontShiftAmountY = fontShiftAmount * offsetMultiplierY;

    const fillColors = ["red", "green", "blue"];
    const displayText = overrideText || personality.toUpperCase();

    p.textFont(myFont, fontSize);
    for (let i = 0; i < fillColors.length; i++) {
      const randomShiftX = lerp(
        -fontShiftAmountX,
        fontShiftAmountX,
        random.value()
      );
      const randomShiftY = lerp(
        -fontShiftAmountY,
        fontShiftAmountY,
        random.value()
      );
      p.fill(fillColors[i]);

      let posX = i > 0 ? originX + randomShiftX : originX;
      let posY = i > 0 ? originY + randomShiftY : originY;
      p.text(displayText, posX, posY);
    }

    p.blendMode(p.BLEND);
  };
};

export default sketch;

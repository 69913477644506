import {
  exists,
  getConfigValueIfUndefined,
  getValueIfUndefined,
} from "../../utilities/misc";

import { personalities } from "../../utilities/enneagram";
import { textHeight } from "../../utilities/text";
import Montserrat from "../../assets/fonts/Montserrat-ExtraBoldItalic.ttf";

export const config = [
  {
    name: "category1",
    type: "category",
    label: "Main Controls",
  },
  {
    name: "category2",
    type: "category",
    label: "Spacing Controls",
  },
  {
    name: "category3",
    type: "category",
    label: "Color Controls",
  },
  {
    name: "personalityType",
    type: "select",
    defaultValue: Object.keys(personalities)[2],
    options: Object.keys(personalities),
    label: "Enneagram Type",
    category: "category1",
  },
  {
    name: "enablePersonalityType",
    type: "boolean",
    defaultValue: true,
    label: "Enable Personality Type",
    category: "category1",
  },
  {
    name: "enableArchetype",
    type: "boolean",
    defaultValue: true,
    label: "Enable Archetype",
    category: "category1",
  },
  {
    name: "archetypePadding",
    type: "float",
    defaultValue: 53,
    min: -512,
    max: 800,
    label: "Archetype Padding",
    category: "category2",
  },
  {
    name: "fgColor",
    type: "color",
    defaultValue: "#e2e23e",
    label: "Foreground Color",
    category: "category3",
  },
  {
    name: "bgColor",
    type: "color",
    defaultValue: "#353531",
    label: "Background Color",
    category: "category3",
  },
];

const sketch = (p) => {
  const givenState = document.p5jsState;
  const state = givenState || {};
  const configFromState = state.configuration;
  let bgOverrideColor = givenState.bgColor;

  let personalityType = getConfigValueIfUndefined(
    config,
    configFromState,
    "personalityType"
  );
  personalityType = personalityType.toUpperCase();

  let enablePersonalityType = getConfigValueIfUndefined(
    config,
    configFromState,
    "enablePersonalityType"
  );
  let enableArchetype = getConfigValueIfUndefined(
    config,
    configFromState,
    "enableArchetype"
  );

  let archetypePadding = getConfigValueIfUndefined(
    config,
    configFromState,
    "archetypePadding"
  );

  let fgColor = getConfigValueIfUndefined(config, configFromState, "fgColor");
  let bgColor = getConfigValueIfUndefined(config, configFromState, "bgColor");

  let screenSizeX = getValueIfUndefined(state, "screenSizeX", 1024);
  let screenSizeY = getValueIfUndefined(state, "screenSizeY", 1024);
  let pixelDensity = getValueIfUndefined(state, "pixelDensity", 0.5);

  p.myCustomRedrawAccordingToNewPropsHandler = (props) => {
    if (exists(props.personalityType)) {
      personalityType = props.personalityType.toUpperCase();
    }

    if (exists(props.enablePersonalityType)) {
      enablePersonalityType = props.enablePersonalityType;
    }

    if (exists(props.enableArchetype)) {
      enableArchetype = props.enableArchetype;
    }

    if (exists(props.archetypePadding)) {
      archetypePadding = props.archetypePadding;
    }

    if (exists(props.fgColor)) {
      fgColor = props.fgColor;
    }

    if (exists(props.bgColor)) {
      bgColor = props.bgColor;
    }
  };

  let myFont;

  p.preload = () => {
    myFont = p.loadFont(Montserrat);
  };

  p.setup = () => {
    p.createCanvas(screenSizeX, screenSizeY);
    p.rectMode(p.CENTER);
    p.angleMode(p.DEGREES);
    p.frameRate(6);
    p.pixelDensity(pixelDensity);
    p.imageMode(p.CENTER);
    p.textAlign(p.CENTER, p.TOP);
  };

  p.draw = () => {
    if (bgOverrideColor) {
      p.background(bgOverrideColor);
    } else {
      p.background(bgColor);
    }
    p.textFont(myFont);

    const width = p.width;
    const originX = width / 2;

    const widthWithPadding = width * 0.75;

    p.fill(fgColor);
    p.noStroke();
    const textSize = 120;

    p.push();
    // main state starts
    p.translate(originX, 145);

    // main personalityType text
    p.push();
    p.textSize(textSize);

    const personalityTypeText = personalityType.toUpperCase();
    const personalityTypeTextWidth = p.textWidth(personalityTypeText);
    const widthToTextRatio = widthWithPadding / personalityTypeTextWidth;

    p.textSize(textSize * widthToTextRatio);
    const personalityTypeTextHeight = textHeight(p, personalityTypeText);

    if (enablePersonalityType) {
      p.text(personalityTypeText, 0, 0);
    }

    p.pop();

    // archetype text
    p.push();
    p.textSize(textSize);

    const archetypeText = `${personalities[
      personalityType
    ].archetype.toUpperCase()}`;
    const archetypeTextWidth = p.textWidth(archetypeText);
    const archetypeScaleRatio = widthWithPadding / archetypeTextWidth;

    p.textSize(textSize * archetypeScaleRatio);

    if (enableArchetype) {
      p.text(
        archetypeText,
        0,
        personalityTypeTextHeight * 2 - archetypePadding
      );
    }

    p.pop();

    // main state ends
    p.pop();
  };
};

export default sketch;

import React from "react";

import { Box } from "src/components/Primitives";
import DesignWithController from "src/components/DesignWithController";

import {
  leaseDetector as sketch,
  leaseDetectorConfig as configuration,
} from "src/artwork";

export const Playground = () => {
  return (
    <Box m={40}>
      <DesignWithController
        configuration={configuration}
        sketch={sketch}
        displayState={{
          pixelDensity: 4,
        }}
      ></DesignWithController>
    </Box>
  );
};

import {
  exists,
  getConfigValueIfUndefined,
  getValueIfUndefined,
} from "../../utilities/misc";
import { signs } from "../../utilities/westernAstrology";
import * as zodiacSignSvgs from "../../utilities/westernAstrology/zodiacSignSvgs02";
import * as zodiacOther from "../../utilities/westernAstrology/otherSvgs";
import BalooTamma2 from "../../assets/fonts/BalooTamma2-Regular.ttf";

export const config = [
  {
    name: "category1",
    type: "category",
    label: "Main Controls",
  },
  {
    name: "sign",
    type: "select",
    defaultValue: Object.values(signs)[5].name,
    options: Object.values(signs).map((item) => item.name),
    label: "Signs",
    category: "category1",
  },
  {
    name: "fgColor",
    type: "color",
    defaultValue: "#586ba6",
    label: "Foreground Color",
    category: "category1",
  },
  {
    name: "bgColor",
    type: "color",
    defaultValue: "#f2b897",
    label: "Background Color",
    category: "category1",
  },
];

const sketch = (p) => {
  const givenState = document.p5jsState;
  const state = givenState || {};
  const configFromState = state.configuration;
  let bgOverrideColor = givenState.bgColor;

  let sign = getConfigValueIfUndefined(config, configFromState, "sign");
  sign = sign.toLowerCase();

  let fgColor = getConfigValueIfUndefined(config, configFromState, "fgColor");
  let bgColor = getConfigValueIfUndefined(config, configFromState, "bgColor");

  let screenSizeX = getValueIfUndefined(state, "screenSizeX", 1024);
  let screenSizeY = getValueIfUndefined(state, "screenSizeY", 1024);
  let pixelDensity = getValueIfUndefined(state, "pixelDensity", 2);

  p.myCustomRedrawAccordingToNewPropsHandler = (props) => {
    if (exists(props.sign)) {
      sign = props.sign.toLowerCase();
    }

    if (exists(props.fgColor)) {
      fgColor = props.fgColor;
    }

    if (exists(props.bgColor)) {
      bgColor = props.bgColor;
    }
  };

  let signIcons = {};
  let zodiacSymbols = {};

  let myFont;

  p.preload = () => {
    myFont = p.loadFont(BalooTamma2);
    Object.keys(zodiacSignSvgs).forEach((zodiacSignSvg) => {
      console.log(zodiacSignSvgs[zodiacSignSvg]);
      signIcons[zodiacSignSvg] = p.loadImage(zodiacSignSvgs[zodiacSignSvg]);
    });

    zodiacSymbols.circle1 = p.loadImage(zodiacOther.zodiacCircle01);
    zodiacSymbols.circle2 = p.loadImage(zodiacOther.zodiacCircle02);
  };

  p.setup = () => {
    p.createCanvas(screenSizeX, screenSizeY);
    p.pixelDensity(pixelDensity);
    p.rectMode(p.CENTER);
    p.imageMode(p.CENTER);
    p.angleMode(p.DEGREES);
    p.textAlign(p.CENTER, p.CENTER);
  };

  p.draw = () => {
    if (bgOverrideColor) {
      p.background(bgOverrideColor);
    } else {
      p.background(bgColor);
    }

    const width = p.width;
    const height = p.height;
    const originX = width / 2;
    const originY = height / 2;

    const imageSize = 250;
    const circleSize = imageSize * 1.7;
    const zodiacCircleSize = circleSize * 1.37;
    const imageYPos = originY * 0.8;
    const textYPos = originY * 1.5;

    p.tint(fgColor);

    p.image(
      zodiacSymbols.circle2,
      originX,
      imageYPos,
      zodiacCircleSize,
      zodiacCircleSize
    );

    // sign with circle
    p.strokeWeight(5);
    p.noFill();
    p.stroke(fgColor);
    p.ellipse(originX, imageYPos, circleSize, circleSize);
    p.image(signIcons[sign], originX, imageYPos, imageSize, imageSize);

    // text
    p.fill(fgColor);
    p.textFont(myFont);
    p.textSize(120);
    p.text(signs[sign].name.toUpperCase(), originX, textYPos);
  };
};

export default sketch;

import React from "react";
import { useMediaQuery } from "react-responsive";

import { getBreakPoint } from "src/styles/theme";

import { Button } from "src/components/Button";
import { BodyText, Box, Flex, SectionTitle } from "src/components/Primitives";

import { imagePaths } from "src/images";

export const ProductSection = ({ onClick }) => {
  const mobileBreakpoint = getBreakPoint("mobile");
  const tabletBreakpoint = getBreakPoint("tablet");

  const isLargerThanMobile = useMediaQuery({
    query: `(min-width: ${mobileBreakpoint}px)`,
  });
  const isLargerThanTablet = useMediaQuery({
    query: `(min-width: ${tabletBreakpoint}px)`,
  });

  const designDisplayMarkup = (
    <img
      src={imagePaths.landing1}
      alt={
        "Woman with T-Shirt that has sun sign Gemini, rising sign Scorpio, moon sign Taurus"
      }
    />
  );

  return (
    <Flex justifyContent={"center"}>
      {isLargerThanTablet && (
        <Box width={[0.5, 0.4]} mr={10}>
          {designDisplayMarkup}
        </Box>
      )}

      <Box width={[1, 0.8, 0.5]}>
        <Box pb={[5]}>
          <SectionTitle>Tell the story of your personality</SectionTitle>
        </Box>
        <BodyText mb={5} fontSize={[6, 5]} lineHeight={1.5}>
          Think of the things that are about your personality. Like your
          Enneagram type or your zodiac sign.
        </BodyText>
        <BodyText mb={5} fontSize={[6, 5]} lineHeight={1.5}>
          Our customizable designs will help you tell the story of your own
          personality, on your favorite products. Shop for clothing and
          accessories based on astrology or personality types. Use the provided
          parameters to personalize the design.
        </BodyText>
        {!isLargerThanTablet && (
          <Box width={1} mb={5}>
            {designDisplayMarkup}
          </Box>
        )}
        <Flex>
          <Button
            type={"pink"}
            onClick={onClick}
            width={[1, "initial"]}
            fullWidth={!isLargerThanMobile}
          >
            Get Started
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export const signs = {
  capricorn: { name: "Capricorn", minDate: "22/12", maxDate: "20/01" },
  aquarius: { name: "Aquarius", minDate: "20/01", maxDate: "19/02" },
  pisces: { name: "Pisces", minDate: "19/02", maxDate: "21/03" },
  aries: { name: "Aries", minDate: "21/03", maxDate: "20/04" },
  taurus: { name: "Taurus", minDate: "20/04", maxDate: "21/05" },
  gemini: { name: "Gemini", minDate: "21/05", maxDate: "21/06" },
  cancer: { name: "Cancer", minDate: "21/06", maxDate: "23/07" },
  leo: { name: "Leo", minDate: "23/07", maxDate: "23/08" },
  virgo: { name: "Virgo", minDate: "23/08", maxDate: "23/09" },
  libra: { name: "Libra", minDate: "23/09", maxDate: "23/10" },
  scorpio: { name: "Scorpio", minDate: "23/10", maxDate: "22/11" },
  sagittarius: { name: "Sagittarius", minDate: "22/11", maxDate: "22/12" },
};

const getMonthString = (monthNum) => {
  switch (monthNum) {
    case "01":
      return "January";
    case "02":
      return "February";
    case "03":
      return "March";
    case "04":
      return "April";
    case "05":
      return "May";
    case "06":
      return "June";
    case "07":
      return "July";
    case "08":
      return "August";
    case "09":
      return "September";
    case "10":
      return "October";
    case "11":
      return "November";
    case "12":
      return "December";
    default:
      throw new Error("Month needs to be from 01 to 12");
  }
};

export const dateConverter = (dateString) => {
  const [day, month] = dateString.split("/");

  return `${day} ${getMonthString(month)}`;
};

import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import LoadingIcon from "src/components/LoadingIcon";
import { Gallery } from "./Gallery";

export const GalleryProvider = (props) => {
  const { loading, error, data } = useQuery(query);

  if (loading) {
    return <LoadingIcon />;
  }

  if (error) {
    // @TODO Add a something went wrong message here
    return <p>{error.message}</p>;
  }

  const collectionsData = data.shop.collections;

  return <Gallery collections={collectionsData} {...props} />;
};

const query = gql`
  query query {
    shop {
      collections(first: 50, sortKey: UPDATED_AT) {
        edges {
          node {
            id
            title
            description
            image {
              id
              originalSrc
              altText
            }
            products(first: 5) {
              edges {
                node {
                  id
                  description
                  productType
                  images(first: 1) {
                    edges {
                      node {
                        id
                        originalSrc
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";

import { Box, Flex, Text } from "src/components/Primitives";
import { border } from "src/styles/mixins";

const CartPriceItem = ({ label, value }) => {
  return (
    <Box pb={2}>
      <Flex justifyContent="space-between">
        <Text fontSize={8} textTransform="uppercase">
          {label}
        </Text>
        <Text fontSize={8} textTransform="uppercase" fontWeight={"600"}>
          $ {value}
        </Text>
      </Flex>
    </Box>
  );
};

const CartFooterContainer = styled.footer`
  border-top: ${border()};
`;

export const CartFooter = ({ children, subtotal, tax, total }) => {
  return (
    <CartFooterContainer>
      <Box p={5}>
        <Box pb={2}>
          <CartPriceItem label="Subtotal" value={subtotal} />
          <CartPriceItem label="Taxes" value={tax} />
          <CartPriceItem label="Total" value={total} />
        </Box>
        <Box>{children}</Box>
      </Box>
    </CartFooterContainer>
  );
};

export const CartLineItems = styled.ul`
  ${(props) => {
    return `
        padding: ${themeGet("space.5")(props)}px;
    `;
  }}
`;

export const EmptyCartMessage = (
  <Box p={5}>
    <Text fontSize={7}>Your cart is currently empty.</Text>
  </Box>
);

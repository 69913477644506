import React from "react";
import styled from "styled-components";

import { useMediaQuery } from "react-responsive";
import { getBreakPoint } from "src/styles/theme";

import { Box } from "src/components/Primitives";
import { Product } from "../Product";

const SelectProductContainer = styled(Box)`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(3, 1fr);

  ${({ isTablet }) => {
    if (isTablet) {
      return `display: flex; flex-direction: column;`;
    }
  }}
`;

export const SelectProduct = ({
  children,
  displayChildren,
  onClick,
  products,
  selectedProductId,
  collectionDescription,
}) => {
  const tabletBreakpoint = getBreakPoint("tablet");
  const isLargerThanTablet = useMediaQuery({
    query: `(min-width: ${tabletBreakpoint}px)`,
  });
  const isTablet = !isLargerThanTablet;

  const getProductItems = () =>
    products.map((productItem) => {
      const { id, image, images, options, priceRange, title } = productItem;
      const selected = selectedProductId === id;

      return (
        <Product
          imagePath={image}
          onClick={() => {
            onClick(id);
          }}
          selected={selected}
          key={id}
          title={title}
          options={options}
          priceRange={priceRange}
          images={images}
          collectionDescription={collectionDescription}
        />
      );
    });

  return (
    <SelectProductContainer isTablet={isTablet}>
      {getProductItems()}
      {displayChildren && <Box>{children}</Box>}
    </SelectProductContainer>
  );
};

import { random } from "canvas-sketch-util";
import { lerp } from "canvas-sketch-util/math";
import {
  exists,
  getConfigValueIfUndefined,
  getValueIfUndefined
} from "../../utilities/misc";
import * as zodiacImages from "../../utilities/westernAstrology/zodiacHeveliusImages";
import Montserrat from "../../assets/fonts/Montserrat-BoldItalic.ttf";

export const config = [
  {
    name: "category1",
    type: "category",
    label: "Main Controls"
  },
  {
    name: "seedValue",
    type: "scalar",
    min: 0,
    max: 9999,
    defaultValue: 2173,
    label: "Seed Value",
    category: "category1"
  },
  {
    name: "textSize",
    type: "float",
    min: 50,
    max: 400,
    defaultValue: 235,
    label: "Text Size",
    category: "category1"
  },
  {
    name: "sign",
    type: "select",
    defaultValue: Object.keys(zodiacImages)[0],
    options: Object.keys(zodiacImages),
    label: "Signs",
    category: "category1"
  }
];

const sketch = p => {
  const givenState = document.p5jsState;
  const state = givenState || {};
  const configFromState = state.configuration;

  let seedValue = getConfigValueIfUndefined(
    config,
    configFromState,
    "seedValue"
  );
  let textSize = getConfigValueIfUndefined(config, configFromState, "textSize");

  let sign = getConfigValueIfUndefined(config, configFromState, "sign");

  let screenSizeX = getValueIfUndefined(state, "screenSizeX", 1024);
  let screenSizeY = getValueIfUndefined(state, "screenSizeY", 1024);
  let pixelDensity = getValueIfUndefined(state, "pixelDensity", 2);

  p.myCustomRedrawAccordingToNewPropsHandler = props => {
    if (exists(props.seedValue)) {
      seedValue = parseInt(props.seedValue, 10);
    }

    if (exists(props.textSize)) {
      textSize = parseFloat(props.textSize);
    }

    if (exists(props.sign)) {
      sign = props.sign;
    }
  };

  let image;
  let myFont;

  p.preload = () => {
    myFont = p.loadFont(Montserrat);
  };

  p.setup = () => {
    p.createCanvas(screenSizeX, screenSizeY);
    p.pixelDensity(pixelDensity);
    p.rectMode(p.CENTER);
    p.imageMode(p.CENTER);
    p.angleMode(p.DEGREES);
    p.textAlign(p.CENTER, p.CENTER);

    image = p.loadImage(zodiacImages["celestial"]);
  };

  p.draw = () => {
    p.background("blue");
    //     ADD - sum of A and B
    // DARKEST - only the darkest colour succeeds: C = min(A\*factor, B).
    // LIGHTEST - only the lightest colour succeeds: C = max(A\*factor, B).
    // DIFFERENCE - subtract colors from underlying image.
    // EXCLUSION - similar to DIFFERENCE, but less extreme.
    // MULTIPLY - multiply the colors, result will always be darker.
    // SCREEN - opposite multiply, uses inverse values of the colors.
    // REPLACE - the pixels entirely replace the others and don't utilize alpha (transparency) values.
    // REMOVE - removes pixels from B with the alpha strength of A.
    // OVERLAY - mix of MULTIPLY and SCREEN . Multiplies dark values, and screens light values. (2D)
    // !! HARD_LIGHT - SCREEN when greater than 50% gray, MULTIPLY when lower. (2D)
    // SOFT_LIGHT - mix of DARKEST and LIGHTEST. Works like OVERLAY, but not as harsh. (2D)
    // DODGE - lightens light tones and increases contrast, ignores darks. (2D)
    // BURN - darker areas are applied, increasing contrast, ignores lights. (2D)
    // SUBTRACT - remainder of A and B (3D)
    p.blendMode(p.SUBTRACT);

    random.setSeed(seedValue);

    const width = p.width;
    const height = p.height;
    const originX = width / 2;
    const originY = height / 2;

    const imageWidth = image.width;
    const imageHeight = image.height;
    const scaleAmount = 0.5;

    p.image(
      image,
      originX,
      originY,
      imageWidth * scaleAmount,
      imageHeight * scaleAmount
    );

    // p.push();
    // p.blendMode(p.BLEND);
    // const fontSize = textSize;
    // const text = "GEMINI";

    // p.textFont(myFont, fontSize);

    // let posX = originX;
    // let posY = originY;
    // // p.fill("black");
    // // p.rect(posX, posY, p.textWidth(text), 200);
    // // p.fill("#FF00FF");
    // // p.noFill();
    // p.stroke("black");
    // // p.strokeWeight(25);
    // p.text(text, posX, posY);
    // p.blendMode(p.BLEND);
    // p.pop();

    p.blendMode(p.BLEND);
  };
};

export default sketch;

import React from "react";
import styled from "styled-components";
import MaterialButton from "@material-ui/core/Button";
import TextFieldMaterial from "@material-ui/core/TextField";
import theme from "src/styles/theme";

import { Box, Flex, Text, BodyTitle } from "src/components/Primitives";

const Form = styled.form`
  position: relative;
  overflow-x: hidden;
`;

const Container = styled(Box)`
  background-color: ${theme.colors.lightGray};
`;

const Button = styled(MaterialButton)`
  & div {
    margin-bottom: 0;
  }
`;

const TextField = styled(TextFieldMaterial)`
  margin-bottom: 0;
  margin-top: 0;
`;

const NewsLetter = () => {
  const inputForBots = (
    <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
      <input
        type="text"
        name="b_7b56bb30c9e5361066ceecf51_d8215cc113"
        tabIndex="-1"
        value=""
      />
    </div>
  );

  // return null;

  return (
    <Container pt={10} pb={15} px={[10, 10, 10, 0]}>
      <Form
        action="https://karaktera.us8.list-manage.com/subscribe/post?u=7b56bb30c9e5361066ceecf51&amp;id=d8215cc113"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        target="_blank"
        noValidate
      >
        {inputForBots}
        <Flex alignItems={"center"} flexDirection={"column"}>
          <BodyTitle>Subscribe Now</BodyTitle>
          <Text fontSize={6} pb={4}>
            Don't miss out on our special offers and new designs!
          </Text>
          <Flex>
            <TextField
              id="standard-name"
              margin="normal"
              type="email"
              required
            />
            <Button color="secondary" type="submit">
              <BodyTitle fontSize={7}>Submit</BodyTitle>
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Container>
  );
};

export default NewsLetter;

import React, { useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Fade } from "src/components/Fade";

import { getBreakPoint } from "src/styles/theme";
import { Box } from "src/components/Primitives";
import { MaxWidthContainer } from "src/components/MaxWidthContainer";
import { TabsSection } from "src/components/TabsSection";
import theme from "src/styles/theme";

import { ImageCard } from "./components";

const GalleryContainer = styled(Box)`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;

  @media screen and (min-width: ${theme.breakpoints[0]}) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: ${theme.breakpoints[1]}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const getCollectionsData = (collectionsData) => {
  const collections = collectionsData.edges;

  return collections.map((collection) => {
    const collectionData = collection.node;
    const { id, description, image, title } = collectionData;

    const imageSrc = image.originalSrc;
    const altText = image.altText;

    const products = collectionData.products.edges.map((productData) => {
      return productData.node;
    });

    const productImages = [];
    const productTypes = [];

    products.forEach((product) => {
      productImages.push(product.images.edges[0].node.originalSrc);
      productTypes.push(product.productType);
    });

    productTypes.sort();

    return {
      id,
      description,
      image: imageSrc,
      altText,
      products,
      title,
      productImages,
      productTypes,
    };
  });
};

const GalleryItem = styled(Box)`
  height: 100%;
`;

const TAB_CATEGORY_MAPPINGS = {
  0: "All",
  1: "Astrology",
  2: "Enneagram",
};

const CATEGORIES = {
  [TAB_CATEGORY_MAPPINGS[1]]: [
    "Bus Reference",
    "Lease Detector",
    "Miracle Climate",
    "Particular Fever",
    "Production Nap Zodiac",
  ],
  [TAB_CATEGORY_MAPPINGS[2]]: [
    "Fabricate Module",
    "Particular Fever Enneagram",
    "Production Nap",
    "Volume Patent",
  ],
};

const getCategoryOfDesign = (designName) => {
  let categoryName = "";
  // eslint-disable-next-line array-callback-return
  Object.entries(CATEGORIES).some(([key, value]) => {
    if (value.includes(designName)) {
      categoryName = key;
      return true;
    }
  });

  return categoryName.toLowerCase();
};

const getCollectionsByTabCategory = (tabCategoryValue) => {
  const allCollectionNames = Object.values(CATEGORIES).reduce((acc, curr) => {
    acc.push(...curr);
    return acc;
  }, []);

  const tabCategoryKey = TAB_CATEGORY_MAPPINGS[tabCategoryValue];

  if (tabCategoryKey === "All") {
    return [...allCollectionNames];
  } else {
    return [...CATEGORIES[tabCategoryKey]];
  }
};

export const Gallery = ({ collections }) => {
  const [categorySelection, setCategorySelection] = useState(0);
  const tabletBreakpoint = getBreakPoint("tablet");

  const isLargerThanTablet = useMediaQuery({
    query: `(min-width: ${tabletBreakpoint}px)`,
  });

  const getDesignThumbnails = (data, nameWhiteList) => {
    let collectionsData = getCollectionsData(data);

    return collectionsData.map((data) => {
      const {
        altText,
        description,
        id,
        image,
        title,
        productImages,
        productTypes,
      } = data;
      console.log(id, title);
      const paths = [image, ...productImages];

      if (!nameWhiteList.includes(title)) {
        return null;
      }

      let categoryName = getCategoryOfDesign(title);

      return (
        <Fade bottom distance={"25%"} key={id}>
          <GalleryItem>
            <ImageCard
              imagePaths={paths}
              title={title}
              linkPath={`design/${categoryName}/${id}`}
              isMobile={!isLargerThanTablet}
              altText={altText}
              description={description}
              productTypes={productTypes}
            />
          </GalleryItem>
        </Fade>
      );
    });
  };

  const nameWhiteList = React.useMemo(() => {
    return getCollectionsByTabCategory(categorySelection);
  }, [categorySelection]);

  return (
    <MaxWidthContainer>
      <Box pb={5}>
        <MaxWidthContainer>
          <TabsSection
            categories={[
              {
                name: "category0",
                label: TAB_CATEGORY_MAPPINGS["0"],
              },
              {
                name: "category1",
                label: TAB_CATEGORY_MAPPINGS["1"],
              },
              {
                name: "category2",
                label: TAB_CATEGORY_MAPPINGS["2"],
              },
            ]}
            onChange={(value) => {
              setCategorySelection(value);
            }}
          />
        </MaxWidthContainer>
      </Box>
      <Box mb={5}>
        <GalleryContainer>
          {getDesignThumbnails(collections, nameWhiteList)}
        </GalleryContainer>
      </Box>
    </MaxWidthContainer>
  );
};

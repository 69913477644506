import localForage from "localforage";

const CHECKOUT_KEY = "checkout";

export async function getStoredData(key) {
  let data;

  try {
    data = await localForage.getItem(key);
  } catch (err) {
    console.log(err);
  }

  return data;
}

export async function setStoredData(key, data) {
  try {
    await localForage.setItem(key, data);
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export async function deleteStoredData(key) {
  try {
    await localForage.removeItem(key);
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export async function getCheckoutData() {
  return getStoredData(CHECKOUT_KEY);
}

export async function setCheckoutData(data) {
  setStoredData(CHECKOUT_KEY, data);
}

export async function deleteCheckoutData() {
  deleteStoredData(CHECKOUT_KEY);
}

/* eslint-disable */
// https://gist.github.com/studioijeoma/942ced6a9c24a4739199
export function textHeight(p, text, textsize, abovesize, belowsize, maxheight) {
  var above;
  var below;
  p.push();
  if (textsize != undefined) {
    p.textSize(textsize);
  }
  if (maxheight != undefined) {
    text = "Ç";
  }
  if (text.includes("Å")) {
    above = (p.textWidth(11) * 91.75) / 100;
  } else if (text.includes("Ô")) {
    above = (p.textWidth(11) * 90.25) / 100;
  } else if (text.includes("Ó")) {
    above = (p.textWidth(11) * 90.15) / 100;
  } else if (text.includes("Ò")) {
    above = (p.textWidth(11) * 90.1) / 100;
  } else if (text.includes("√")) {
    above = (p.textWidth(11) * 89.75) / 100;
  } else if (text.includes("È") || text.includes("Í") || text.includes("Î")) {
    above = (p.textWidth(11) * 88.5) / 100;
  } else if (
    text.includes("Ì") ||
    text.includes("Û") ||
    text.includes("Ç") ||
    text.includes("Á") ||
    text.includes("Â") ||
    text.includes("Ê") ||
    text.includes("Ú") ||
    text.includes("Ù")
  ) {
    above = (p.textWidth(11) * 88.25) / 100;
  } else if (text.includes("Ÿ") || text.includes("Ë") || text.includes("Ï")) {
    above = (p.textWidth(11) * 85.5) / 100;
  } else if (text.includes("")) {
    above = (p.textWidth(11) * 78.25) / 100;
  } else if (text.includes("å")) {
    above = (p.textWidth(11) * 76.65) / 100;
  } else if (text.includes("$")) {
    above = (p.textWidth(11) * 74.75) / 100;
  } else if (text.includes("∫")) {
    above = (p.textWidth(11) * 73) / 100;
  } else if (text.includes("∂") || text.includes("O")) {
    above = (p.textWidth(11) * 71.25) / 100;
  } else if (
    text.includes("@") ||
    text.includes("C") ||
    text.includes("Ø") ||
    text.includes("º") ||
    text.includes("ª") ||
    text.includes("€") ||
    text.includes("◊")
  ) {
    above = (p.textWidth(11) * 71.1) / 100;
  } else if (
    text.includes("Q") ||
    text.includes("S") ||
    text.includes("G") ||
    text.includes("ƒ")
  ) {
    above = (p.textWidth(11) * 71) / 100;
  } else if (
    text.includes("é") ||
    text.includes("è") ||
    text.includes("à") ||
    text.includes("ù") ||
    text.includes("`") ||
    text.includes("´") ||
    text.includes("ê") ||
    text.includes("î") ||
    text.includes("ô") ||
    text.includes("Œ")
  ) {
    above = (p.textWidth(11) * 70.75) / 100;
  } else if (text.includes("{") || text.includes("}")) {
    above = (p.textWidth(11) * 70.5) / 100;
  } else if (
    text.includes("(") ||
    text.includes(")") ||
    text.includes("f") ||
    text.includes("ﬁ") ||
    text.includes("ﬂ") ||
    text.includes("|") ||
    text.includes("ß")
  ) {
    above = (p.textWidth(11) * 70.25) / 100;
  } else if (text.includes("?")) {
    above = (p.textWidth(11) * 70.1) / 100;
  } else if (text.includes("Ω")) {
    above = (p.textWidth(11) * 69.9) / 100;
  } else if (text.includes("“") || text.includes("‘") || text.includes("‰")) {
    above = (p.textWidth(11) * 69.85) / 100;
  } else if (text.includes("[") || text.includes("]")) {
    above = (p.textWidth(11) * 69.75) / 100;
  } else if (text.includes("d") || text.includes("h")) {
    above = (p.textWidth(11) * 69.5) / 100;
  } else if (
    text.includes("#") ||
    text.includes('"') ||
    text.includes("'") ||
    text.includes("§") ||
    text.includes("!") ||
    text.includes("A") ||
    text.includes("Z") ||
    text.includes("E") ||
    text.includes("R") ||
    text.includes("T") ||
    text.includes("Y") ||
    text.includes("U") ||
    text.includes("i") ||
    text.includes("I") ||
    text.includes("P") ||
    text.includes("^") ||
    text.includes("*") ||
    text.includes("D") ||
    text.includes("F") ||
    text.includes("H") ||
    text.includes("j") ||
    text.includes("J") ||
    text.includes("k") ||
    text.includes("K") ||
    text.includes("l") ||
    text.includes("L") ||
    text.includes("M") ||
    text.includes("W") ||
    text.includes("X") ||
    text.includes("V") ||
    text.includes("b") ||
    text.includes("B") ||
    text.includes("N") ||
    text.includes("/") ||
    text.includes("”") ||
    text.includes("’") ||
    text.includes("¶") ||
    text.includes("Æ") ||
    text.includes("®") ||
    text.includes("†") ||
    text.includes("™") ||
    text.includes("‡") ||
    text.includes("∏") ||
    text.includes("∑") ||
    text.includes("∆") ||
    text.includes("©")
  ) {
    above = (p.textWidth(11) * 69.25) / 100;
  } else if (text.includes("£")) {
    above = (p.textWidth(11) * 69.1) / 100;
  } else if (text.includes("&")) {
    above = (p.textWidth(11) * 69) / 100;
  } else if (text.includes("¨") || text.includes("ë") || text.includes("ï")) {
    above = (p.textWidth(11) * 68) / 100;
  } else if (text.includes("2") || text.includes("6") || text.includes("8")) {
    above = (p.textWidth(11) * 67.75) / 100;
  } else if (
    text.includes("3") ||
    text.includes("4") ||
    text.includes("9") ||
    text.includes("0") ||
    text.includes("°") ||
    text.includes("⁄")
  ) {
    above = (p.textWidth(11) * 67.5) / 100;
  } else if (text.includes("%")) {
    above = (p.textWidth(11) * 67.25) / 100;
  } else if (text.includes("1")) {
    above = (p.textWidth(11) * 67) / 100;
  } else if (text.includes("5")) {
    above = (p.textWidth(11) * 66.5) / 100;
  } else if (text.includes("7") || text.includes("¥")) {
    above = (p.textWidth(11) * 66.25) / 100;
  } else if (text.includes("t")) {
    above = (p.textWidth(11) * 64.5) / 100;
  } else if (text.includes("¢")) {
    above = (p.textWidth(11) * 60) / 100;
  } else if (text.includes("∞")) {
    above = (p.textWidth(11) * 53.1) / 100;
  } else if (text.includes("ç") || text.includes("o") || text.includes("ø")) {
    above = (p.textWidth(11) * 52) / 100;
  } else if (text.includes("c")) {
    above = (p.textWidth(11) * 51.9) / 100;
  } else if (text.includes("s") || text.includes("æ") || text.includes("œ")) {
    above = (p.textWidth(11) * 51.75) / 100;
  } else if (
    text.includes("a") ||
    text.includes("e") ||
    text.includes("r") ||
    text.includes("p")
  ) {
    above = (p.textWidth(11) * 51.65) / 100;
  } else if (text.includes("m") || text.includes("n")) {
    above = (p.textWidth(11) * 51.6) / 100;
  } else if (text.includes("q")) {
    above = (p.textWidth(11) * 51.5) / 100;
  } else if (text.includes("g")) {
    above = (p.textWidth(11) * 51.25) / 100;
  } else if (text.includes("¿")) {
    above = (p.textWidth(11) * 50.75) / 100;
  } else if (
    text.includes("z") ||
    text.includes("y") ||
    text.includes("u") ||
    text.includes("w") ||
    text.includes("x") ||
    text.includes("v") ||
    text.includes("¡")
  ) {
    above = (p.textWidth(11) * 50.5) / 100;
  } else if (text.includes("µ")) {
    above = (p.textWidth(11) * 50.35) / 100;
  } else if (text.includes("ı")) {
    above = (p.textWidth(11) * 50.25) / 100;
  } else if (
    text.includes("<") ||
    text.includes(">") ||
    text.includes("π") ||
    text.includes("≤") ||
    text.includes("≥")
  ) {
    above = (p.textWidth(11) * 50.1) / 100;
  } else if (text.includes(";") || text.includes(":")) {
    above = (p.textWidth(11) * 49.8) / 100;
  } else if (text.includes("+") || text.includes("±")) {
    above = (p.textWidth(11) * 49.25) / 100;
  } else if (text.includes("÷")) {
    above = (p.textWidth(11) * 48.25) / 100;
  } else if (text.includes("•")) {
    above = (p.textWidth(11) * 45) / 100;
  } else if (
    text.includes("«") ||
    text.includes("»") ||
    text.includes("‹") ||
    text.includes("›")
  ) {
    above = (p.textWidth(11) * 42.6) / 100;
  } else if (text.includes("≈")) {
    above = (p.textWidth(11) * 41.75) / 100;
  } else if (text.includes("·")) {
    above = (p.textWidth(11) * 39.1) / 100;
  } else if (text.includes("=") || text.includes("¬")) {
    above = (p.textWidth(11) * 38.75) / 100;
  } else if (text.includes("~")) {
    above = (p.textWidth(11) * 34.2) / 100;
  } else if (text.includes("-")) {
    above = (p.textWidth(11) * 31.25) / 100;
  } else if (text.includes("—") || text.includes("–")) {
    above = (p.textWidth(11) * 30.15) / 100;
  } else if (text.includes("…")) {
    above = (p.textWidth(11) * 10.4) / 100;
  } else if (
    text.includes(",") ||
    text.includes(".") ||
    text.includes("„") ||
    text.includes("‚")
  ) {
    above = (p.textWidth(11) * 10.25) / 100;
  } else if (text.includes("_")) {
    above = (p.textWidth(11) * -7.25) / 100;
  }
  if (text.includes("Ç") || text.includes("ç")) {
    below = (p.textWidth(11) * 21.65) / 100;
  } else if (text.includes("g")) {
    below = (p.textWidth(11) * 21.25) / 100;
  } else if (text.includes("y")) {
    below = (p.textWidth(11) * 20.5) / 100;
  } else if (text.includes("§") || text.includes("j")) {
    below = (p.textWidth(11) * 20.25) / 100;
  } else if (text.includes("p") || text.includes("q")) {
    below = (p.textWidth(11) * 20) / 100;
  } else if (text.includes("µ")) {
    below = (p.textWidth(11) * 19.8) / 100;
  } else if (text.includes("ƒ")) {
    below = (p.textWidth(11) * 19.7) / 100;
  } else if (text.includes("{") || text.includes("}")) {
    below = (p.textWidth(11) * 19.65) / 100;
  } else if (text.includes("(") || text.includes(")") || text.includes("∫")) {
    below = (p.textWidth(11) * 19.5) / 100;
  } else if (text.includes("¿")) {
    below = (p.textWidth(11) * 19.4) / 100;
  } else if (text.includes("[") || text.includes("]")) {
    below = (p.textWidth(11) * 18.9) / 100;
  } else if (text.includes("¡")) {
    below = (p.textWidth(11) * 18.75) / 100;
  } else if (text.includes("¶")) {
    below = (p.textWidth(11) * 17) / 100;
  } else if (text.includes("‡")) {
    below = (p.textWidth(11) * 15.4) / 100;
  } else if (text.includes("†")) {
    below = (p.textWidth(11) * 15.35) / 100;
  } else if (
    text.includes(",") ||
    text.includes(";") ||
    text.includes("„") ||
    text.includes("‚")
  ) {
    below = (p.textWidth(11) * 14.25) / 100;
  } else if (text.includes("∏") || text.includes("∑")) {
    below = (p.textWidth(11) * 14.1) / 100;
  } else if (text.includes("_")) {
    below = (p.textWidth(11) * 12) / 100;
  } else if (text.includes("$") || text.includes("¢")) {
    below = (p.textWidth(11) * 11.1) / 100;
  } else if (text.includes("Q")) {
    below = (p.textWidth(11) * 5.5) / 100;
  } else if (text.includes("‰")) {
    below = (p.textWidth(11) * 2.5) / 100;
  } else if (text.includes("") || text.includes("ø")) {
    below = (p.textWidth(11) * 2.2) / 100;
  } else if (
    text.includes("@") ||
    text.includes("O") ||
    text.includes("S") ||
    text.includes("Ø") ||
    text.includes("Ô") ||
    text.includes("Ò") ||
    text.includes("◊") ||
    text.includes("Ó")
  ) {
    below = (p.textWidth(11) * 2) / 100;
  } else if (text.includes("8") || text.includes("s") || text.includes("œ")) {
    below = (p.textWidth(11) * 1.9) / 100;
  } else if (
    text.includes("9") ||
    text.includes("0") ||
    text.includes("√") ||
    text.includes("Ù") ||
    text.includes("ô") ||
    text.includes("Ú") ||
    text.includes("Û") ||
    text.includes("J") ||
    text.includes("G") ||
    text.includes("o")
  ) {
    below = (p.textWidth(11) * 1.8) / 100;
  } else if (
    text.includes("é") ||
    text.includes("⁄") ||
    text.includes("∂") ||
    text.includes("€") ||
    text.includes("ê") ||
    text.includes("ë") ||
    text.includes("C") ||
    text.includes("%") ||
    text.includes("d") ||
    text.includes("U") ||
    text.includes("e") ||
    text.includes("3") ||
    text.includes("6") ||
    text.includes("è")
  ) {
    below = (p.textWidth(11) * 1.75) / 100;
  } else if (
    text.includes("5") ||
    text.includes("æ") ||
    text.includes("å") ||
    text.includes("£") ||
    text.includes("a") ||
    text.includes("à")
  ) {
    below = (p.textWidth(11) * 1.65) / 100;
  } else if (text.includes("&") || text.includes("Œ") || text.includes("b")) {
    below = (p.textWidth(11) * 1.5) / 100;
  } else if (text.includes("c")) {
    below = (p.textWidth(11) * 1.45) / 100;
  } else if (text.includes("u") || text.includes("ß") || text.includes("ù")) {
    below = (p.textWidth(11) * 1.35) / 100;
  } else if (text.includes("π")) {
    below = (p.textWidth(11) * 0.85) / 100;
  } else if (text.includes("<") || text.includes(">")) {
    below = (p.textWidth(11) * 0.8) / 100;
  } else if (text.includes("t")) {
    below = (p.textWidth(11) * 0.75) / 100;
  } else if (
    text.includes("#") ||
    text.includes("1") ||
    text.includes("2") ||
    text.includes("w") ||
    text.includes("M") ||
    text.includes("m") ||
    text.includes("L") ||
    text.includes("l") ||
    text.includes("K") ||
    text.includes("k") ||
    text.includes("H") ||
    text.includes("h") ||
    text.includes("F") ||
    text.includes("f") ||
    text.includes("D") ||
    text.includes("P") ||
    text.includes("I") ||
    text.includes("i") ||
    text.includes("Y") ||
    text.includes("T") ||
    text.includes("R") ||
    text.includes("r") ||
    text.includes("E") ||
    text.includes("Z") ||
    text.includes("z") ||
    text.includes("A") ||
    text.includes("!") ||
    text.includes("7") ||
    text.includes("4") ||
    text.includes("Ÿ") ||
    text.includes("+") ||
    text.includes("/") ||
    text.includes(":") ||
    text.includes(".") ||
    text.includes("?") ||
    text.includes("N") ||
    text.includes("n") ||
    text.includes("B") ||
    text.includes("V") ||
    text.includes("v") ||
    text.includes("X") ||
    text.includes("x") ||
    text.includes("W") ||
    text.includes("±") ||
    text.includes("…") ||
    text.includes("ı") ||
    text.includes("©") ||
    text.includes("≥") ||
    text.includes("≤") ||
    text.includes("|") ||
    text.includes("Ë") ||
    text.includes("È") ||
    text.includes("Í") ||
    text.includes("Ï") ||
    text.includes("Î") ||
    text.includes("Ì") ||
    text.includes("ﬂ") ||
    text.includes("ﬁ") ||
    text.includes("∆") ||
    text.includes("Ω") ||
    text.includes("¥") ||
    text.includes("ï") ||
    text.includes("î") ||
    text.includes("®") ||
    text.includes("Ê") ||
    text.includes("Å") ||
    text.includes("Â") ||
    text.includes("Æ") ||
    text.includes("Á")
  ) {
    below = (p.textWidth(11) * 0) / 100;
  } else if (text.includes("÷")) {
    below = (p.textWidth(11) * -1) / 100;
  } else if (text.includes("≈")) {
    below = (p.textWidth(11) * -7.35) / 100;
  } else if (
    text.includes("›") ||
    text.includes("‹") ||
    text.includes("»") ||
    text.includes("«")
  ) {
    below = (p.textWidth(11) * -10) / 100;
  } else if (text.includes("¬") || text.includes("=")) {
    below = (p.textWidth(11) * -10.5) / 100;
  } else if (text.includes("~")) {
    below = (p.textWidth(11) * -15.05) / 100;
  } else if (text.includes("∞")) {
    below = (p.textWidth(11) * -16.1) / 100;
  } else if (text.includes("-")) {
    below = (p.textWidth(11) * -22.5) / 100;
  } else if (text.includes("–") || text.includes("—")) {
    below = (p.textWidth(11) * -23.25) / 100;
  } else if (text.includes("•")) {
    below = (p.textWidth(11) * -24.1) / 100;
  } else if (text.includes("·")) {
    below = (p.textWidth(11) * -27) / 100;
  } else if (text.includes("^")) {
    below = (p.textWidth(11) * -28.5) / 100;
  } else if (text.includes("™")) {
    below = (p.textWidth(11) * -35) / 100;
  } else if (text.includes("º") || text.includes("ª")) {
    below = (p.textWidth(11) * -38.9) / 100;
  } else if (text.includes("°")) {
    below = (p.textWidth(11) * -39) / 100;
  } else if (text.includes('"') || text.includes("'") || text.includes("*")) {
    below = (p.textWidth(11) * -41.5) / 100;
  } else if (text.includes("”") || text.includes("’")) {
    below = (p.textWidth(11) * -44.75) / 100;
  } else if (text.includes("“") || text.includes("‘")) {
    below = (p.textWidth(11) * -45.25) / 100;
  } else if (text.includes("´") || text.includes("`")) {
    below = (p.textWidth(11) * -57.1) / 100;
  } else if (text.includes("¨")) {
    below = (p.textWidth(11) * -58.25) / 100;
  }

  if (text != "") {
    if (abovesize != undefined && belowsize != undefined) {
      return above + below;
    } else if (abovesize != undefined) {
      return above;
    } else if (belowsize != undefined) {
      return below;
    } else {
      return above + below;
    }
  } else {
    return 0;
  }
  p.pop();
}
//END OF THE FUNCTION

import React from "react";
import Select from "@material-ui/core/Select";
import { MenuItem } from "@material-ui/core";

import { Box, Text } from "src/components/Primitives";
import { getVariantTitle } from "src/utilities/product";

export const singleVariantOptionOverride = { old: "Title", new: "Option" };

const getAllOptionsTable = (allOptions) => {
  const allOptionsTable = {};

  if (!allOptions) {
    return allOptionsTable;
  }

  allOptions.forEach((option) => {
    const optionName = option.name;
    allOptionsTable[optionName] = option.values;
  });

  return allOptionsTable;
};

export class SelectedProductParameters extends React.Component {
  render = () => {
    const {
      onChange,
      allOptions,
      selectionState,
      variantOptionsTable,
    } = this.props;

    const allOptionsTable = getAllOptionsTable(allOptions);

    // console.log("allOptions", allOptions);
    // console.log("selectionState", selectionState);
    // console.log("variantOptionsTable", variantOptionsTable);
    // console.log("allOptionsTable", allOptionsTable);

    const singleParameterProduct = allOptions.length === 1;

    // filter the allOptions values based on the parameter selection.
    // like if you select medium, and if certain colors don't exist for it.
    // this would take care of it.
    if (!singleParameterProduct) {
      const emptySelectionState = Object.keys(selectionState).length === 0;

      if (!emptySelectionState) {
        Object.entries(selectionState).forEach(
          ([selectionStateKey, selectionStateValue]) => {
            const correspondingSelectionsObj =
              variantOptionsTable[selectionStateKey][selectionStateValue];

            Object.entries(correspondingSelectionsObj).forEach(
              ([key, value]) => {
                allOptionsTable[key] = value;
              }
            );
          }
        );
      }
    }

    // is there already a single selection?
    const selectionExists = Object.keys(selectionState).length > 0;

    const selectFields =
      allOptionsTable &&
      Object.entries(allOptionsTable)
        .sort()
        .map(([key, value], index, arr) => {
          const isLast = index === arr.length - 1;
          const optionName = key;
          const optionValues = value.map((optionValue) => {
            return {
              value: optionValue,
              label: optionValue,
            };
          });

          // if the user made a selection for one of the dropdowns.
          // populate the rest with default values.
          // so that the variant query can properly go through.
          let selectedValue;
          if (!selectionExists) {
            selectedValue =
              selectionState[optionName] !== undefined
                ? selectionState[optionName]
                : "";
          } else {
            if (selectionState[optionName] !== undefined) {
              selectedValue = selectionState[optionName];
            } else {
              // TODO: This is Hacky!! Your pick here might not exist for other selections.
              selectedValue = allOptionsTable[optionName][1];
              // TODO: This is Hacky too! I don't think you can call onChange here.
              onChange(optionName, selectedValue);
            }
          }

          return (
            <Box mr={isLast ? 0 : 5} key={optionName} width={1} mb={3}>
              <Text mb={2} fontWeight={"700"} fontSize={6}>
                Select{" "}
                {optionName === singleVariantOptionOverride.old
                  ? singleVariantOptionOverride.new
                  : optionName}
              </Text>
              <Box fontFamily={"body"}>
                <Select
                  value={selectedValue}
                  onChange={(event) => {
                    const selectedValue = event.target.value;
                    onChange(optionName, selectedValue);
                  }}
                >
                  {optionValues.map((option) => {
                    const { label, value } = option;

                    return (
                      <MenuItem value={value} key={value}>
                        {getVariantTitle(label)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Box>
          );
        });

    return selectFields || null;
  };
}

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Box, Flex, Text } from "src/components/Primitives";
import { border, borderRadius } from "src/styles/mixins";
import {
  getVariantTitle,
  TEXT_FOR_DEFAULT_OPTION,
} from "src/utilities/product";

export const LineItems = styled.ul`
  display: grid;
  grid-auto-rows: 140px;
  grid-template-columns: 1fr;

  ${({ isMobile }) => {
    if (isMobile) {
      return `
        grid-auto-rows: 270px;
        `;
    }
  }}
`;

const LineItemContainer = styled.li`
  display: grid;
  grid-template-columns: 140px 2fr 2fr 1fr 100px;
  border-bottom: ${border()};
  padding: 12px;
  grid-gap: 12px;

  ${({ isMobile }) => {
    if (isMobile) {
      return `
      grid-column-gap: 4px;
      grid-row-gap: 8px;
      grid-template-columns: 160px 1fr 1.5fr 100px;
      `;
    }
  }}
`;

const LineItemImg = styled.img`
  padding-right: 8px;
`;

const LineItemTitleContainer = styled(Box)`
  height: 100%;

  ${({ isMobile }) => {
    if (isMobile) {
      return `
        grid-column-start: 1;
        grid-column-end: 3;
      `;
    }
  }}
`;

const LineItemPriceContainer = styled(Box)`
  padding: 8px;
  padding-top: 4px;
  text-align: right;
`;

const LineItemQuantityContainer = styled(Box)`
  border: ${border(2)};
  border-radius: ${borderRadius()};
  margin-right: 8px;
  height: 28px;
`;

const LinkText = styled(Text)`
  text-decoration: underline;
`;

const LineItemDesignConfigurationOuterContainer = styled(Box)`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  ${({ isMobile }) => {
    if (isMobile) {
      return `
        padding: 8px;
        padding-top: 0;
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 2;
        grid-column-end: 5;
      `;
    }
  }}
`;

const LineItemDesignConfigurationContainer = styled(Box)`
  height: 100%;
`;

const LineItemQuantityAndPriceContainer = styled(Box)`
  display: flex;
  flex-direction: column-reverse;

  ${({ isMobile }) => {
    if (isMobile) {
      return `
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 3;
        grid-column-end: 5;

        flex-direction: colum-reverse;
        justify-content: center;
        align-items: flex-end;
      `;
    }
  }}
`;

const ExpansionContainer = styled(Flex)`
  flex-grow: 1;
`;

const DesignSettingsItemContainer = styled(Box)`
  border-bottom: ${border()};
`;

const DesignSettingsItem = ({ field, value }) => {
  const fontSize = 8;

  return (
    <DesignSettingsItemContainer>
      <Flex justifyContent={"space-between"} pb={1} pr={3}>
        <Text fontSize={fontSize} fontWeight={"500"} pr={6}>
          {field}:
        </Text>
        <Text fontSize={fontSize}>{String(value)}</Text>
      </Flex>
    </DesignSettingsItemContainer>
  );
};

export const LineItem = ({ lineItem, isMobile, updateLineItemInCart }) => {
  function decrementQuantity(lineItemId) {
    updateLineItemInCart(lineItemId, lineItem.quantity - 1);
  }

  function incrementQuantity(lineItemId) {
    updateLineItemInCart(lineItemId, lineItem.quantity + 1);
  }

  function getDesignSettingsText(designSettings) {
    return Object.entries(designSettings).map(([key, value]) => {
      return <DesignSettingsItem field={key} value={value} key={key} />;
    });
  }

  const lineItemCustomProperties = lineItem.customAttributes[0].value;
  const parsedProperties = JSON.parse(lineItemCustomProperties);
  console.log(parsedProperties);

  const designUrl = parsedProperties.url;
  const designSettings = parsedProperties.designSettings;

  const variantTitle = lineItem.variant.title;
  const hideVariantTitle = variantTitle === TEXT_FOR_DEFAULT_OPTION;

  return (
    <LineItemContainer isMobile={isMobile}>
      {lineItem.variant.image ? (
        <LineItemImg
          src={lineItem.variant.image.src}
          alt={`${lineItem.title} product shot`}
        />
      ) : null}

      <LineItemTitleContainer isMobile={isMobile}>
        <Text fontSize={[6, 5]} pb={1} fontWeight={["500", "400"]}>
          {lineItem.title}
        </Text>
        {!hideVariantTitle && (
          <Flex pb={1}>
            <Text fontSize={[8, 7]}>{getVariantTitle(variantTitle)}</Text>
          </Flex>
        )}
        <Link to={designUrl}>
          <LinkText fontSize={[8, 7]} color={"pink"}>
            Go to Design
          </LinkText>
        </Link>
      </LineItemTitleContainer>

      <LineItemDesignConfigurationOuterContainer isMobile={isMobile}>
        <LineItemDesignConfigurationContainer>
          <Text fontSize={[7, 6]} pb={2} fontWeight={["600"]}>
            Design Customizations
          </Text>
          {getDesignSettingsText(designSettings)}
        </LineItemDesignConfigurationContainer>
      </LineItemDesignConfigurationOuterContainer>

      {!isMobile && <ExpansionContainer />}

      <LineItemQuantityAndPriceContainer isMobile={isMobile}>
        <LineItemQuantityContainer>
          <button
            className="Line-item__quantity-update"
            onClick={() => decrementQuantity(lineItem.id)}
          >
            -
          </button>
          <span className="Line-item__quantity">{lineItem.quantity}</span>
          <button
            className="Line-item__quantity-update"
            onClick={() => incrementQuantity(lineItem.id)}
          >
            +
          </button>
        </LineItemQuantityContainer>

        <LineItemPriceContainer>
          <Text fontWeight={"600"}>
            {`$${(lineItem.quantity * lineItem.variant.price).toFixed(2)}`}
          </Text>
        </LineItemPriceContainer>
      </LineItemQuantityAndPriceContainer>
    </LineItemContainer>
  );
};

import { lerp } from "canvas-sketch-util/math";

import {
  exists,
  getConfigValueIfUndefined,
  getValueIfUndefined,
} from "../../utilities/misc";

import { signs } from "../../utilities/westernAstrology";
import Montserrat from "../../assets/fonts/Montserrat-Black.ttf";

export const config = [
  {
    name: "category1",
    type: "category",
    label: "Text Controls",
  },
  {
    name: "category3",
    type: "category",
    label: "Text Copy Controls",
  },
  {
    name: "category2",
    type: "category",
    label: "Styling Controls",
  },
  {
    name: "sign",
    type: "select",
    defaultValue: Object.values(signs)[7].name,
    options: Object.values(signs).map((item) => item.name),
    label: "Zodiac Sign",
    category: "category1",
  },
  {
    name: "overrideText",
    type: "text",
    defaultValue: "",
    label: "Override Text",
    category: "category1",
  },
  {
    name: "textPositionOffset",
    type: "float",
    min: -400,
    max: 400,
    defaultValue: -200,
    label: "Text Position Offset",
    category: "category1",
  },
  {
    name: "textCopyTiltAmount",
    type: "float",
    min: -0.3,
    max: 0.3,
    defaultValue: -0.16,
    label: "Text Copy Tilt",
    category: "category1",
  },
  {
    name: "strokeWeight",
    type: "float",
    min: 0,
    max: 60,
    defaultValue: 21,
    label: "Stroke Weight",
    category: "category2",
  },
  {
    name: "strokeColor",
    type: "color",
    defaultValue: "#060606",
    label: "Stroke Color",
    category: "category2",
  },
  {
    name: "textColor",
    type: "color",
    defaultValue: "#ad8dcc",
    label: "Text Color",
    category: "category2",
  },
  {
    name: "bgColor",
    type: "color",
    defaultValue: "#b2a7a6",
    label: "Background Color",
    category: "category2",
  },
  {
    name: "spacing",
    type: "scalar",
    min: 0,
    max: 100,
    defaultValue: 67,
    label: "Spacing",
    category: "category3",
  },
  {
    name: "spacingMultiplier",
    type: "float",
    min: 0,
    max: 10,
    defaultValue: 0.19,
    label: "Spacing Multiplier",
    category: "category3",
  },
  {
    name: "textCopyAmount",
    type: "scalar",
    min: 1,
    max: 50,
    defaultValue: 5,
    label: "Text Copy Amount",
    category: "category3",
  },
];

const sketch = (p) => {
  const givenState = document.p5jsState;
  const state = givenState || {};
  const configFromState = state.configuration;
  let isAnimated = givenState.homeScreen;
  let bgOverrideColor = givenState.bgColor;

  let sign = getConfigValueIfUndefined(config, configFromState, "sign");
  let overrideText = getConfigValueIfUndefined(
    config,
    configFromState,
    "overrideText"
  );
  let textPositionOffset = getConfigValueIfUndefined(
    config,
    configFromState,
    "textPositionOffset"
  );
  let textCopyTiltAmount = getConfigValueIfUndefined(
    config,
    configFromState,
    "textCopyTiltAmount"
  );
  let spacing = getConfigValueIfUndefined(config, configFromState, "spacing");
  let spacingMultiplier = getConfigValueIfUndefined(
    config,
    configFromState,
    "spacingMultiplier"
  );
  let textCopyAmount = getConfigValueIfUndefined(
    config,
    configFromState,
    "textCopyAmount"
  );
  let strokeWeight = getConfigValueIfUndefined(
    config,
    configFromState,
    "strokeWeight"
  );
  let textColor = getConfigValueIfUndefined(
    config,
    configFromState,
    "textColor"
  );
  let strokeColor = getConfigValueIfUndefined(
    config,
    configFromState,
    "strokeColor"
  );
  let bgColor = getConfigValueIfUndefined(config, configFromState, "bgColor");

  let screenSizeX = getValueIfUndefined(state, "screenSizeX", 1024);
  let screenSizeY = getValueIfUndefined(state, "screenSizeY", 1024);
  let pixelDensity = getValueIfUndefined(state, "pixelDensity", 0.5);

  p.myCustomRedrawAccordingToNewPropsHandler = (props) => {
    if (exists(props.sign)) {
      sign = props.sign;
    }

    if (exists(props.overrideText)) {
      overrideText = props.overrideText;
    }

    if (exists(props.textPositionOffset)) {
      textPositionOffset = parseFloat(props.textPositionOffset);
    }

    if (exists(props.textCopyTiltAmount)) {
      textCopyTiltAmount = parseFloat(props.textCopyTiltAmount);
    }

    if (exists(props.spacing)) {
      spacing = parseInt(props.spacing, 10);
    }

    if (exists(props.spacingMultiplier)) {
      spacingMultiplier = parseFloat(props.spacingMultiplier);
    }

    if (exists(props.textCopyAmount)) {
      textCopyAmount = parseInt(props.textCopyAmount, 10);
    }

    if (exists(props.strokeWeight)) {
      strokeWeight = parseFloat(props.strokeWeight);
    }

    if (exists(props.textColor)) {
      textColor = props.textColor;
    }

    if (exists(props.strokeColor)) {
      strokeColor = props.strokeColor;
    }

    if (exists(props.bgColor)) {
      bgColor = props.bgColor;
    }
  };

  let myFont;

  p.preload = () => {
    myFont = p.loadFont(Montserrat);
  };

  p.setup = () => {
    const canvas = p.createCanvas(screenSizeX, screenSizeY);
    canvas.drawingContext.miterLimit = 2;

    p.pixelDensity(pixelDensity);

    p.rectMode(p.CENTER);
    p.angleMode(p.DEGREES);
    p.frameRate(isAnimated ? 24 : 6);
    p.textAlign(p.CENTER, p.CENTER);
  };

  p.draw = () => {
    if (bgOverrideColor) {
      p.background(bgOverrideColor);
    } else {
      p.background(bgColor);
    }

    p.textFont(myFont);

    let displayText = overrideText || sign.toUpperCase();

    const width = p.width;
    const height = p.height;
    const originX = width / 2;
    const originY = height / 2;
    const widthWithPadding = width * 0.75;

    if (isAnimated) {
      const animationValue = lerp(-0.3, 0.3, p.sin(p.frameCount * 2) + 1);
      textCopyTiltAmount = animationValue;
    }

    p.push();
    p.translate(0, originY + textPositionOffset);

    for (let i = spacing * textCopyAmount; i > 0; i -= spacing) {
      // styling
      {
        let finalTextColor = textColor;
        let finalStrokeColor = strokeColor;

        p.fill(finalTextColor);
        p.strokeWeight(strokeWeight);
        p.stroke(finalStrokeColor);
      }
      // styling ends

      p.push();
      p.translate(0 - i * textCopyTiltAmount, i * spacingMultiplier);

      {
        // draw text
        p.push();

        // fixed text size
        const textSize = 200;
        p.textSize(textSize);
        const mainSignTextWidth = p.textWidth(displayText);
        const widthToTextRatio = widthWithPadding / mainSignTextWidth;
        p.textSize(textSize * widthToTextRatio);

        p.text(displayText, originX, 0);
        p.pop();
        // draw text ends
      }

      p.pop();
    }

    p.pop();
  };
};

export default sketch;

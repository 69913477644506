import { lerp } from "canvas-sketch-util/math";
import {
  exists,
  getConfigValueIfUndefined,
  getValueIfUndefined,
} from "../../utilities/misc";
import tinyColor from "../../utilities/tinycolor";
import { personalities } from "../../utilities/enneagram";

import Montserrat from "../../assets/fonts/Montserrat-Black.ttf";

export const config = [
  {
    name: "category1",
    type: "category",
    label: "Text Controls",
  },
  {
    name: "category2",
    type: "category",
    label: "Other Controls",
  },
  {
    name: "personality",
    type: "select",
    defaultValue: Object.keys(personalities)[0],
    options: Object.keys(personalities),
    label: "Enneagram Types",
    category: "category1",
  },
  {
    name: "overrideText",
    type: "text",
    defaultValue: "",
    label: "Override Text",
    category: "category1",
  },
  {
    name: "textSize",
    type: "float",
    min: 50,
    max: 400,
    defaultValue: 140,
    label: "Text Size",
    category: "category1",
  },
  {
    name: "textColor",
    type: "color",
    defaultValue: "#ffffff",
    label: "Text Color",
    category: "category1",
  },
  {
    name: "bgColor",
    type: "color",
    defaultValue: "#45345a",
    label: "Background Color",
    category: "category1",
  },
  {
    name: "spread",
    type: "float",
    min: 0,
    max: 400,
    defaultValue: 250,
    label: "Spread",
    category: "category2",
  },
  {
    name: "reveal",
    type: "float",
    min: 0,
    max: 1,
    defaultValue: 0.35,
    label: "Reveal",
    category: "category2",
  },
  {
    name: "lighten",
    type: "boolean",
    defaultValue: true,
    label: "Fade",
    category: "category2",
  },
];

const sketch = (p) => {
  const givenState = document.p5jsState;
  const state = givenState || {};
  const configFromState = state.configuration;

  let personality = getConfigValueIfUndefined(
    config,
    configFromState,
    "personality"
  );
  let overrideText = getConfigValueIfUndefined(
    config,
    configFromState,
    "overrideText"
  );
  let textSize = getConfigValueIfUndefined(config, configFromState, "textSize");
  let textColor = getConfigValueIfUndefined(
    config,
    configFromState,
    "textColor"
  );
  let bgColor = getConfigValueIfUndefined(config, configFromState, "bgColor");
  let spread = getConfigValueIfUndefined(config, configFromState, "spread");
  let reveal = getConfigValueIfUndefined(config, configFromState, "reveal");
  let lighten = getConfigValueIfUndefined(config, configFromState, "lighten");

  let screenSizeX = getValueIfUndefined(state, "screenSizeX", 1024);
  let screenSizeY = getValueIfUndefined(state, "screenSizeY", 1024);
  let pixelDensity = getValueIfUndefined(state, "pixelDensity", 0.5);

  p.myCustomRedrawAccordingToNewPropsHandler = (props) => {
    if (exists(props.personality)) {
      personality = props.personality;
    }

    if (exists(props.overrideText)) {
      overrideText = props.overrideText;
    }

    if (exists(props.textSize)) {
      textSize = parseFloat(props.textSize);
    }

    if (exists(props.textColor)) {
      textColor = props.textColor;
    }

    if (exists(props.bgColor)) {
      bgColor = props.bgColor;
    }

    if (exists(props.spread)) {
      spread = parseFloat(props.spread);
    }

    if (exists(props.reveal)) {
      reveal = parseFloat(props.reveal);
    }

    if (exists(props.lighten)) {
      lighten = props.lighten;
    }
  };

  let myFont;

  p.preload = () => {
    myFont = p.loadFont(Montserrat);
  };

  p.setup = () => {
    p.createCanvas(screenSizeX, screenSizeY);
    p.pixelDensity(pixelDensity);
    p.rectMode(p.CENTER);
    p.imageMode(p.CENTER);
    p.angleMode(p.DEGREES);
    p.textAlign(p.CENTER, p.CENTER);
    p.frameRate(6);
  };

  p.draw = () => {
    p.background(bgColor);

    const height = p.height;
    const originY = height / 2;

    const fontSize = textSize;
    let revealRatio = spread * reveal;
    p.textFont(myFont, fontSize);
    let displayText = overrideText || personality;

    for (let i = spread; i > 0; i -= revealRatio) {
      const changeRatio = lighten ? lerp(1, 0.1, i / spread) : null;
      getText({
        changeRatio,
        fontSize,
        p,
        y: originY + i,
        text: displayText,
        textColor,
        bgColor,
      });
    }

    for (let i = spread; i > 0; i -= revealRatio) {
      const changeRatio = lighten ? lerp(1, 0.1, i / spread) : null;
      getText({
        changeRatio,
        fontSize,
        p,
        y: originY - i,
        text: displayText,
        textColor,
        bgColor,
      });
    }

    getText({ fontSize, p, y: originY, text: displayText, textColor, bgColor });
  };
};

function getText({
  changeRatio = null,
  p,
  text,
  textColor,
  y,
  fontSize,
  bgColor,
}) {
  const width = p.width;
  const originX = width / 2;
  let color = textColor;

  if (changeRatio) {
    color = tinyColor(textColor)
      .setAlpha(changeRatio)
      .toRgbString();
    color = color.toString();
  }

  p.push();
  p.translate(originX, y);
  p.noStroke();
  p.fill(bgColor);
  p.rect(0, 0, width, fontSize);
  p.fill(color);
  p.text(text.toUpperCase(), 0, 0 - fontSize * 0.13);
  p.pop();
}

export default sketch;

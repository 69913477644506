import React from "react";
import compose from "lodash.flowright";
import { graphql } from "react-apollo";
import gql from "graphql-tag";

import {
  deleteCheckoutData,
  getCheckoutData,
  setCheckoutData,
} from "./utilities/localStorage";

import {
  createCheckout,
  checkoutLineItemsAdd,
  checkoutLineItemsUpdate,
  checkoutLineItemsRemove,
  checkoutCustomerAssociate,
} from "./checkout";

const initialState = {};

const Context = React.createContext(initialState);

class AppStateProvider extends React.Component {
  state = {
    checkout: { lineItems: { edges: [] } },
    checkoutUpdating: false,
  };

  async componentWillMount() {
    const savedCheckout = await getCheckoutData();
    let lineItems;

    if (savedCheckout) {
      lineItems = savedCheckout.lineItems.edges.map((item) => {
        const { variant, quantity, customAttributes } = item.node;

        return {
          variantId: variant.id,
          quantity,
          customAttributes: customAttributes.map((item) => ({
            key: item.key,
            value: item.value,
          })),
        };
      });
    }

    const checkoutBackup = {};
    const checkout = lineItems ? { lineItems } : checkoutBackup;

    this.setState(
      {
        checkoutUpdating: true,
      },
      () => {
        this.props
          .createCheckout({
            variables: {
              input: checkout,
            },
          })
          .then((res) => {
            this.setState({
              checkout: res.data.checkoutCreate.checkout,
              checkoutUpdating: false,
            });
          })
          .catch(async (err) => {
            // error with the saved checkout
            await deleteCheckoutData();
            // TODO not sure if this is the best way to tackle it.
            // Ideally you would retry the request above.
            window.location.reload();
          });
      }
    );
  }

  addVariantToCart = ({ variantId, quantity = 1, customAttributes = "" }) => {
    const formattedCustomAttributes = [
      {
        key: "properties",
        value: customAttributes,
      },
    ];

    this.setState(
      {
        checkoutUpdating: true,
      },
      () => {
        return this.props
          .checkoutLineItemsAdd({
            variables: {
              checkoutId: this.state.checkout.id,
              lineItems: [
                {
                  variantId,
                  quantity: parseInt(quantity, 10),
                  customAttributes: formattedCustomAttributes,
                },
              ],
            },
          })
          .then(async (res) => {
            const checkout = res.data.checkoutLineItemsAdd.checkout;
            await setCheckoutData(checkout);

            this.setState({
              checkout,
              checkoutUpdating: false,
            });
          });
      }
    );
  };

  updateLineItemInCart = (lineItemId, quantity) => {
    this.setState(
      {
        checkoutUpdating: true,
      },
      () => {
        this.props
          .checkoutLineItemsUpdate({
            variables: {
              checkoutId: this.state.checkout.id,
              lineItems: [{ id: lineItemId, quantity: parseInt(quantity, 10) }],
            },
          })
          .then(async (res) => {
            const checkout = res.data.checkoutLineItemsUpdate.checkout;
            await setCheckoutData(checkout);

            this.setState({
              checkout,
              checkoutUpdating: false,
            });
          });
      }
    );
  };

  removeLineItemInCart = (lineItemId) => {
    this.setState(
      {
        checkoutUpdating: true,
      },
      () => {
        this.props
          .checkoutLineItemsRemove({
            variables: {
              checkoutId: this.state.checkout.id,
              lineItemIds: [lineItemId],
            },
          })
          .then(async (res) => {
            const checkout = res.data.checkoutLineItemsRemove.checkout;
            await setCheckoutData(checkout);

            this.setState({
              checkout,
              checkoutUpdating: false,
            });
          });
      }
    );
  };

  render() {
    const { children } = this.props;

    return (
      <Context.Provider
        value={{
          ...this.state,
          addVariantToCart: this.addVariantToCart,
          removeLineItemInCart: this.removeLineItemInCart,
          updateLineItemInCart: this.updateLineItemInCart,
        }}
      >
        {children}
      </Context.Provider>
    );
  }
}

const AppStateProviderWithDataAndMutation = compose(
  graphql(createCheckout, { name: "createCheckout" }),
  graphql(checkoutLineItemsAdd, { name: "checkoutLineItemsAdd" }),
  graphql(checkoutLineItemsUpdate, { name: "checkoutLineItemsUpdate" }),
  graphql(checkoutLineItemsRemove, { name: "checkoutLineItemsRemove" }),
  graphql(checkoutCustomerAssociate, { name: "checkoutCustomerAssociate" })
)(AppStateProvider);

export const Provider = AppStateProviderWithDataAndMutation;
export const Consumer = Context.Consumer;

export default AppStateProviderWithDataAndMutation;

import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import LoadingIcon from "src/components/LoadingIcon";
import { Blog } from "./Blog";

export const BlogProvider = (props) => {
  const { loading, error, data } = useQuery(query);

  if (loading) {
    return <LoadingIcon />;
  }

  if (error) {
    // @TODO Add a something went wrong message here
    return <p>{error.message}</p>;
  }

  const articles = data.blogByHandle.articles.edges.map((item) => item.node);

  return <Blog articles={articles} {...props} />;
};

const query = gql`
  query blog {
    blogByHandle(handle: "blog") {
      title
      id
      handle
      articles(first: 50) {
        edges {
          node {
            title
            handle
            content
            contentHtml
            tags
            image {
              altText
              originalSrc
            }
          }
        }
      }
    }
  }
`;

import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useQueryParams, JsonParam } from "use-query-params";

import { Box, Flex } from "src/components/Primitives";
import { getBreakPoint } from "src/styles/theme";

import { DesignController } from "../DesignController";
import DesignDisplay from "../DesignDisplay";

import {
  DesignDisplayContainer,
  DesignDisplayInnerContainer,
  DesignControllerContainer,
} from "./DesignWithController.components";

const DesignWithController = (props) => {
  const [designState, setDesignState] = useState({});

  const {
    children,
    configuration,
    fullHeightController = true,
    sketch,
    sketchProportions,
    getState = () => {},
    page,
    displayState = {},
    productProperties = {},
  } = props;

  const tabletBreakpoint = getBreakPoint("tablet");
  const isLargerThanMobile = useMediaQuery({
    query: `(min-width: ${tabletBreakpoint}px)`,
  });
  const isBgIgnored = Boolean(productProperties.transparentBg);

  const [query] = useQueryParams({
    state: JsonParam,
  });
  const { state: initialState } = query;

  return (
    <Flex flexDirection={"column"}>
      <Flex width={"100%"} flexDirection={["column", "column", "row"]}>
        <DesignDisplayContainer
          width={[1, 1, 0.6]}
          p={[5, 10, 10]}
          pt={page === "404" ? [5] : [5, 10, 20]}
        >
          <DesignDisplayInnerContainer mb={4}>
            <DesignDisplay
              sketch={sketch}
              state={designState}
              aspectRatio={sketchProportions}
              configuration={configuration}
              extraState={{ ...displayState, ...productProperties }}
            />
          </DesignDisplayInnerContainer>
        </DesignDisplayContainer>

        <DesignControllerContainer
          width={[1, 1, 0.4]}
          isMobile={!isLargerThanMobile}
          pb={25}
          styleOverride={{
            paddingLeft: page === "404" && "24px",
            minHeight: page === "404" && "initial",
          }}
          noCategory={page === "404"}
        >
          <Box mb={5}>
            <DesignController
              stateCallback={(state) => {
                setDesignState(state);
                getState(state);
              }}
              initialState={initialState}
              configuration={configuration}
              mainPage={!fullHeightController}
              isBgIgnored={isBgIgnored}
            />
          </Box>
          <Box>{children}</Box>
        </DesignControllerContainer>
      </Flex>
    </Flex>
  );
};

export default DesignWithController;

import React from "react";

import { P5Wrapper } from "src/components/P5Wrapper";
import { CanvasContainer } from "./DesignDisplay.components";

const DesignDisplay = (props) => {
  const defaultSize = 1024;
  const defaultSizeUnit = "px";
  const defaultAspectRatio = {
    x: 1,
    y: 1,
  };

  const {
    configuration,
    sketch,
    state,
    width = defaultSize,
    height = defaultSize,
    aspectRatio = defaultAspectRatio,
    extraState,
    styleConfig = {},
  } = props;

  const calculatedWidth = width * aspectRatio.x;
  const calculatedWidthUnit = `${calculatedWidth}${defaultSizeUnit}`;

  const calculatedHeight = height * aspectRatio.y;
  const calculatedHeightUnit = `${calculatedHeight}${defaultSizeUnit}`;

  const additionalState = {
    screenSizeX: calculatedWidth,
    screenSizeY: calculatedHeight,
    pixelDensity: 0.5,
    ...extraState,
    configuration,
  };

  document.p5jsState = additionalState;

  const newState = {
    ...state,
    ...additionalState,
  };

  return (
    <CanvasContainer
      width={calculatedWidthUnit}
      height={calculatedHeightUnit}
      styleConfig={styleConfig}
    >
      <P5Wrapper sketch={sketch} {...newState} />
    </CanvasContainer>
  );
};

export default DesignDisplay;

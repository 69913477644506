import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Box } from "src/components/Primitives";

const styles = (_theme) => ({
  spinner: {
    color: "#6798e5",
  },
});

const LoadingIconContainer = styled(Box)`
  background: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  ${({ height }) => {
    if (height === undefined) {
      return `height: 100vh;`;
    } else {
      return `height: ${height}`;
    }
  }}

  ${({ fullScreen, fullScreenPaddingSize }) => {
    if (fullScreen && fullScreenPaddingSize) {
      return `& > div {
        width: 100% !important;
        height: 100% !important;
        padding: ${fullScreenPaddingSize};
      }`;
    }
  }}
`;

class LoadingIcon extends React.Component {
  render() {
    const { classes, ...props } = this.props;

    return (
      <LoadingIconContainer {...props}>
        <CircularProgress size={60} className={classes.spinner} />
      </LoadingIconContainer>
    );
  }
}

export default withStyles(styles)(LoadingIcon);

import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import Rating from "@material-ui/lab/Rating";

import { Flex, BodyText, Box, Text } from "src/components/Primitives";

import { border, boxShadow } from "src/styles/mixins";
import { getBreakPoint } from "src/styles/theme";

import Headshot01 from "./images/headshot01.jpg";
import Headshot02 from "./images/headshot02.jpg";
import Headshot03 from "./images/headshot03.jpg";

const Container = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 36px;
  grid-row-gap: 18px;

  ${({ isMobile }) => {
    if (isMobile) {
      return `
      grid-template-columns: 1fr;
      `;
    }
  }}
`;

const TestimonialContainer = styled(Box)`
  border: ${border()};
  box-shadow: ${boxShadow()};
  height: fit-content;
`;

const Author = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Avatar = styled.img`
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: ${border()};
`;

const Testimonial = ({ author = "Anonymous", children, title, avatar }) => {
  return (
    <TestimonialContainer py={7} px={7}>
      <Box mb={2}>
        <Rating value={5} readOnly />
      </Box>
      <Flex mb={3} alignItems={"center"}>
        <Box mr={3}>
          <Avatar src={avatar} alt={`Headshot for ${author}`} />
        </Box>
        <Text fontSize={5} fontWeight={"500"}>
          {title}
        </Text>
      </Flex>
      <Box mb={5}>
        <BodyText fontSize={5}>{children}</BodyText>
      </Box>

      <Author mb={5}>
        <Text fontSize={6} fontWeight={"500"}>
          - {author}
        </Text>
      </Author>
    </TestimonialContainer>
  );
};

export const TestimonialsSection = () => {
  const tabletBreakpoint = getBreakPoint("tablet");
  const isLargerThanMobile = useMediaQuery({
    query: `(min-width: ${tabletBreakpoint}px)`,
  });
  const isMobile = !isLargerThanMobile;

  return (
    <Container isMobile={isMobile}>
      <Testimonial title={"The Best!"} avatar={Headshot01}>
        I love it. I finally found my favourite brand.
      </Testimonial>
      <Testimonial title={"Love it!"} avatar={Headshot02}>
        Pellentesque a velit nec leo mollis dapibus. Mauris maximus libero mi,
        nec lobortis libero tincidunt sit amet. Ut rhoncus ex orci, elementum
        accumsan urna rhoncus sed. Sed tempus turpis quis semper mollis. Donec
        lobortis ut massa et tristique. Aenean quis odio vitae dui efficitur
        viverra eu sed dui.
      </Testimonial>
      <Testimonial title={"Customization is a bit hard"} avatar={Headshot03}>
        Pellentesque a velit nec leo mollis dapibus. Mauris maximus libero mi,
        nec lobortis libero tincidunt sit amet. Ut rhoncus ex orci, elementum
        accumsan urna rhoncus sed. Sed tempus turpis quis semper mollis. Donec
        lobortis ut massa et tristique.
      </Testimonial>
    </Container>
  );
};

import theme from "./theme";

export const border = (multiplier = 1) => {
  return `${multiplier * 1}px solid ${theme.colors.lightGray}`;
};

export const borderSelected = (multiplier = 1) => {
  return `${multiplier * 1}px solid ${theme.colors.pink}`;
};

export const boxShadow = () => {
  return `2px 2px 10px 2px ${theme.colors.lightGray}`;
};

export const borderRadius = () => {
  return "5px";
};

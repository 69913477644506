import React from "react";
import gql from "graphql-tag";
import { withRouter, Redirect } from "react-router-dom";
import { useQueryParams, StringParam, JsonParam } from "use-query-params";
import { useQuery } from "@apollo/react-hooks";

import Layout from "src/components/Layout";
import LoadingIcon from "src/components/LoadingIcon";
import * as AppContext from "src/App.context";

import CustomizeYourDesign from "./CustomizeYourDesign";
import { convertSelectedProductOptionsObjToArray } from "../DesignCollection/DesignCollection";
import { parseProductOptionsQuery } from "../DesignCollection/DesignCollection.provider";

function getProductProperties(productType, variantTitle) {
  const isApperal = productType.includes("T-Shirt");
  if (!isApperal) {
    return false;
  }

  const productColor = variantTitle.split("/")[0].trim();
  let productColorInHex;

  switch (productColor) {
    case "Athletic Heather": {
      productColorInHex = "#a8abb2";
      break;
    }
    case "White": {
      productColorInHex = "#e2e3de";
      break;
    }
    case "Black": {
      productColorInHex = "#27262b";
      break;
    }
    case "Asphalt": {
      productColorInHex = "#505457";
      break;
    }
    case "Navy": {
      productColorInHex = "#37384a";
      break;
    }
    default: {
      productColorInHex = "black";
    }
  }

  return {
    transparentBg: true,
    bgColor: productColorInHex,
  };
}

const CustomizeYourDesignProvider = ({ match }) => {
  const collectionId = match.params.designId;
  const [query, setQuery] = useQueryParams({
    productId: StringParam,
    productOptions: JsonParam,
    state: JsonParam,
  });
  const {
    productId: id,
    productOptions: productOptionsStr,
    state: designSettings,
  } = query;

  const selectedProductOptions = parseProductOptionsQuery(productOptionsStr)[
    id
  ];

  const selectedProductOptionsArr = convertSelectedProductOptionsObjToArray(
    selectedProductOptions
  );

  const prerequisites = Boolean(
    id && productOptionsStr && Array.isArray(selectedProductOptionsArr)
  );

  const { loading, error, data } = useQuery(QUERY_VARIANT_DATA, {
    variables: { id, selectedOptions: selectedProductOptionsArr },
    skip: !prerequisites,
  });

  if (!prerequisites) {
    return <Redirect to={`/design/${collectionId}`} />;
  }

  if (loading || !data) {
    return <LoadingIcon />;
  }

  if (error) {
    console.error(error.message);
    return (
      <Redirect
        to={{
          pathname: `/design/${id}`,
        }}
      />
    );
  }

  return (
    <AppContext.Consumer>
      {(appContext) => {
        const {
          addVariantToCart,
          checkout,
          checkoutUpdating,
          removeLineItemInCart,
          updateLineItemInCart,
        } = appContext;

        const layoutProps = {
          checkout,
          removeLineItemInCart,
          updateLineItemInCart,
          displayNewsletter: false,
          checkoutUpdating,
          title: "Customize your Product Design",
        };

        return (
          <Layout {...layoutProps}>
            {(layoutContext) => {
              const { handleOpenCheckout, handleCartOpen } = layoutContext;

              const variantBySelectedOptions =
                data.node.variantBySelectedOptions;

              if (!variantBySelectedOptions) {
                return <div>The selected variant doesn't exist.</div>;
              }

              const productType = variantBySelectedOptions.product.title;
              const variantTitle = variantBySelectedOptions.title;

              const productProperties = getProductProperties(
                productType,
                variantTitle
              );

              return (
                <CustomizeYourDesign
                  addVariantToCart={addVariantToCart}
                  collectionId={collectionId}
                  data={data}
                  initialDesignSettings={designSettings}
                  id={id}
                  handleCartOpen={handleCartOpen}
                  selectedProductOptions={selectedProductOptionsArr}
                  handleOpenCheckout={handleOpenCheckout}
                  productProperties={productProperties}
                  updateUrl={setQuery}
                />
              );
            }}
          </Layout>
        );
      }}
    </AppContext.Consumer>
  );
};

export default withRouter(CustomizeYourDesignProvider);

const QUERY_VARIANT_DATA = gql`
  query getVariantData($id: ID!, $selectedOptions: [SelectedOptionInput!]!) {
    node(id: $id) {
      id
      ... on Product {
        variantBySelectedOptions(selectedOptions: $selectedOptions) {
          id
          title
          product {
            id
            title
          }
        }
      }
    }
  }
`;

import React, { useEffect } from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";

import { Fade } from "src/components/Fade";
import { MaxWidthContainer } from "src/components/MaxWidthContainer";
import { Box, MainTitle, Page } from "src/components/Primitives";
import { scrollToTop } from "src/utilities/misc";

import { LayoutWrapper } from "src/components/LayoutWrapper";
import { Article } from "./components";

const Container = styled(Box)`
  ${(props) => {
    return `
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: ${themeGet("space.1")(props)}px;

        @media screen and (min-width: ${themeGet("breakpoints.0")(props)}) {
          grid-template-columns: repeat(2, 1fr);
        }
      
        @media screen and (min-width: ${themeGet("breakpoints.1")(props)}) {
          grid-template-columns: repeat(3, 1fr);
        }
    `;
  }}
`;

export const Blog = ({ articles }) => {
  useEffect(() => {
    scrollToTop();
  }, []);

  const articlesMarkup = articles.map((data) => {
    return <Article data={data} key={data.title} />;
  });

  return (
    <LayoutWrapper>
      <Fade>
        <Page>
          <MaxWidthContainer>
            <MainTitle>Blog</MainTitle>
            Coming Soon...
          </MaxWidthContainer>
        </Page>
      </Fade>
    </LayoutWrapper>
  );

  return (
    <LayoutWrapper>
      <Fade>
        <Page>
          <MaxWidthContainer>
            <MainTitle>Blog</MainTitle>
            <Container>{articlesMarkup}</Container>
          </MaxWidthContainer>
        </Page>
      </Fade>
    </LayoutWrapper>
  );
};

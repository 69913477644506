import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import { Twitter, Instagram, Facebook, Send } from "react-feather";
import { Link } from "react-router-dom";

import { Box, Flex, Text } from "src/components/Primitives";
import { MaxWidthContainer } from "src/components/MaxWidthContainer";
import { scrollToTop } from "src/utilities/misc";

const FooterSocialIcon = styled.div`
  ${(props) => {
    return `
      color: ${themeGet("colors.midGray")(props)}
      padding: 10px;
      margin-right: 8px;
      cursor: pointer;
    `;
  }}
`;
const FooterSocial = () => {
  const iconProps = { size: "24px", fill: "none" };

  return (
    <Box mx={2}>
      <Flex>
        <FooterSocialIcon>
          <Twitter {...iconProps} />
        </FooterSocialIcon>
        <FooterSocialIcon>
          <Instagram {...iconProps} />
        </FooterSocialIcon>
        <FooterSocialIcon>
          <Facebook {...iconProps} />
        </FooterSocialIcon>
        <FooterSocialIcon>
          <Send {...iconProps} />
        </FooterSocialIcon>
      </Flex>
    </Box>
  );
};

const FooterNavigationContactLink = () => {
  return (
    <Box mx={4} my={3}>
      <a href="mailto:karakterastore@gmail.com">
        <Text color={"lightGrey"} fontSize={7}>
          Contact
        </Text>
      </a>
    </Box>
  );
};

const FooterNavigationLink = ({ pathname, name }) => {
  return (
    <Box mx={4} my={3}>
      <Link
        to={{
          pathname: pathname,
        }}
        onClick={() => {
          scrollToTop();
        }}
      >
        <Text color={"lightGrey"} fontSize={7}>
          {name}
        </Text>
      </Link>
    </Box>
  );
};

const FooterNavigationGroup = styled(Flex)``;

const FooterNavigation = () => {
  return (
    <Box>
      <Flex mb={4} flexDirection={["column", "column", "row"]}>
        <FooterNavigationGroup flexDirection={["column", "row", "row"]}>
          <FooterNavigationLink pathname={"/"} name={"Home"} />
          <FooterNavigationLink pathname={"/shop"} name={"Shop"} />
          <FooterNavigationLink pathname={"/about"} name={"About"} />
          <FooterNavigationLink pathname={"/blog"} name={"Blog"} />
          <FooterNavigationLink pathname={"/faq"} name={"FAQ"} />
        </FooterNavigationGroup>

        <FooterNavigationGroup flexDirection={["column", "row", "row"]}>
          <FooterNavigationLink
            pathname={"/shipping-and-returns"}
            name={"Shipping & Returns"}
          />
          <FooterNavigationLink
            pathname={"/terms-of-service"}
            name={"Terms of Service"}
          />
          <FooterNavigationLink
            pathname={"/privacy-policy"}
            name={"Privacy Policy"}
          />
          <FooterNavigationContactLink />
        </FooterNavigationGroup>
      </Flex>
      <Text color={"lightGrey"} fontSize={8} mx={3}>
        © 2020 Karaktera. All Rights Reserved.
      </Text>
    </Box>
  );
};

class Footer extends React.Component {
  render() {
    return (
      <Box bg={"black"} px={[10, 10, 10, 0]} py={10}>
        <MaxWidthContainer>
          <Flex
            justifyContent={"space-between"}
            flexDirection={["column", "column", "column", "row"]}
          >
            <FooterSocial />
            <FooterNavigation />
          </Flex>
        </MaxWidthContainer>
      </Box>
    );
  }
}

export default Footer;

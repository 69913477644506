import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";
import theme from "src/styles/theme";

const CustomSlider = withStyles({
  root: {
    color: theme.colors.gray,
    height: 8
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -10,
    marginLeft: -12,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)"
  },
  track: {
    height: 4,
    borderRadius: 4,
    color: theme.colors.pink
  },
  rail: {
    height: 4,
    borderRadius: 4
  }
})(Slider);

export default CustomSlider;

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Fade } from "src/components/Fade";
import { encodeQueryParams, JsonParam } from "use-query-params";
import { stringify } from "query-string";

import styled from "styled-components";
import { Box, Flex } from "src/components/Primitives";
import { Button } from "src/components/Button";
import DesignWithController from "src/components/DesignWithController";
import { scrollToTop } from "src/utilities/misc";

import { getDesignConfiguration, getProductProportions } from "./utils";

const Page = styled(Box)``;

const CustomizeYourDesign = ({
  addVariantToCart,
  collectionId,
  data,
  initialDesignSettings,
  handleCartOpen,
  history,
  match,
  location,
  selectedProductOptions,
  updateUrl,
  productProperties,
}) => {
  function updateUrlWithState() {
    const state = designSettings;
    updateUrl({
      state,
    });

    const encodedQuery = encodeQueryParams({ state: JsonParam }, { state });
    const encodedQueryStr = stringify(encodedQuery);

    return encodedQueryStr;
  }
  const [designSettings, setDesignSettings] = useState(initialDesignSettings);

  useEffect(() => {
    scrollToTop();
  }, []);

  const { config: configuration, sketch } = getDesignConfiguration(
    collectionId
  );

  const variantId = data.node.variantBySelectedOptions.id;
  const productProportions = getProductProportions(selectedProductOptions);

  const handleAddToCart = () => {
    const newSearchQuery = updateUrlWithState();
    const baseUrl = location.pathname;
    const searchQuery = location.search;
    const fullUrl = `${baseUrl}${searchQuery}&${newSearchQuery}`;

    const customAttributes = {
      designSettings,
      url: fullUrl,
    };

    const variantData = {
      variantId,
      customAttributes: JSON.stringify(customAttributes),
    };

    addVariantToCart(variantData);
    handleCartOpen();
  };

  const handlePrevious = () => {
    const designType = match.params.designType;
    const searchQuery = location.search;

    const newSearchQuery = updateUrlWithState();

    history.push(
      `/design/${designType}/${collectionId}${searchQuery}&${newSearchQuery}`
    );
  };

  const buttons = (
    <Box px={5}>
      <Flex flexDirection={["column", "row", "column"]}>
        <Box ml={[0]} width={1} mb={[2]} mr={[0, 2, 0]}>
          <Button fullWidth onClick={handleAddToCart} type="pink">
            Add to Cart
          </Button>
        </Box>
        <Box mr={[0]} width={1}>
          <Button fullWidth onClick={handlePrevious}>
            Previous
          </Button>
        </Box>
      </Flex>
    </Box>
  );

  return (
    <Page>
      <Fade>
        <Box>
          <DesignWithController
            configuration={configuration}
            sketch={sketch}
            sketchProportions={productProportions}
            productProperties={productProperties}
            getState={(state) => {
              setDesignSettings(state);
            }}
          >
            {buttons}
          </DesignWithController>
        </Box>
      </Fade>
    </Page>
  );
};

export default withRouter(CustomizeYourDesign);

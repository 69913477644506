import React from "react";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Flex, Text } from "src/components/Primitives";

const CollapsibleSectionTitle = ({ children }) => {
  return (
    <Text fontWeight={"900"} fontSize={7} textTransform={"uppercase"}>
      {children}
    </Text>
  );
};

export const CollapsibleSection = ({
  controllers,
  expanded,
  label,
  name,
  handleChange,
}) => {
  return (
    <ExpansionPanel
      key={label}
      expanded={expanded === label}
      onChange={handleChange(label)}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${label}-content`}
        id={label}
      >
        <CollapsibleSectionTitle>{label}</CollapsibleSectionTitle>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Flex flexDirection={"column"} width={1}>
          {controllers
            .filter((controller) => {
              const { category } = controller;
              if (category === name) {
                return true;
              }

              return false;
            })
            .map((controller) => controller.component)}
        </Flex>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

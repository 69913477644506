export const imagePaths = {
  about1:
    "https://cdn.shopify.com/s/files/1/0261/9041/3846/files/about1.jpg?v=1589137626",
  about2:
    "https://cdn.shopify.com/s/files/1/0261/9041/3846/files/about2.jpg?v=1589137626",
  hero:
    "https://cdn.shopify.com/s/files/1/0261/9041/3846/files/hero.jpg?v=1589137625",
  landing1:
    "https://cdn.shopify.com/s/files/1/0261/9041/3846/files/landing1.jpg?v=1589137625",
  mockupToteBag:
    "https://cdn.shopify.com/s/files/1/0261/9041/3846/files/mockup-tote-bag.jpg?v=1589137625",
  mockupPillow:
    "https://cdn.shopify.com/s/files/1/0261/9041/3846/files/mockup-pillow.jpg?v=1589137625",
  mockupPoster:
    "https://cdn.shopify.com/s/files/1/0261/9041/3846/files/mockup-poster.jpg?v=1589137625",
  mockupMug:
    "https://cdn.shopify.com/s/files/1/0261/9041/3846/files/mockup-mug.jpg?v=1589137625",
  ogImage:
    "https://cdn.shopify.com/s/files/1/0261/9041/3846/files/ogimage.jpg?v=1589140527",
};

import React from "react";
import styled from "styled-components";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { CopyText, BodyTitle, Box, Subtitle } from "src/components/Primitives";

export const TitleContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ExpansionItem = ({ expanded, onChange, name, heading, content }) => {
  return (
    <ExpansionPanel expanded={expanded === name} onChange={onChange(name)}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${name}bh-content`}
        id={`${name}bh-header`}
      >
        <BodyTitle textTransform={"initial"} textAlign={"left"}>
          {heading}
        </BodyTitle>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <CopyText>{content}</CopyText>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const FaqItems = ({ expanded, handleChange }) => {
  return (
    <Box>
      <ExpansionItem
        expanded={expanded}
        onChange={handleChange}
        name={"panel0"}
        heading={"How does this work?"}
        content={
          "First, you choose a design. Then you choose a product to print that design on. After that you can customize the design and order the product with the customized design"
        }
      />
      <ExpansionItem
        expanded={expanded}
        onChange={handleChange}
        name={"panel1"}
        heading={"When will I get my order?"}
        content={
          "It takes 3-7 days to fulfill an order, after which it’s shipped out. The shipping time depends on your location, but can be estimated as follows: USA: 5-​8 business days, International: 10-20 business days. We are experiencing slight delays due to COVID19."
        }
      />
      <ExpansionItem
        expanded={expanded}
        onChange={handleChange}
        name={"panel2"}
        heading={"Where will my order ship from?"}
        content={
          "We work with an on-demand order fulfillment company with facilities worldwide!"
        }
      />
      <ExpansionItem
        expanded={expanded}
        onChange={handleChange}
        name={"panel3"}
        heading={
          "How do you comply with labor, environmental and safety standards?"
        }
        content={
          "We work with a fulfillment company that sources products and fabrics from ethical brands and suppliers that comply to labor, environmental, and safety standards."
        }
      />
      <ExpansionItem
        expanded={expanded}
        onChange={handleChange}
        name={"panel4"}
        heading={
          "What is that lenghty code that appears next to my orders on checkout?"
        }
        content={
          "You will notice a bunch of lines of code that is next to your order on the checkout phase. This is how we keep track of the customizations you have made to the product."
        }
      />
      <ExpansionItem
        expanded={expanded}
        onChange={handleChange}
        name={"panel5"}
        heading={"What if I have more questions?"}
        content={"Feel free to reach us at karakterastore@gmail.com"}
      />
    </Box>
  );
};

export const FaqContent = ({ hasTitle }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (_event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box>
      {hasTitle && (
        <TitleContainer mb={10}>
          <Subtitle>Frequently Asked Questions</Subtitle>
        </TitleContainer>
      )}
      <Box>
        <FaqItems expanded={expanded} handleChange={handleChange} />
      </Box>
    </Box>
  );
};

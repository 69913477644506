import React from "react";
import gql from "graphql-tag";
import { withRouter } from "react-router-dom";
import { useQueryParams, StringParam, JsonParam } from "use-query-params";
import { useQuery } from "@apollo/react-hooks";

import { LayoutWrapper } from "src/components/LayoutWrapper";
import LoadingIcon from "src/components/LoadingIcon";
import { getProductsData } from "./utils";

import DesignCollection from "./DesignCollection";
import { convertSelectedProductOptionsObjToArray } from "./DesignCollection";

export const parseProductOptionsQuery = (query) => {
  return query || {};
};

const DesignCollectionProvider = (props) => {
  const collectionId = props.match.params.designId;
  const [query, setQuery] = useQueryParams({
    productId: StringParam,
    productOptions: JsonParam,
  });
  const { productId, productOptions } = query;

  const selectedProductId = productId || "";
  const selectedVariantFromURL = productOptions || {};

  const variantSelectionPerProduct = parseProductOptionsQuery(
    selectedVariantFromURL
  );
  const variantSelectionForProduct =
    variantSelectionPerProduct[selectedProductId];
  const selectedProductOptionsArr =
    (variantSelectionForProduct &&
      convertSelectedProductOptionsObjToArray(variantSelectionForProduct)) ||
    [];

  const {
    loading: loadingCollectionData,
    error: errorCollectionData,
    data: collectionData,
  } = useQuery(QUERY_COLLECTION_DATA, {
    variables: { id: collectionId },
  });

  const {
    loading: loadingVariantData,
    error: errorVariantData,
    data: variantData,
  } = useQuery(QUERY_VARIANT_DATA, {
    variables: {
      id: selectedProductId,
      selectedOptions: selectedProductOptionsArr,
    },
  });

  let selectedVariant = null;
  if (!loadingVariantData && !errorVariantData && variantData) {
    selectedVariant = variantData.node.variantBySelectedOptions;
  }

  if (loadingCollectionData || !collectionData) {
    return <LoadingIcon />;
  }
  if (errorCollectionData) {
    return <p>{errorCollectionData.message}</p>;
  }

  const collectionDescription = collectionData.node.description;
  const products = getProductsData(collectionData);

  return (
    <LayoutWrapper
      title={"Select a Product"}
      description={"Select a product and then customize the design"}
    >
      <DesignCollection
        collectionDescription={collectionDescription}
        products={products}
        updateURL={setQuery}
        selectedProductId={selectedProductId}
        selectedVariant={selectedVariant}
        variantSelectionPerProduct={variantSelectionPerProduct}
        loading={loadingVariantData}
      />
    </LayoutWrapper>
  );
};

export default withRouter(DesignCollectionProvider);

const ProductFragment = gql`
  fragment ProductFragment on Product {
    id
    title
    handle
    descriptionHtml

    priceRange {
      minVariantPrice {
        amount
        currencyCode
      }
      maxVariantPrice {
        amount
        currencyCode
      }
    }

    options(first: 3) {
      id
      name
      values
    }

    images(first: 10) {
      edges {
        node {
          id
          src
          altText
        }
      }
    }

    variants(first: 50) {
      edges {
        node {
          selectedOptions {
            name
            value
          }
          id
          title
          price
          image {
            altText
            originalSrc
          }
        }
      }
    }
  }
`;

const QUERY_COLLECTION_DATA = gql`
  query getCollectionData($id: ID!) {
    node(id: $id) {
      ... on Collection {
        description
        products(first: 20, sortKey: TITLE) {
          edges {
            node {
              ...ProductFragment
            }
          }
        }
      }
    }
  }
  ${ProductFragment}
`;

const QUERY_VARIANT_DATA = gql`
  query getVariantData($id: ID!, $selectedOptions: [SelectedOptionInput!]!) {
    node(id: $id) {
      id
      ... on Product {
        variantBySelectedOptions(selectedOptions: $selectedOptions) {
          id
          title
          price
          image {
            altText
            originalSrc
          }
        }
      }
    }
  }
`;

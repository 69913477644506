import React, { useState } from "react";
import styled from "styled-components";

import { Box, LazyContainer, Flex, Text } from "src/components/Primitives";
import LoadingIcon from "src/components/LoadingIcon";
import { Carousel } from "./components";

import { border, borderSelected, boxShadow } from "src/styles/mixins";

const Container = styled(Box)`
  border: ${border()};
  box-shadow: ${boxShadow()};
  cursor: pointer;
  display: flex;
  flex-direction: column;

  ${({ selected, onClick }) => {
    return `
      border: ${selected && borderSelected(4)};
      cursor: ${onClick ? "pointer" : "initial"}
    `;
  }};
`;

const MainImageContainer = styled(Box)`
  ${({ onClick }) => {
    return `
      cursor: ${onClick ? "pointer" : "initial"}
    `;
  }};
`;

const TitleContainer = styled(Flex)`
  flex-grow: 1;
`;

export const Product = ({
  imagePath,
  onClick,
  priceRange,
  price,
  selected,
  title,
  images = [],
  controlled,
  collectionDescription,
  fetching = false,
}) => {
  const [loading, setLoading] = useState(true);
  const [mainImagePath, setMainImagePath] = useState(imagePath);

  let productPrice = null;
  if (!price && priceRange) {
    const minAmount = priceRange.min.amount;
    const maxAmount = priceRange.max.amount;
    productPrice =
      minAmount === maxAmount ? `${minAmount}$` : `From ${minAmount}$`;
  } else {
    productPrice = `${price}$`;
  }

  const imagesMarkup = (
    <Carousel
      images={images}
      onClick={(image) => {
        setMainImagePath(image);
      }}
    />
  );

  const handleOnLoad = () => {
    setLoading(false);
  };

  const inProgress = loading || fetching;

  return (
    <Container width={1} p={2} mr={5} mb={[5, 5, 0]} selected={selected}>
      <MainImageContainer onClick={onClick} selected={selected}>
        <LazyContainer pb={"100%"}>
          {inProgress && (
            <LoadingIcon
              height={"auto"}
              fullScreen
              fullScreenPaddingSize={"40%"}
            />
          )}
          {
            <img
              src={controlled ? imagePath : mainImagePath}
              alt={`${title} with ${collectionDescription}`}
              onLoad={handleOnLoad}
              style={{
                display: inProgress ? "none" : "block",
              }}
            />
          }
        </LazyContainer>
      </MainImageContainer>

      <Box py={2}>{imagesMarkup}</Box>

      <TitleContainer pb={2} pl={1} pr={1} justifyContent={"space-between"}>
        <Text fontWeight={"600"}>{title}</Text>
        <Text fontWeight={"400"} textAlign={"right"}>
          {productPrice}
        </Text>
      </TitleContainer>
    </Container>
  );
};

import React, { useEffect } from "react";
import MediaQuery from "react-responsive";
import { Element, Events, scroller } from "react-scroll";
import styled from "styled-components";
import { themeGet } from "styled-system";

import theme, { getBreakPoint } from "src/styles/theme";
import { border } from "src/styles/mixins";
import { getVariantTitle } from "src/utilities/product";

import { Fade } from "src/components/Fade";

import { SelectProduct } from "./components/SelectProduct/";
import { Product } from "./components/Product";
import { SelectedProductParameters } from "./components/SelectedProductParameters";

import { Box, Flex, Text, BodyText } from "src/components/Primitives";
import { createVariantOptionsTable } from "./utils";

const DescriptionText = styled(BodyText)`
  color: ${theme.colors.darkGray};

  & p {
    margin-top: 12px;
  }

  & .table-responsive {
    overflow: scroll;
  }

  & table {
    width: 100%;
    border: ${border()};
    & tr {
      border-bottom: ${border()};
    }
    & td {
      padding: 8px;
    }
  }
`;

const PriceDisplay = styled(Text)`
  ${(props) => {
    return `
      background: ${themeGet("colors.lightGray")(props)};
      padding: 4px;
      border-radius: 4px;
    `;
  }}
`;

function createMarkup(content) {
  return { __html: content };
}

export const ProductSelection = (props) => {
  const {
    children,
    loading,
    onProductClick,
    onVariantSelect,
    products,
    selectedProduct,
    selectedVariant,
    variantOptions,
    collectionDescription,
  } = props;

  useEffect(() => {
    Events.scrollEvent.register("begin", function(_to, _element) {});

    Events.scrollEvent.register("end", function(_to, _element) {});

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  const scrollTo = () => {
    scroller.scrollTo("scroll-to-element", {
      duration: 800,
      delay: 150,
      smooth: "easeInOutQuart",
    });
  };

  const tabletBreakpoint = getBreakPoint("tablet");
  const product = selectedProduct || {};
  const hasSelectedProduct = product && Object.keys(product).length > 0;
  const { descriptionHtml, id, title } = product;
  let price = null;
  if (selectedVariant) {
    price = `${selectedVariant.price}$`;
  }

  useEffect(() => {
    if (hasSelectedProduct) {
      scrollTo();
    }
  }, []);

  const getDescriptionImage = () => {
    if (selectedVariant) {
      return (
        <Product
          imagePath={selectedVariant.image.originalSrc}
          price={selectedVariant.price}
          title={getVariantTitle(selectedVariant.title)}
          controlled
          fetching={loading}
          collectionDescription={collectionDescription}
        />
      );
    }

    if (hasSelectedProduct) {
      return (
        <Product
          imagePath={product.image}
          priceRange={product.priceRange}
          title={product.title}
          controlled
          fetching={loading}
          collectionDescription={collectionDescription}
        />
      );
    }
  };

  const variantOptionsTable = createVariantOptionsTable(selectedProduct);

  const productDescriptionSectionMarkup = id && (
    <Box mb={5}>
      <Flex alignItems={"flex-end"} mb={5}>
        <Text fontSize={3} fontWeight={"600"} mr={3}>
          {title}
        </Text>
        <PriceDisplay fontSize={5} fontWeight={"400"}>
          {price && `${price}`}
        </PriceDisplay>
      </Flex>

      <Box width={1} mb={5}>
        <Flex flexDirection={["column", "column", "row"]}>
          <Box width={[1, 1, 0.4]} mr={10}>
            {getDescriptionImage()}
          </Box>

          <Box width={[1, 1, 0.5]}>
            <Flex flexDirection={"column"}>
              <Box mb={5}>
                <DescriptionText
                  lineHeight={"copy"}
                  fontFamily={"body"}
                  dangerouslySetInnerHTML={createMarkup(descriptionHtml)}
                ></DescriptionText>
              </Box>
              <Box>
                <Flex flexDirection={"column"}>
                  <Box mb={10}>
                    <SelectedProductParameters
                      onChange={onVariantSelect}
                      allOptions={product.options}
                      selectionState={variantOptions}
                      variantOptionsTable={variantOptionsTable}
                    />
                  </Box>

                  <Box>{children}</Box>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Box>
  );

  return (
    <MediaQuery minWidth={tabletBreakpoint}>
      {(matches) => {
        const isDesktop = matches;
        return (
          <Box>
            <Text fontSize={3} fontWeight={"900"} mb={10}>
              Step 1: Select a Product
            </Text>

            <Fade>
              <>
                <Box mb={12}>
                  <SelectProduct
                    onClick={(val) => {
                      scrollTo();
                      onProductClick(val);
                    }}
                    selectedProductId={id}
                    products={products}
                    displayChildren={!isDesktop}
                    collectionDescription={collectionDescription}
                  >
                    <Element name="scroll-to-element">
                      {productDescriptionSectionMarkup}
                    </Element>
                  </SelectProduct>
                </Box>
                {isDesktop && (
                  <Element name="scroll-to-element">
                    {productDescriptionSectionMarkup}
                  </Element>
                )}
              </>
            </Fade>
          </Box>
        );
      }}
    </MediaQuery>
  );
};

import React, { Component } from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";

import { CartFooter, CartLineItems, EmptyCartMessage } from "./Cart.components";
import { Button } from "src/components/Button";
import { Drawer } from "src/components/Drawer";
import LineItem from "../LineItem";

import LoadingIcon from "src/components/LoadingIcon";

const LoadingIconContainer = styled.div`
  position: relative;
`;

class Cart extends Component {
  render() {
    const { checkoutUpdating } = this.props;
    const {
      lineItems: _lineItems,
      subtotalPrice,
      totalTax,
      totalPrice,
    } = this.props.checkout;

    if (checkoutUpdating) {
      return <LoadingIcon />;
    }

    const lineItems = _lineItems.edges.map((lineItem) => {
      return (
        <LineItem
          removeLineItemInCart={this.props.removeLineItemInCart}
          updateLineItemInCart={this.props.updateLineItemInCart}
          key={lineItem.node.id.toString()}
          line_item={lineItem.node}
        />
      );
    });

    const isCartEmpty = lineItems.length === 0;
    const cartContent = isCartEmpty ? (
      EmptyCartMessage
    ) : (
      <React.Fragment>
        <CartLineItems>{lineItems}</CartLineItems>
        <CartFooter subtotal={subtotalPrice} tax={totalTax} total={totalPrice}>
          <Link to={"/review"}>
            <Button fullWidth type={"pink"}>
              Review Order
            </Button>
          </Link>
        </CartFooter>
      </React.Fragment>
    );

    return (
      <Drawer
        isOpen={this.props.isCartOpen}
        handleClose={this.props.handleCartClose}
        title={"Cart"}
      >
        {checkoutUpdating ? (
          <LoadingIconContainer>
            <LoadingIcon />
          </LoadingIconContainer>
        ) : (
          cartContent
        )}
      </Drawer>
    );
  }
}

export default Cart;

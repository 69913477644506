import React from "react";
import styled from "styled-components";
import truncate from "lodash/truncate";
import { themeGet } from "styled-system";
import { Link } from "react-router-dom";

import { CopyText, BodyTitle, Box } from "src/components/Primitives";
import { border } from "src/styles/mixins";

const Container = styled(Box)`
  border: ${border()};
`;

const ImageContainer = styled(Box)`
  display: block;
  position: relative;
  width: 100%;
  padding-top: 100%;
  background: rgb(255, 255, 255);
`;

const Image = styled.img`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
`;

const TextContainer = styled(Box)``;

const Description = styled(CopyText)``;

const Title = styled(BodyTitle)`
  text-align: left;
  cursor: pointer;
`;

const Tags = styled(CopyText)`
  ${(props) => {
    return `color: ${themeGet("colors.midGray")(props)};`;
  }}
`;

export const Article = ({ data }) => {
  console.log(data);

  const title = data.title;
  const content = data.content;
  const description = truncate(content, { length: 100 });
  const tags = data.tags.join(", ");
  const path = `/blog/${data.handle}`;

  return (
    <Container>
      <ImageContainer>
        <Link to={path}>
          <Image src={data.image.originalSrc} alt={data.image.altText} />
        </Link>
      </ImageContainer>
      <TextContainer p={5}>
        <Tags mb={4} fontSize={7}>
          {tags}
        </Tags>
        <Link to={path}>
          <Title>{title}</Title>
        </Link>
        <Description pb={10}>{description}</Description>
      </TextContainer>
    </Container>
  );
};

import * as artwork from "src/artwork";

export const idToName = {
  "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1Njk3NTMzMzM5OA==": "busReference",
  "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1Njk2ODI4ODI3OA==": "fabricateModule",
  "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1Njk3NTQzMTcwMg==": "leaseDetector",
  "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1Njk3NTEwNDAyMg==": "miracleClimate",
  "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1Njk3NDg0MTg3OA==": "particularFever",
  "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1Njk3NTAwNTcxOA==":
    "particularFeverEnneagram",
  "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1Njk3NDc3NjM0Mg==": "productionNap",
  "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1Njk3NTY2MTA3OA==": "productionNapZodiac",
  "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1Njk2NzgyOTUyNg==": "volumePatent",
};

export default {
  busReference: {
    config: artwork.busReferenceConfig,
    sketch: artwork.busReference,
  },
  fabricateModule: {
    config: artwork.fabricateModuleConfig,
    sketch: artwork.fabricateModule,
  },
  leaseDetector: {
    config: artwork.leaseDetectorConfig,
    sketch: artwork.leaseDetector,
  },
  miracleClimate: {
    config: artwork.miracleClimateConfig,
    sketch: artwork.miracleClimate,
  },
  particularFever: {
    config: artwork.particularFeverConfig,
    sketch: artwork.particularFever,
  },
  particularFeverEnneagram: {
    config: artwork.particularFeverEnneagramConfig,
    sketch: artwork.particularFeverEnneagram,
  },
  productionNap: {
    config: artwork.productionNapConfig,
    sketch: artwork.productionNap,
  },
  productionNapZodiac: {
    config: artwork.productionNapZodiacConfig,
    sketch: artwork.productionNapZodiac,
  },
  volumePatent: {
    config: artwork.volumePatentConfig,
    sketch: artwork.volumePatent,
  },
};

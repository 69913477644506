import styled from "styled-components";

import { Box } from "src/components/Primitives";

export const TitleContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

import { random } from "canvas-sketch-util";
import { lerp } from "canvas-sketch-util/math";

export function createCircleFrame({
  p,
  originX,
  originY,
  width,
  height,
  radius
}) {
  for (let i = 0; i < 360; i += 0.5) {
    p.push();
    p.translate(originX, originY);
    p.rotate(i);

    p.strokeWeight(20);
    p.line(radius, 0, width / 2, height / 2);
    p.pop();
  }
}

export function createRandomSizedCircles({
  p,
  circleAmount,
  originX,
  originY,
  radius,
  minCircleSize,
  maxCircleSize
}) {
  for (let i = 0; i < circleAmount; i++) {
    const randomXpos = random.value();
    const randomYPos = random.value();
    const randomCircleSize = random.value();

    const positionX = lerp(originX - radius, originX + radius, randomXpos);
    const positionY = lerp(originY - radius, originY + radius, randomYPos);
    const circleSize = lerp(minCircleSize, maxCircleSize, randomCircleSize);

    p.push();
    p.ellipse(positionX, positionY, circleSize);
    p.pop();
  }
}

import { random } from "canvas-sketch-util";
import colors from "nice-color-palettes";

import {
  exists,
  getConfigValueIfUndefined,
  getValueIfUndefined,
} from "../../utilities/misc";
import tinyColor from "../../utilities/tinycolor";

import {
  createCircleFrame,
  createRandomSizedCircles,
} from "../../utilities/shape";
import { signs } from "../../utilities/westernAstrology";
import * as constellationSvgs from "../../utilities/westernAstrology/constellationSvgs02";
import * as zodiacSignSvgs from "../../utilities/westernAstrology/zodiacSignSvgs04";
import Montserrat from "../../assets/fonts/Montserrat-ExtraBoldItalic.ttf";

export const config = [
  {
    name: "category1",
    type: "category",
    label: "Main Controls",
  },
  {
    name: "category2",
    type: "category",
    label: "Sphere Controls",
  },
  {
    name: "category3",
    type: "category",
    label: "Color Controls",
  },
  {
    name: "seedValue",
    type: "scalar",
    min: 0,
    max: 9999,
    defaultValue: 1558,
    label: "Seed Value",
    category: "category1",
  },
  {
    name: "sign",
    type: "select",
    defaultValue: Object.values(signs)[0].name,
    options: Object.values(signs).map((item) => item.name),
    label: "Sign",
    category: "category1",
  },
  {
    name: "useZodiacSymbols",
    type: "boolean",
    defaultValue: false,
    label: "Use Zodiac Symbols",
    category: "category1",
  },
  {
    name: "circleAmount",
    type: "scalar",
    min: 1,
    max: 500,
    defaultValue: 200,
    label: "Circle Amount",
    category: "category2",
  },
  {
    name: "minCircleSize",
    type: "float",
    min: 0,
    max: 10,
    defaultValue: 3,
    label: "Min. Circle Size",
    category: "category2",
  },
  {
    name: "maxCircleSize",
    type: "float",
    min: 0,
    max: 10,
    defaultValue: 8,
    label: "Max. Circle Size",
    category: "category2",
  },

  {
    name: "fgColor",
    type: "color",
    defaultValue: "#b8d1db",
    label: "Foreground Color",
    category: "category3",
  },
  {
    name: "bgColor",
    type: "color",
    defaultValue: "#262a57",
    label: "Background Color",
    category: "category3",
  },
];

const sketch = (p) => {
  const givenState = document.p5jsState;
  const state = givenState || {};
  const configFromState = state.configuration;

  let seedValue = getConfigValueIfUndefined(
    config,
    configFromState,
    "seedValue"
  );

  let sign = getConfigValueIfUndefined(config, configFromState, "sign");
  sign = sign.toLowerCase();

  let useZodiacSymbols = getConfigValueIfUndefined(
    config,
    configFromState,
    "useZodiacSymbols"
  );

  let circleAmount = getConfigValueIfUndefined(
    config,
    configFromState,
    "circleAmount"
  );
  let minCircleSize = getConfigValueIfUndefined(
    config,
    configFromState,
    "minCircleSize"
  );
  let maxCircleSize = getConfigValueIfUndefined(
    config,
    configFromState,
    "maxCircleSize"
  );
  let fgColor = getConfigValueIfUndefined(config, configFromState, "fgColor");
  let bgColor = getConfigValueIfUndefined(config, configFromState, "bgColor");

  let screenSizeX = getValueIfUndefined(state, "screenSizeX", 1024);
  let screenSizeY = getValueIfUndefined(state, "screenSizeY", 1024);
  let pixelDensity = getValueIfUndefined(state, "pixelDensity", 0.5);

  p.myCustomRedrawAccordingToNewPropsHandler = (props) => {
    if (exists(props.seedValue)) {
      seedValue = parseInt(props.seedValue, 10);
    }

    if (exists(props.sign)) {
      sign = props.sign.toLowerCase();
    }

    if (exists(props.useZodiacSymbols)) {
      useZodiacSymbols = props.useZodiacSymbols;
    }

    if (exists(props.circleAmount)) {
      circleAmount = parseInt(props.circleAmount, 10);
    }

    if (exists(props.minCircleSize)) {
      minCircleSize = parseInt(props.minCircleSize, 10);
    }

    if (exists(props.maxCircleSize)) {
      maxCircleSize = parseInt(props.maxCircleSize, 10);
    }

    if (exists(props.fgColor)) {
      fgColor = props.fgColor;
    }

    if (exists(props.bgColor)) {
      bgColor = props.bgColor;
    }
  };

  let constellationIcons = {};
  let zodiacIcons = {};
  let myFont;

  p.preload = () => {
    myFont = p.loadFont(Montserrat);
    Object.keys(constellationSvgs).forEach((constellationSvg) => {
      console.log(constellationSvgs[constellationSvg]);
      constellationIcons[constellationSvg] = p.loadImage(
        constellationSvgs[constellationSvg]
      );
    });

    Object.keys(zodiacSignSvgs).forEach((zodiacSignSvg) => {
      console.log(zodiacSignSvgs[zodiacSignSvg]);
      zodiacIcons[zodiacSignSvg] = p.loadImage(zodiacSignSvgs[zodiacSignSvg]);
    });
  };

  p.setup = () => {
    p.createCanvas(screenSizeX, screenSizeY);
    p.rectMode(p.CENTER);
    p.angleMode(p.DEGREES);
    p.frameRate(6);
    p.pixelDensity(pixelDensity);
    p.imageMode(p.CENTER);
    p.textAlign(p.CENTER, p.CENTER);
  };

  p.draw = () => {
    random.setSeed(seedValue);
    p.background(bgColor);

    const width = p.width;
    const height = p.height;
    const originX = width / 2;
    const originY = height / 2;

    const imageSize = 600;
    const circleSize = imageSize * 1.3;
    const imageYPos = originY * 0.8;
    const image = constellationIcons[sign];
    const textYPos = originY * 1.5;

    p.textFont(myFont);
    p.tint(fgColor);

    const zodiacIconsScale = 0.7;
    if (useZodiacSymbols) {
      p.image(
        zodiacIcons[sign],
        originX,
        imageYPos,
        imageSize * zodiacIconsScale,
        imageSize * zodiacIconsScale
      );
    } else {
      p.image(image, originX, imageYPos, imageSize, imageSize);
    }

    const newColor = tinyColor(fgColor)
      .setAlpha(0.75)
      .toRgbString();

    // random sized circles
    p.fill(newColor);
    p.noStroke();
    createRandomSizedCircles({
      p,
      circleAmount,
      originX,
      originY: imageYPos,
      radius: circleSize / 2,
      minCircleSize,
      maxCircleSize,
    });

    // circle frame
    p.stroke(fgColor);
    createCircleFrame({
      p,
      originX,
      originY: imageYPos,
      width: width * 1.2,
      height: height * 1.2,
      radius: circleSize * 0.5,
    });

    // main sign text
    p.push();
    p.fill(bgColor);
    p.noStroke();
    p.textSize(100);
    p.text(signs[sign].name.toUpperCase(), originX, textYPos);
    p.pop();
  };
};

export default sketch;

import styled from "styled-components";

import { Box } from "src/components/Primitives";

export const ControllerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SlidersContainer = styled(Box)`
  ${props => {
    if (props.mainPage) {
      return ``;
    }
    return `
      width: 100%;
    `;
  }}
`;

export const SliderContainer = styled(Box)`
  margin-bottom: 12px;
`;

export const SliderLabel = styled(Box)`
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
`;

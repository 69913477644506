import React from "react";
import styled from "styled-components";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { Box, Card, BodyText } from "src/components/Primitives";
import { border, borderRadius, boxShadow } from "src/styles/mixins";

export { Link } from "src/components/Link";

export const CustomCard = styled(Card)`
  user-select: none;
  border: ${border()};
  border-radius: ${borderRadius()};
  height: 100%;
  box-shadow: ${boxShadow()};
`;

export const ImageContainer = styled(Box)``;

export const Image = styled.img`
  ${({ isVisible }) => {
    if (!isVisible) {
      return `display: none;`;
    }
  }}
`;

const IconContainer = styled(Box)`
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: black;
  background: rgba(255, 255, 255, 0.25);
  ${({ right }) => {
    if (right) {
      return `right: 0;`;
    }
  }}

  transition: all 0.2s;
  cursor: pointer;

  ${({ isVisible = false }) => {
    return `opacity: ${isVisible ? "100%" : "0"};`;
  }}
`;

export const IconLeftContainer = (props) => {
  return (
    <IconContainer {...props}>
      <ArrowBackIosIcon fontSize="small" />
    </IconContainer>
  );
};
export const IconRightContainer = (props) => {
  return (
    <IconContainer right {...props}>
      <ArrowForwardIosIcon fontSize="small" />
    </IconContainer>
  );
};

export const ImageCardContainer = styled(Box)`
  display: flex;
  flex-direction: "column";
  justify-content: "space-between";
  position: relative;
  align-items: center;

  transition: all 0.2s;
  cursor: pointer;

  ${({ animation = true }) => {
    if (animation) {
      return `
      &:hover div {
          opacity: 100%;
      }
      `;
    }
  }}
`;

export const DescriptionContainer = styled(Text)``;

export const TextContainer = styled(BodyText)`
  padding: 8px;
  font-size: 14px;
`;

export const ProductTypeContainer = styled(Box)`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 12px;
  bottom: 0;
`;

const ProductTypeImageContainer = styled(Box)`
  display: inline-block;
  padding: 4px;
  width: 28px;
  height: 28px;

  > img {
    opacity: 0.5;
  }
`;

export const ProductTypeImage = ({ alt, src }) => {
  return (
    <ProductTypeImageContainer>
      <img src={src} alt={alt} />
    </ProductTypeImageContainer>
  );
};

import React from "react";
import styles from "styled-components";
import { useMediaQuery } from "react-responsive";

import { getBreakPoint } from "src/styles/theme";
import { Box } from "src/components/Primitives";
import { MaxWidthContainer } from "src/components/MaxWidthContainer";

import InfoCard from "./InfoCard";

import { imagePaths } from "src/images";

const Container = styles(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  ${({ isDesktop }) => {
    if (isDesktop) {
      return `
        grid-template-columns: 1fr 1fr 1fr 1fr;
      `;
    }
  }}
`;

export const InfoSection = () => {
  const mobileBreakpoint = getBreakPoint("mobile");
  const isLargerThanMobile = useMediaQuery({
    query: `(min-width: ${mobileBreakpoint}px)`,
  });

  return (
    <MaxWidthContainer>
      <Container isDesktop={isLargerThanMobile}>
        <InfoCard
          title={"Unique and Customizable"}
          description="We have designs that can be customized for your personality type (like Enneagram) or your zodiac sign."
          path={imagePaths.mockupToteBag}
          first
          altText={
            "Women holding a bag with Type 1 Enneagram Personality Type printed on it."
          }
        />
        <InfoCard
          title={"On your favorite Products"}
          description="Our designs can be printed on a wide range of products such as pillows, bags and tshirts."
          path={imagePaths.mockupPillow}
          last
          altText={
            "Woman holding a pillow with Capricorn symbol and constellation"
          }
        />
        <InfoCard
          title={"Exceptional Quality"}
          description="Products and fabrics from ethical brands and suppliers that comply to labor, environmental, and safety standards."
          path={imagePaths.mockupMug}
          altText={
            "Man wearing a T-Shirt with Type 3, The Achiever Enneagram Personality Type printed on it"
          }
        />
        <InfoCard
          title={"Worldwide Shipping"}
          description="We ship worldwide from locations in Europe and North America."
          path={imagePaths.mockupPoster}
          altText={"Woman holding a poster with Aries symbol"}
        />
      </Container>
    </MaxWidthContainer>
  );
};

import React from "react";
import styled from "styled-components";
import { Box, LazyContainer } from "src/components/Primitives";
import { DesignController } from "src/components/DesignController";
import { signs } from "src/artwork/utilities/westernAstrology";

import { border } from "src/styles/mixins";

export { leaseDetectorHero as sketch } from "src/artwork/";

export { LazyContainer };

export const HeroImageContainer = styled(Box)``;

export const HeroContainer = styled(Box)``;

export const CustomDesignContainer = styled(Box)`
  position: absolute;
  width: 26.9%;
  height: 54.5%;
  top: 23.5%;
  left: 36.5%;
  font-size: 0;

  ${({ isMobile }) => {
    if (isMobile) {
      return `
      margin-top: 8px;
      margin-bottom: 20px;
      position: unset;
      width: 100%;
    `;
    }
  }}

  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const HeroSectionContainer = styled(Box)`
  position: relative;
`;

export const TitleContainer = styled(Box)`
  position: absolute;
  width: 100%;
  padding: 24px;
  text-align: center;
  z-index: 2;

  ${({ isMobile }) => {
    if (isMobile) {
      return `
      text-align: left;
      position: relative;
      padding: 12px;
      padding-top: 20px;
    `;
    }
  }}

  ${({ isTablet }) => {
    if (isTablet) {
      return `
        position: relative;
        padding: 24px;
      `;
    }
  }};
`;

export const DesignControllerContainer = styled(Box)`
  justify-content: center;
  display: flex;
  align-items: center;
  z-index: 20;
  margin-top: -80px;
  ${({ isMobile, isTablet }) => {
    if (isMobile) {
      return `margin-top: 0;`;
    }

    if (isTablet) {
      return `margin-top: -30px`;
    }
  }}
`;

export const DesignControllerContainerInner = styled(Box)`
  z-index: 2;
  border: ${border(2)};
`;

export const DesignControllerMainPage = ({ config, getState }) => {
  return (
    <DesignController
      stateCallback={(state) => {
        getState(state);
      }}
      initialState={config}
      configuration={config}
      mainPage
      useTabs
    />
  );
};

export const sketchConfig = [
  {
    name: "category1",
    type: "category",
    label: "Text Controls",
  },
  {
    name: "category2",
    type: "category",
    label: "Shape Controls",
  },
  {
    name: "sign",
    type: "select",
    defaultValue: Object.values(signs)[0].name,
    options: Object.values(signs).map((item) => item.name),
    label: "Zodiac Sign",
    category: "category1",
  },
  {
    name: "useZodiacSymbols",
    type: "boolean",
    defaultValue: false,
    label: "Use Zodiac Symbols",
    category: "category1",
  },
  {
    name: "circleAmount",
    type: "scalar",
    min: 1,
    max: 1000,
    defaultValue: 200,
    label: "Circle Amount",
    category: "category2",
  },
  {
    name: "minCircleSize",
    type: "float",
    min: 0,
    max: 20,
    defaultValue: 3,
    label: "Min. Circle Size",
    category: "category2",
  },
  {
    name: "maxCircleSize",
    type: "float",
    min: 0,
    max: 20,
    defaultValue: 8,
    label: "Max. Circle Size",
    category: "category2",
  },
];

import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import LoadingIcon from "src/components/LoadingIcon";
import { BlogPage } from "./BlogPage";

export const BlogPageProvider = () => {
  const { loading, error, data } = useQuery(query);

  if (loading) {
    return <LoadingIcon />;
  }

  if (error) {
    // @TODO Add a something went wrong message here
    return <p>{error.message}</p>;
  }

  const pageData = data.blogByHandle.articleByHandle;

  return <BlogPage data={pageData} />;
};

const query = gql`
  query blog {
    blogByHandle(handle: "blog") {
      id
      articleByHandle(handle: "test-post") {
        id
        contentHtml
        tags
        title
        image {
          altText
          originalSrc
        }
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { Box, Flex, SrOnly } from "src/components/Primitives";
import { MaxWidthContainer } from "src/components/MaxWidthContainer";
import { Button } from "src/components/Button";
import { FaqContent } from "src/components/FaqContent";

import { ProductSelection } from "src/sections/ProductSelection";

import { scrollToTop } from "src/utilities/misc";

export const convertSelectedProductOptionsStringToArray = (
  selectedProductOptionsStr
) => {
  const result =
    selectedProductOptionsStr &&
    selectedProductOptionsStr.split(",").map((item) => {
      const [name, value] = item.split(":");
      return {
        name,
        value,
      };
    });

  return result;
};

export const convertSelectedProductOptionsObjToArray = (
  selectedProductOptionsObj
) => {
  return Object.keys(selectedProductOptionsObj).map((key) => {
    const value = selectedProductOptionsObj[key];
    return {
      name: key,
      value,
    };
  });
};

const DesignCollection = (props) => {
  useEffect(() => {
    scrollToTop();
  }, []);

  const [state_selectedProductId, state_setSelectedProductId] = useState("");

  function getProductById(products, id) {
    if (!products) {
      return null;
    }

    const selectedProduct = products.find((product) => product.id === id);
    return selectedProduct || null;
  }

  function getCurrentVariant(variantSelectionPerProduct, selectedProductId) {
    return variantSelectionPerProduct[selectedProductId] || {};
  }

  function getCurrentVariantString(
    variantSelectionPerProduct,
    selectedProductId
  ) {
    const getCurrentVariantArray = () => {
      const variantOptions = getCurrentVariant(
        variantSelectionPerProduct,
        selectedProductId
      );

      return Object.keys(variantOptions).map((name) => {
        const value = variantOptions[name];

        return {
          name,
          value,
        };
      });
    };

    const variantOptionsArr = getCurrentVariantArray();
    const result = variantOptionsArr.reduce((acc, curr) => {
      const { name, value } = curr;
      const strCurr = `${name}:${value}`;

      return acc === "" ? strCurr : `${strCurr},${acc}`;
    }, "");

    return result;
  }

  function handleSelectedVariant(selectedProductId, optionName, optionValue) {
    const variantSelectionPerProduct = {
      ...props.variantSelectionPerProduct,
    };

    if (!variantSelectionPerProduct[selectedProductId]) {
      variantSelectionPerProduct[selectedProductId] = {};
    }

    variantSelectionPerProduct[selectedProductId][optionName] = optionValue;

    return variantSelectionPerProduct;
  }

  function validateProductOptionSelection(
    selectedProduct,
    variantSelectionPerProduct,
    selectedProductId
  ) {
    const hasProductSelection = Boolean(selectedProductId);
    if (!hasProductSelection) {
      return false;
    }

    const availableVariantOptionNames = selectedProduct.options.map(
      (optionObj) => optionObj.name
    );
    const variantOptions = getCurrentVariant(
      variantSelectionPerProduct,
      selectedProductId
    );
    // check to see if we have captured the value for every available option for the given product;
    return availableVariantOptionNames.every((name) =>
      Object.keys(variantOptions).includes(name)
    );
  }

  const {
    loading,
    products,
    history,
    match,
    location,
    updateURL,
    selectedProductId,
    selectedVariant,
    variantSelectionPerProduct,
    collectionDescription,
  } = props;

  const collectionId = match.params.designId;
  const designType = match.params.designType;

  const selectedProduct = getProductById(products, selectedProductId);
  const productVariantOptions = getCurrentVariant(
    variantSelectionPerProduct,
    selectedProductId
  );

  const isValid = validateProductOptionSelection(
    selectedProduct,
    variantSelectionPerProduct,
    selectedProductId
  );

  return (
    <Box px={[10]} pt={10} pb={20} position={"relative"}>
      <SrOnly>{collectionDescription}</SrOnly>
      <MaxWidthContainer>
        <Box>
          <ProductSelection
            collectionDescription={collectionDescription}
            loading={loading}
            products={products}
            selectedProduct={selectedProduct}
            selectedVariant={selectedVariant}
            variantOptions={productVariantOptions}
            onProductClick={(selectedProductId) => {
              state_setSelectedProductId(selectedProductId);
              updateURL({
                productId: selectedProductId,
              });
            }}
            onVariantSelect={(optionName, selectedValue) => {
              const variantSelectionPerProduct = handleSelectedVariant(
                selectedProductId,
                optionName,
                selectedValue
              );

              updateURL({
                productOptions: variantSelectionPerProduct,
              });
            }}
          >
            {" "}
            <Button
              type={"pink"}
              disabled={!isValid}
              onClick={() => {
                if (isValid) {
                  history.push(
                    `/customize/${designType}/${collectionId}${location.search}`
                  );
                }
              }}
            >
              NEXT: CUSTOMIZE
            </Button>
          </ProductSelection>
        </Box>
        <Flex pt={20} justifyContent={"center"}>
          <Box width={[1, 1, 0.65]}>
            <FaqContent hasTitle />
          </Box>
        </Flex>
      </MaxWidthContainer>
    </Box>
  );
};

export default withRouter(DesignCollection);

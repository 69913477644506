import React from "react";
import { useLocation } from "react-router-dom";

import Layout from "src/components/Layout";
import * as AppContext from "src/App.context";

const getParsedPathname = (pathname) => {
  if (pathname === "/") {
    return "";
  }

  if (pathname.includes("/shop")) {
    return "Shop";
  }

  if (pathname.includes("/about")) {
    return "About";
  }

  if (pathname.includes("/design")) {
    return "Design";
  }

  if (pathname.includes("/customize")) {
    return "Customize";
  }
};

export const LayoutWrapper = ({ children, ...props }) => {
  const location = useLocation();
  const title = getParsedPathname(location.pathname);

  return (
    <AppContext.Consumer>
      {(appContext) => {
        const {
          checkout,
          checkoutUpdating,
          removeLineItemInCart,
          updateLineItemInCart,
        } = appContext;

        const layoutProps = {
          checkout,
          checkoutUpdating,
          title,
          removeLineItemInCart,
          updateLineItemInCart,
          ...props,
        };

        return (
          <Layout {...layoutProps}>
            {() => {
              return children;
            }}
          </Layout>
        );
      }}
    </AppContext.Consumer>
  );
};

import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import { Button } from "src/components/Button";
import { MaxWidthContainer } from "src/components/MaxWidthContainer";
import { MainTitle, Page } from "src/components/Primitives";
import { getBreakPoint } from "src/styles/theme";
import { scrollToTop } from "src/utilities/misc";

import {
  CartFooter,
  EmptyCartMessage,
  TitleContainer,
  ButtonContainer,
} from "./ReviewYourOrder.components";

import { LineItem, LineItems } from "./components/LineItem";

export const ReviewYourOrder = ({
  checkout,
  handleOpenCheckout,
  removeLineItemInCart,
  updateLineItemInCart,
}) => {
  const {
    lineItems: _lineItems,
    subtotalPrice,
    totalTax,
    totalPrice,
  } = checkout;

  useEffect(() => {
    scrollToTop();
  }, []);

  const tabletBreakpoint = getBreakPoint("tablet");
  const isLargerThanMobile = useMediaQuery({
    query: `(min-width: ${tabletBreakpoint}px)`,
  });
  const isMobile = !isLargerThanMobile;

  const lineItems = _lineItems.edges.map((lineItem) => {
    return (
      <LineItem
        removeLineItemInCart={removeLineItemInCart}
        updateLineItemInCart={updateLineItemInCart}
        key={lineItem.node.id.toString()}
        lineItem={lineItem.node}
        isMobile={isMobile}
      />
    );
  });

  const isCartEmpty = lineItems.length === 0;

  const cartContent = isCartEmpty ? (
    EmptyCartMessage
  ) : (
    <React.Fragment>
      <LineItems isMobile={isMobile}>{lineItems}</LineItems>
      <CartFooter subtotal={subtotalPrice} tax={totalTax} total={totalPrice}>
        <ButtonContainer>
          <Button type={"pink"} onClick={handleOpenCheckout}>
            Checkout
          </Button>
        </ButtonContainer>
      </CartFooter>
    </React.Fragment>
  );

  return (
    <Page>
      <MaxWidthContainer>
        <TitleContainer>
          <MainTitle>Shopping Cart</MainTitle>
        </TitleContainer>
        {cartContent}
      </MaxWidthContainer>
    </Page>
  );
};

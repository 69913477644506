import React, { useState } from "react";
import styled from "styled-components";

import { Box } from "src/components/Primitives";
import { border } from "src/styles/mixins";

const Container = styled(Box)`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 8px 0;
  grid-gap: 4px;
  position: relative;
`;

const CarouselImage = styled.img`
  border: ${border()};
  cursor: pointer;
  opacity: 0.5;

  ${({ selected, onClick }) => {
    return `
      cursor: ${onClick ? "pointer" : "initial"};
      ${selected && `opacity: 1;`}
    `;
  }};
`;

export const Carousel = ({ images, onClick }) => {
  const [selectionIndex, setSelectionIndex] = useState(0);

  return (
    <Container>
      {images.map((image, index) => {
        return (
          <CarouselImage
            src={image}
            alt=""
            key={image}
            onClick={() => {
              onClick(image);
              setSelectionIndex(index);
            }}
            selected={index === selectionIndex}
          />
        );
      })}
    </Container>
  );
};

import { random } from "canvas-sketch-util";
import {
  exists,
  getConfigValueIfUndefined,
  getValueIfUndefined,
} from "../../utilities/misc";
import { dateConverter, signs } from "../../utilities/westernAstrology";
import * as constellationSvgs from "../../utilities/westernAstrology/constellationSvgs";
import {
  createCircleFrame,
  createRandomSizedCircles,
} from "../../utilities/shape";
import Montserrat from "../../assets/fonts/Montserrat-Thin.ttf";

export const config = [
  {
    name: "category1",
    type: "category",
    label: "Main Controls",
  },
  {
    name: "category2",
    type: "category",
    label: "Color Controls",
  },
  {
    name: "sign",
    type: "select",
    defaultValue: Object.values(signs)[0].name,
    options: Object.values(signs).map((item) => item.name),
    label: "Sign",
    category: "category1",
  },
  {
    name: "enableDate",
    type: "boolean",
    defaultValue: true,
    label: "Enable Date",
    category: "category1",
  },
  {
    name: "fgColor",
    type: "color",
    defaultValue: "#000000",
    label: "Foreground Color",
    category: "category2",
  },
  {
    name: "bgColor",
    type: "color",
    defaultValue: "#cfdde4",
    label: "Background Color",
    category: "category2",
  },
];

const sketch = (p) => {
  const givenState = document.p5jsState;
  const state = givenState || {};
  const configFromState = state.configuration;

  let sign = getConfigValueIfUndefined(config, configFromState, "sign");
  sign = sign.toLowerCase();

  let enableDate = getConfigValueIfUndefined(
    config,
    configFromState,
    "enableDate"
  );

  let fgColor = getConfigValueIfUndefined(config, configFromState, "fgColor");
  let bgColor = getConfigValueIfUndefined(config, configFromState, "bgColor");

  let screenSizeX = getValueIfUndefined(state, "screenSizeX", 1024);
  let screenSizeY = getValueIfUndefined(state, "screenSizeY", 1024);
  let pixelDensity = getValueIfUndefined(state, "pixelDensity", 2);

  p.myCustomRedrawAccordingToNewPropsHandler = (props) => {
    if (exists(props.sign)) {
      sign = props.sign.toLowerCase();
    }

    if (exists(props.enableDate)) {
      enableDate = props.enableDate;
    }

    if (exists(props.fgColor)) {
      fgColor = props.fgColor;
    }

    if (exists(props.bgColor)) {
      bgColor = props.bgColor;
    }
  };

  let signIcons = {};
  let myFont;

  p.preload = () => {
    myFont = p.loadFont(Montserrat);
    Object.keys(constellationSvgs).forEach((constellationSvg) => {
      console.log(constellationSvgs[constellationSvg]);
      signIcons[constellationSvg] = p.loadImage(
        constellationSvgs[constellationSvg]
      );
    });
  };

  p.setup = () => {
    p.createCanvas(screenSizeX, screenSizeY);
    p.pixelDensity(pixelDensity);
    p.rectMode(p.CENTER);
    p.imageMode(p.CENTER);
    p.angleMode(p.DEGREES);
    p.textAlign(p.CENTER, p.CENTER);
  };

  p.draw = () => {
    p.background(bgColor);
    random.setSeed(1);

    const width = p.width;
    const height = p.height;
    const originX = width / 2;
    const originY = height / 2;

    const imageSize = 400;
    const circleSize = imageSize * 1.3;
    const imageYPos = originY * 0.8;
    const textYPos = originY * 1.5;
    const textDateYPos = textYPos * 1.125;

    p.tint(fgColor);
    p.textFont(myFont);

    p.push();

    // stars
    p.push();
    const circleAmount = 100;
    const minCircleSize = 3;
    const maxCircleSize = 6;

    p.noStroke();
    p.fill(fgColor);

    createRandomSizedCircles({
      p,
      circleAmount,
      originX,
      originY: imageYPos,
      radius: circleSize * 0.5,
      minCircleSize,
      maxCircleSize,
    });
    p.pop();
    // stars end

    // constellation
    p.image(signIcons[sign], originX, imageYPos, imageSize, imageSize);
    // constellation end

    // circle surrounding the constellation
    p.push();
    p.stroke(fgColor);
    createCircleFrame({
      p,
      originX,
      originY: imageYPos,
      width: width * 1.2,
      height: height * 1.2,
      radius: circleSize * 0.496,
    });

    p.stroke(bgColor);
    createCircleFrame({
      p,
      originX,
      originY: imageYPos,
      width: width * 1.2,
      height: height * 1.2,
      radius: circleSize * 0.5,
    });
    p.pop();
    // circle surrounding the constellation end

    // main sign text
    p.fill(fgColor);
    p.textSize(100);
    p.text(signs[sign].name.toUpperCase(), originX, textYPos);
    // main sign text end

    // date text
    if (enableDate) {
      p.textSize(48);
      const date01 = dateConverter(signs[sign].minDate);
      const date02 = dateConverter(signs[sign].maxDate);
      const date = `${date01} - ${date02}`;
      p.text(date, originX, textDateYPos);
    }
    // date text end
  };
};

export default sketch;

import styled from "styled-components";

import { Box } from "src/components/Primitives";

import { border } from "src/styles/mixins";

export const DesignDisplayContainer = styled(Box)`
  ${({ styleOverride = {} }) => {
    return `
      display: flex;
      justify-content: center;
      flex-grow: 1;
      background: ${
        styleOverride.background ? styleOverride.background : "#f4f4f7"
      }; 
    `;
  }}
`;

export const DesignDisplayInnerContainer = styled(Box)`
  max-width: 560px;
`;

export const DesignControllerContainer = styled(Box)`
  ${({ isMobile, styleOverride }) => {
    return `
        height: ${isMobile ? "100%" : "unset"};
        background: white;
        max-width: ${isMobile ? "100%" : "460px"};
        border-left: ${
          styleOverride.borderLeft ? styleOverride.borderLeft : border()
        };
        overflow-y: auto;
        overflow-x: hidden;
        width: ${isMobile ? "100%" : "460px"};
        min-height: ${
          styleOverride.minHeight ? styleOverride.minHeight : "100vh"
        };
        padding-left: ${
          styleOverride.paddingLeft ? styleOverride.paddingLeft : "initial"
        }
    `;
  }}
`;

export const personalities = {
  "TYPE 1": {
    archetype: "The Reformer",
    label: "Type 1",
    keyFeatures: [
      "Principled",
      "Purposeful",
      "Self-Controlled",
      "Perfectionistic",
    ],
  },
  "TYPE 2": {
    archetype: "The Helper",
    label: "Type 2",
    keyFeatures: ["Demonstrative", "Generous", "People-Pleasing", "Possessive"],
  },
  "TYPE 3": {
    archetype: "The Achiever",
    label: "Type 3",
    keyFeatures: ["Adaptive", "Driven", "Excelling", "Image-Conscious"],
  },
  "TYPE 4": {
    archetype: "The Individualist",
    label: "Type 4",
    keyFeatures: ["Dramatic", "Expressive", "Self-Absorbed", "Temperamental"],
  },
  "TYPE 5": {
    archetype: "The Investigator",
    label: "Type 5",
    keyFeatures: ["Innovative", "Isolated", "Perceptive", "Secretive"],
  },
  "TYPE 6": {
    archetype: "The Loyalist",
    label: "Type 6",
    keyFeatures: ["Anxious", "Engaging", "Responsible", "Suspicious"],
  },
  "TYPE 7": {
    archetype: "The Enthusiast",
    label: "Type 7",
    keyFeatures: ["Distractible", "Scattered", "Spontaneous", "Versatile"],
  },
  "TYPE 8": {
    archetype: "The Challenger",
    label: "Type 8",
    keyFeatures: ["Confrontational", "Decisive", "Self-Confident", "Willful"],
  },
  "TYPE 9": {
    archetype: "The Peacemaker",
    label: "Type 9",
    keyFeatures: ["Agreeable", "Complacent", "Receptive", "Reassuring"],
  },
};

import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import { getBreakPoint } from "src/styles/theme";
import { BodyText, BodyTitle, Box } from "src/components/Primitives";
import { MaxWidthContainer } from "src/components/MaxWidthContainer";
import { Fade } from "src/components/Fade";
import { borderRadius, boxShadow } from "src/styles/mixins";

import Step1 from "./images/step1.svg";
import Step2 from "./images/step2.svg";
import Step3 from "./images/step3.svg";

const Container = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;

  ${({ isMobile }) => {
    if (isMobile) {
      return `
        grid-template-columns: 1fr;
        `;
    }
  }}
`;

const WalkthroughItemContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: ${borderRadius()};
  // box-shadow: ${boxShadow()};
  height: 100%;
`;

const WalkthroughImage = styled.img`
  width: 100%;
  height: 140px;
  width: 140px;
`;

const WalkthroughItem = ({ children, icon, title }) => {
  return (
    <WalkthroughItemContainer>
      <WalkthroughImage src={icon} alt={`${title} icon`} />
      <BodyTitle mb={2}>{title}:</BodyTitle>
      <BodyText textAlign={"center"} fontSize={6}>
        {children}
      </BodyText>
    </WalkthroughItemContainer>
  );
};

export const Walkthrough = () => {
  const mobileBreakpoint = getBreakPoint("mobile");
  const isLargerThanMobile = useMediaQuery({
    query: `(min-width: ${mobileBreakpoint}px)`,
  });
  const isMobile = !isLargerThanMobile;

  return (
    <MaxWidthContainer contentContainer>
      <Container isMobile={isMobile}>
        <Fade bottom distance={"50%"} delay={100}>
          <WalkthroughItem icon={Step1} title={"Step 1"}>
            Choose a Design
          </WalkthroughItem>
        </Fade>
        <Fade bottom distance={"50%"} delay={200}>
          <WalkthroughItem icon={Step2} title={"Step 2"}>
            Select a Product for the Design
          </WalkthroughItem>
        </Fade>
        <Fade bottom distance={"50%"} delay={400}>
          <WalkthroughItem icon={Step3} title={"Step 3"}>
            Customize the Design
          </WalkthroughItem>
        </Fade>
      </Container>
    </MaxWidthContainer>
  );
};
